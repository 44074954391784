import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import SearchGoogleMaps from './Components/SearchGoogleMaps';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabUsuario from './Components/TabUsuario';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { Link,useNavigate,Navigate } from "react-router-dom";



function DetalleUsuarioV(){
    const [shouldRedirect, setShouldRedirect] = useLocalStorage('shouldRedirect',false);
    const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);

    const {id} = useParams();
    const navigate = useNavigate();
 useEffect(() => {
	window.scrollTo(0, 0);
})     
    return (
        <>
         <Layout>
            <br></br>
            <br></br>
            <br></br>
            <Container style={{padding:'0 0 0 0'}}>
                <Row>
                    <Col sm={1}>
                    </Col>
                    <Col sm={10}>
                    <TabUsuario></TabUsuario>
                    </Col>
                    <Col sm={1}>
                    </Col>
                </Row>
            </Container>
         </Layout>
        </>
        
    )
}

export default DetalleUsuarioV;
