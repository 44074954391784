import React, { useState, useEffect } from "react";

const Pagination = ({ pages, current, onPageChange }) => {
  const [disabledPrevious, setDisabledPrevious] = useState(true);
  const [disabledNext, setDisabledNext] = useState(true);
  var pagesVec = [];
  for (var i = 1; i <= pages; i++) {
    pagesVec.push(i);
  }
  useEffect(() => {
    // Activar o desactivar los botones de anterior y siguiente
    setDisabledPrevious(current === 1);
    setDisabledNext(current === pages);
  }, [current, pages]);

  return pages > 1 ? (
    <div style={{ position: "relative" }}>
      {window.innerWidth < 768 ? (
        <div
          style={{
            display: "flex",
            position: "relative",
            top: "120px",
            justifyContent: "center",
            gap: "12px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <button
            className="botonAnterior"
            disabled={disabledPrevious}
            onClick={() => onPageChange(current - 1)}
          >
            Anterior
          </button>
          <button
            className="botonSiguiente"
            disabled={disabledNext}
            onClick={() => onPageChange(current + 1)}
          >
            Siguiente
          </button>
        </div>
      ) : null}

      <div className="pagination">
        {pagesVec.map((page) => (
          <button
            key={page}
            className={page === current ? "button active" : "button"}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
      </div>

      <div className="pagination">
        {window.innerWidth > 768 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="button"
              disabled={disabledPrevious}
              onClick={() => onPageChange(current - 1)}
            >
              Anterior
            </button>
            <div>
              <button
                className="button"
                disabled={disabledNext}
                onClick={() => onPageChange(current + 1)}
              >
                Siguiente
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default Pagination;
