import React, { useState, useEffect, useRef } from "react";
import imgcorazon1 from "../img/corazon_2.svg";
import imgcorazon2 from "../img/corazon1.svg";
import "react-slideshow-image/dist/styles.css";
import Card from "react-bootstrap/Card";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoadingSpinner from "./LoadingSpinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Carousel from "react-bootstrap/Carousel";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../useLocalStorage";

import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

function CardAnuncios({
  Id,
  direccion,
  Precio,
  ListaImagenes,
  terreno,
  dormitorios,
  banios,
  parqueos,
  CodigoAnuncio,
  Transaccion,
  TipoInmueble,
  Favoritos,
  Moneda
}) {
  //Slideshow(ListaImagenes); console.log(ListaImagenes);

  const [items, setItems] = useState();
  const [index, setIndex] = useState(0);
  const [megusta, setMegusta] = useState(false);
  async function fetchApi() {
    const jsonNew = JSON.parse(ListaImagenes);
    if (jsonNew.items !== undefined) {
      setItems(jsonNew.items);
    }
  }

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleRedirect = () => {
    window.open("DetalleAnuncio/" + btoa(CodigoAnuncio));
  };

  function calcularFavoritos(id) {
    if (Favoritos == null) {
      return;
    }
    for (let index = 0; index < Favoritos.length; index++) {
      if (Favoritos.length > 0) {
        if (Favoritos[index].idAnuncio == id) {
          setMegusta(true);
          break;
        }
        setMegusta(false);
      }
    }
  }
  useEffect(() => {
    fetchApi();
    calcularFavoritos(CodigoAnuncio);
  }, []);

  async function handleClick() {
    const idusuario = localStorage.getItem("idusuario");
    if (idusuario === null || idusuario.length < 1) {
      alert("Debe iniciar sesión para poder guardar este anuncio a favoritos");
      return;
    }
    if (megusta == false) {
      setMegusta(true);
      if (idusuario != "") {
        const data = JSON.stringify({
          pidusuario: idusuario,
          pidanuncio: CodigoAnuncio,
        });
        await fetch("https://merkasa.com.bo/api/ApiRegistroFavoritos.php", {
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            //setPosts((posts) => [data, ...posts]);

            const respuesta = data.idAnuncioF;

            if (respuesta == 0) {
              return;
            } else {
              if (isNaN(respuesta)) {
                alert("Hubo un error en el registro del anuncio");
              }
              //handleRedirect();
            }
          });
      }
    } else {
      setMegusta(false);
      const data = JSON.stringify({
        idanuncio: CodigoAnuncio,
      });

      await fetch("https://merkasa.com.bo/api/ApiEliminarFavorito.php", {
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          //setPosts((posts) => [data, ...posts]);

          const respuesta = JSON.parse(data);

          if (respuesta != "1") {
            alert("Hubo un error en el sistema, inténtelo mas tarde.");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }

  const inmueble = () => {
	 let tipo = parseInt(TipoInmueble);
    if (tipo === 1) {
      return "Casa";
    } else if (tipo === 2) {
      return "Departamento";
    } else if (tipo === 3) {
      return "Terreno";
    } else if (tipo === 4) {
      return "Oficina";
    } else if (tipo === 5) {
      return "Casa de campo";
    } else if (tipo === 6) {
      return "Local Comercial";
    } else if (tipo === 7) {
      return "Comercial";
    } else if (tipo === 8) {
      return "Industrial";
    } else if (tipo === 9) {
      return "Parqueo";
    } else if (tipo === 10) {
      return "Agrícola";
    } else {
      return "";
    }
  };
  function formatoCaracteristicas() {
    let caracteristicas = [];
    if (dormitorios > 0) {
      caracteristicas.push(dormitorios + " Dorm ");
    }
    if (banios > 0) {
      if (banios == 0) {
        caracteristicas.push(banios + " Baños ");
      } else {
        caracteristicas.push(banios + " Baño ");
      }
    }
    if (parqueos > 0) {
      caracteristicas.push(parqueos + " Parq ");
    }
    if (terreno > 0) {
      caracteristicas.push(terreno + " m2 tot.");
    }
    return caracteristicas.join(" | ");
  }

  return (
    <>
      <Card
        key={Id}
        style={{
          width: "100%",
          height: "380px",
          border: "1px solid black",
          cursor: "pointer",
          overflow: "hidden",
        }}
        className="cardAnuncios"
      >
        <Card.Body>
          <Card.Title style={{ width: "100%", height: "200px" }}>
                        <div
              className="ToolTipCorazonCardAnuncios"
              style={{ visibility: "visible" }}
            >
              {megusta == true ? (
                <>
                  <button
                    type="button"
                    className="icon-container"
                    onClick={() => handleClick()}
                  >
                    <MdFavorite className="love-icon" />
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="icon-container"
                    onClick={() => handleClick()}
                  >
                    <MdFavoriteBorder className="love-icon" />
                  </button>
                </>
              )}
            </div>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
              indicators={true}
			  touch={false}
            >
              {!items ? (
                <LoadingSpinner></LoadingSpinner>
              ) : (
                items.map((item) => {
                  return (
                    <Carousel.Item onClick={(e) => handleRedirect()} style={{ width: "100%" }}>
                      <LazyLoadImage
                        src={item.imagen}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          margin: "0 0 0 0",
                          height: "200px",
                        }}
                        className="cardImagen"
                      ></LazyLoadImage>
                    </Carousel.Item>
                  );
                })
              )}
            </Carousel>
          </Card.Title>
          <Card.Text
            style={{ height: "100%" }}
            onClick={(e) => handleRedirect()}
            className="cardText"
          >
            <b>
              <p
                style={{
                  fontSize: "12pt",
                  padding: "0 0 0 0",
                  position: "relative",
                  top: "5px",
                  height: "30px",
                  whiteSpace: "nowrap",
                  margin: "0 0 10px 10px",
                }}
              >
                {inmueble()} en {Transaccion}
              </p>
            </b>
            <div
              className="divPrecioTopNew"
              style={{
                position: "relative",
                bottom: "2px",
                margin: "0 0 10px 10px",
              }}
            >
              <p>{Moneda==="BS"?"Bs":"$us"} {Precio}</p>
            </div>
            <p
              style={{
                fontSize: "11pt",
                //
                margin: "0 0 10px 10px",
                whiteSpace: "nowrap",
              }}
            >
              {formatoCaracteristicas()}
            </p>
            <p
              style={{
                fontSize: "9pt",
                margin: "0 0 10px 10px",
				padding: "0px 10px 0 0",
              }}
            >
              {direccion}
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
export default CardAnuncios;
