import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';



export default function Header() {
  
  const handleRedirectCredito = () => {

    alert('Proximamente disfrutaras de esta opción.')
  
  }


  return (
    <header style={{width:'100%',padding:'0 0 0 0'}}>
      <Container style={{border:'1px solid black'}}>
      <Row>
        <Col sm={2}>
        <button style={{fontSize:'8pt'}}><p>Nuevos proyectos</p></button>
        </Col>
        <Col sm={1}>
        <Button className='btnLogin' variant='primary4' onClick={(e) => handleRedirectCredito()}>
                    <b>Obtén tu crédito</b>
                    </Button>
        </Col>
        <Col sm={1}>
        <Button className='btnLogin' variant='primary4' onClick={(e) => handleRedirectCredito()}>
                    <b>Obtén tu crédito</b>
                    </Button>
        </Col>
        <Col sm={5}>
          <p>Titulo</p>
        </Col>
        <Col sm={1}>
        </Col>
        <Col sm={1}>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
      </Container>
    </header>
  );
}