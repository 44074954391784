import React, {useState,useEffect,useRef} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios'; // npm i axios
import Canvas from './Components/Canvas';
import {FaTrash,FaPlus } from 'react-icons/fa'; // npm i react-icons
import Table from 'react-bootstrap/Table';
import {useNavigate } from "react-router-dom";
import Sortable from 'sortablejs';


function AgentePromocionate2()
{   
	const [imagen, setImagen] = useState([]);
	const [vWidth, setWidth] = useState('');  
	const [vHeight, setHeight] = useState('');
	const [urlInstagram, seturlInstagram] = useState('');
	const [urlTiktok, seturlTiktok] = useState('');
	const [urlFacebook, seturlFacebook] = useState('');
	const [descripcion, setdescripcion] = useState('');
	const [urlVideosYoutube, setUrlVideosYoutube] = useState([]);
	const [UsuarioId, setUsuarioId] = useState();
	const [AgenteId , setAgenteId] = useState();
	const [urlYoutubeNew, seturlYoutubeNew] = useState('');

	const navigate = useNavigate();

	function deleteImagen(id,src){ 
		//const CodAnuncio = sessionStorage.getItem("CodAnuncioActual");
		const idUsuario = localStorage.getItem("idusuario");

		if(idUsuario == null)
			{
			return;
			}


		const imgFinal = document.getElementById('canvas-'+id);

		let context = imgFinal.getContext('2d');

		if(context != null)
			{
			context.clearRect(0, 0, vWidth, vHeight);
			}
		imagenesEliminadas.current.push(src);
		handleRemoveItem(id);

		}

	const handleRemoveItem = (id) => {
		setImagen(imagen.filter(item => item.id !== id));
		imagenesOrdenadas.current = imagenesOrdenadas.current.filter(item => item.id !== id);
		};

	function niceBytes(a)
	{
		var marker = 1024; // Change to 1000 if required
		var decimal = 3; // Change as required
		var megaBytes = marker * marker; // One MB is 1
		return(a / megaBytes).toFixed(decimal);
		}

	function convertImage(e)
	{

		const files = e.target.files;

		for (let index = 0; index < files.length; index++) {
			const reader = new FileReader();


			let src = URL.createObjectURL(e.target.files[index]);

			if(niceBytes(e.target.files[index].size) >= 32.0)
				{
				alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
				return;
				}

			reader.readAsDataURL(e.target.files[index]);
			reader.onload = (e) => {
				const image = new Image();
				image.src = e.target.result;
				image.onload = () => {
					const {
						height,
						width
						} = image;

					setHeight(height);
					setWidth(width);

					const idimagenes = Math.random();	
					if(src.length > 1)
						{
						imagenesOrdenadas.current.push({ id:idimagenes,src:src, height:height, width: width,idusuario:UsuarioId,idagente:AgenteId,exist:0});
						setImagen(imagen => [...imagen,{ id:idimagenes,src:src, height:height, width: width,idusuario:UsuarioId,idagente:AgenteId,exist:0}])
						}
					};
				};  
			}

		const fileAnun = document.getElementById('fileAnuncio');
		fileAnun.value = '';

		}

	async function handleRedirect ()  {

		var esLink = /^(ftp|http|https):\/\/[^ "]+$/;

		if(descripcion.length === 0)
			{
			alert("Debe ingresar una breve descripción para activarse como agende en el muro de merkasa.");
			return;
			}
		if(imagen.length > 10)
			{
			alert("Cantidad permitida de fotos es 10.");
			return;
			}
		if (urlInstagram.length > 0 && esLink.test(urlInstagram) === false){
			alert ("Debe ingresar una url de instagram válida.");
			return;
			}
		if (urlTiktok.length > 0 && esLink.test(urlTiktok) === false){
			alert ("Debe ingresar una url de tiktok válida.");
			return;
			}
		if (urlFacebook.length > 0 && esLink.test(urlFacebook) === false ){
			alert ("Debe ingresar una url de facebook válida.");
			return;
			}


		// Actualización de datos

		const data = JSON.stringify({
			pidagente:AgenteId,pdescripcion:descripcion,purlintagram:urlInstagram,purltiktok:urlTiktok,purlfacebook:urlFacebook,
			})

		await fetch('https://merkasa.com.bo/api/ApiActualizaDatosAgente.php', {
			method: 'POST',
			body: data,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				},
			})
		.then((response) => response.json())
		.then((data) => {

			const respuesta = data;

			if(respuesta === 0)
				{

				alert("Hubo un problema en actualizar los datos, intente nuevamente.");  
				return; 
				}
			})
		.catch((err) => {
			console.log(err.message);
			});

		//Registro de url videos

		let fdy = new FormData() 
		fdy.append("agenteid",AgenteId)
		fdy.append("tipo", 2)

		await axios.post('https://merkasa.com.bo/api/ApiDesactivarDatos.php', fdy);

		urlVideosYoutube.map(async (item)  => {
				let fd = new FormData() 
				fd.append("idagente",AgenteId)
				fd.append("urlvideo", item.urlVideos)
				console.log('numero de agente: ', AgenteId)
				await axios.post('https://merkasa.com.bo/api/ApiRegistroAgenteUrlVideos.php', fd);


			});


		imagenesEliminadas.current.map(async (item)  => {

			const data2 = JSON.stringify({
				url : item,
			})

			await fetch('https://merkasa.com.bo/api/ApiEliminaAgenteImg.php', {
				method: 'POST',
				body: data2,
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
				},
			})

			})


		//Registro de imagenes
		let fdu = new FormData() 
		fdu.append("idagente",AgenteId)
		fdu.append("tipo", 1)

		imagenesOrdenadas.current.map(async (item)  => {

			if (!imagenesAnteriores.current.includes(item.src)){
				const imgFinal = document.getElementById('canvas-'+item.id);

				let fd = new FormData() 
				fd.append("idagente",AgenteId)
				fd.append("imagenbs4",(item.exist == 1) ? item.src : imgFinal.toDataURL("image/webp"));
				fd.append("pheight",item.height)
				fd.append("pwidth",item.width)
				fd.append("exist",item.exist)
				const res = await axios.post('https://merkasa.com.bo/api/ApiRegistroAgenteImg.php', fd);
				}});



		var x = document.getElementById("idmaster");

		if (x.className === "") 
			{
			x.className += "redirect-overlay";
			}

		alert("Datos grabados correctamente.");
		ObtenerDatosAgente();

		setTimeout(() => {
			navigate('/MiPerfilAgente');
			}, 2000);

		}

	async function ObtenerDatosAgente()
	{

		var idusuario = localStorage.getItem("idusuario");
		setUsuarioId(idusuario);

		let fdA = new FormData() 
		fdA.append("idusuario",idusuario)

		const responseAgente = await axios.post('https://merkasa.com.bo/api/ApiObtenerAgente.php',fdA);
		console.log("respuesta a la petición ",responseAgente.data);
		if (responseAgente.data.items.length > 0){
			responseAgente.data.items.map((item) => 
			{

				seturlFacebook(item.urlFacebook);
				seturlInstagram(item.urlInstagram);
				seturlTiktok(item.urlTikTok);
				setdescripcion(item.descripcion);
				sessionStorage.setItem("UsuarioActivo",JSON.stringify(item.correo));

				/*Datos para redireccionar al perfil del agente*/

				sessionStorage.setItem("idAgenteSeleccionado",item.idAgente);
				sessionStorage.setItem("NombreAgenteSeleccionado",item.Nombre +' '+item.ApellidoPaterno + ' '+ item.ApellidoMaterno);
				sessionStorage.setItem("CantidadAnuncios",item.CantAnuncios);
				sessionStorage.setItem("DescripcionAgenteSeleccionado",item.descripcion);
				sessionStorage.setItem("FotoPerfilAgenteSeleccionado",item.fotoperfil);
				sessionStorage.setItem("FacebookAgenteSeleccionado",item.urlFacebook);
				sessionStorage.setItem("InstagramAgenteSeleccionado",item.urlInstagram);
				sessionStorage.setItem("TiktokAgenteSeleccionado",item.urlTikTok);
				sessionStorage.setItem("NumeroCelularAgenteSeleccionado",item.Celular);
				})}
		}

	async function fetchApi(){

		await ObtenerDatosAgente();
		let idAgente = sessionStorage.getItem("idAgenteSeleccionado");

		let idusuario = localStorage.getItem("idusuario");
		setUsuarioId(idusuario);

		let fd = new FormData() 
		fd.append("idagente",idAgente)


		const response = await axios.post('https://merkasa.com.bo/api/ApiListarVideosAgentes.php',fd);



		if(response.data !== 0)
			{
			response.data.items.map((item,index) => {
				setUrlVideosYoutube(urlVideosYoutube => [...urlVideosYoutube,{idUsuario:idusuario, idAgente: idAgente, urlVideos: item.urlVideo,id:item.id,index:index,exist:1}])
				})
			}     

		setAgenteId(idAgente);
		let fdag = new FormData() 
		fdag.append("idagente",idAgente)

		const responseag = await axios.post('https://merkasa.com.bo/api/ApiObtenerAgenteImagenes.php',fdag);
		if(responseag.data.items !== undefined)
			{
			responseag.data.items.map((item) => {
				let idimagenes = Math.random();
				imagenesOrdenadas.current.push({ id:idimagenes,src:item.UrlImagen, height:item.height, width: item.width,idusuario:idusuario,idagente:idAgente,exist:1})

				setImagen(imagen => [...imagen,{ id:idimagenes,src:item.UrlImagen, height:item.height, width: item.width,idusuario:idusuario,idagente:idAgente,exist:1}])
				imagenesAnteriores.current.push(item.UrlImagen);
				})
			}     
		}


	const handleAddUrlVideo = () => {

		var esLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

		if(urlYoutubeNew.length === 0)
			{   
			alert("Debe ingresar url de youtube para continuar.")
			return;
			}
		if (esLink.test(urlYoutubeNew) === false){
			alert ("Debe ingresar una url de youtube válida.");
			return;
			}

		let valorMax = ((urlVideosYoutube == null ) ? 1 : (urlVideosYoutube.length) + 1)

		setUrlVideosYoutube(urlVideosYoutube => [...urlVideosYoutube,{idUsuario:UsuarioId, idAgente: AgenteId, urlVideos: urlYoutubeNew,id:valorMax,index : valorMax}])

		seturlYoutubeNew('');


		}

	const handleRemoveVideoItem = (id) => {
		setUrlVideosYoutube(urlVideosYoutube.filter(item => parseInt(item.id) !== id));
		};

	function formatCadena(cadena)
	{
		let newValor = cadena.replace(/[^A-Za-z0-9 . ]/,"");

		return newValor;
		}

	useEffect(() => {
		fetchApi();
	},[])


	const imagenesAnteriores = useRef([]);
	const imagenesEliminadas = useRef([]);
	const imagenesOrdenadas = useRef([]);
	const gridRef = useRef(null);

	useEffect(() => {
		if (gridRef.current) {
			new Sortable(gridRef.current, {
				animation: 150,
				ghostClass: 'sortable-ghost',
				onEnd: ({ oldIndex, newIndex }) => {
					const image = imagenesOrdenadas.current.splice(oldIndex, 1)[0];
					imagenesOrdenadas.current.splice(newIndex, 0, image);
					}
				});
			};
	},[]);	

	return(
		<>
			<Container id="idmaster" className="" style={{padding:'calc(1em + 1vw) 0 0 0'}}>
				<Row>
					<Col sm={12}>
						<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
							<Form.Label style={{fontSize:'10pt'}}><b>Agrega una descripción sobre tu experiencia personal y profesional</b></Form.Label>
							<Form.Control
								as="textarea" 
								rows={6}
								value={descripcion}
								onChange={(e) => setdescripcion(e.target.value)}
								required
								style={{fontSize:'8pt'}}
								nFocus={true}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={12} style={{paddingTop:'10px'}}>
						<p style={{fontSize:'10pt'}}><b>Información de tus redes</b></p>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Container style={{padding:'0 0 0 0'}}>
							<Row>
								<Col sm={12}>
									<Form.Group style={{marginBottom:'10px'}}className="sm-3" controlId="exampleForm.ControlInput2">
										<p style={{fontSize:'8pt',marginBottom:'0'}}><b>Link de Instagram</b></p>
										<Form.Control
											type="text"
											placeholder="https://"
											value={urlInstagram}
											style={{fontSize:'8pt'}}
											onChange={(e) => seturlInstagram(e.target.value)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col sm={12}>
									<Form.Group style={{marginBottom:'10px'}} className="sm-3" controlId="exampleForm.ControlInput2">
										<p style={{fontSize:'8pt',marginBottom:'0'}}><b>Link de TikTok</b></p>
										<Form.Control
											type="text"
											placeholder="https://"
											value={urlTiktok}
											style={{fontSize:'8pt'}}
											onChange={(e) => seturlTiktok(e.target.value)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col sm={12}>
									<Form.Group style={{marginBottom:'4px'}} className="sm-3" controlId="exampleForm.ControlInput2">
										<p style={{fontSize:'8pt',marginBottom:'0'}}><b>Link de Facebook</b></p>
										<Form.Control
											type="text"
											placeholder="https://"
											value={urlFacebook}
											style={{fontSize:'8pt'}}
											onChange={(e) => seturlFacebook(e.target.value)}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Container style={{padding:'0 0 0 0',marginBottom:'10px'}} >
							<Row>
								<Col sm={6} style={{paddingTop:'10px',float:'left'}}>
									<p style={{fontSize:'8pt',marginBottom:'0'}}><b>Link de Youtube</b></p>
								</Col>
							</Row>
							<Row>
								<Col sm={11}>
									<Form.Control
										type="text"
										placeholder="https://"
										value={urlYoutubeNew}
										onChange={(e) => seturlYoutubeNew(e.target.value)}
										style={{fontSize:'8pt'}}
									/>
								</Col>
								<Col sm={1}>
									<center>
										<FaPlus style={{cursor:'pointer',color:'green'}} onClick={(e) => handleAddUrlVideo()} /> 
									</center>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table responsive="sm">
							<thead>
								<tr>
									<th><b>#</b></th>
									<th><b>Url</b></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{  !urlVideosYoutube ? <>

								<tr>
									<td style={{fontSize:'8pt'}}>Agrega un nuevo video</td>
									<td></td>
								</tr>

							</> :
							urlVideosYoutube.map( (item,index) => {
								return (
									<tr>
										<td style={{fontSize:'8pt'}}>{index+1}</td>
										<td style={{fontSize:'8pt'}}>{item.urlVideos}</td>
										<td><div className="input-group-append">
												<button
													type="button"
													className="btn btn-primary"
													onClick={() => window.open(urlVideosYoutube[index].urlVideos, '_blank')}
												>
													Ir
												</button>
										</div></td>
										<td><FaTrash style={{cursor:'pointer',color:'red'}} onClick={(e) => handleRemoveVideoItem(parseInt(item.id))} /></td>
									</tr>
									)
									})      
								}

							</tbody>
						</Table>
					</Col>
				</Row>
				<Row>
					<Col sm={12} style={{paddingTop:'10px'}}>
						<p style={{fontSize:'10pt'}}><b>Sube fotos de tus proyectos o momentos con clientes</b></p>
					</Col>
				</Row>
				<Row>
					<Col sm={12} style={{paddingTop:'10px'}}>
						<center>
							<div id='wimagenagente'>                   
								<ul id='listaAnunciosfotos' ref={gridRef}>
									{!imagen ? 'Cargando imagenes' : imagen.map( (item) => {
										/*<Canvas src={item.src} width={item.width} height={item.height} ></Canvas>*/
										return(<><li key={item.id} id={'li-'+item.id} className='anuncioslif2'>
											<Container style={{padding:'0 0 0 0'}}>
												<Row>
													<Col sm={12}>
														<Canvas src={item.src} width={item.width} height={item.height} index={item.id}></Canvas>
													</Col>
													<Col sm={12}>
														<center>
															<button  className="btn btn-outline-danger btn-sm btnEliminarFoto" 
																onClick={() => deleteImagen(item.id,item.src)} ><FaTrash />
															</button> 
														</center>

													</Col>
												</Row>
											</Container>

								</li></>
								)
								}) 
							}
						</ul>

						<h4>Vista Previa</h4>
					</div>

					<div className="form-group">
						<center>
						</center>
						<br></br>
						<center>
							<input id="fileAnuncio"  type="file" multiple style={{display:'none'}} className="form-control-file" accept="image/png,image/jpeg,image/jpe"
								onChange={(e) => convertImage(e)}/>
							<div id="customFileLabel" style={{}}>
								<button onClick={() => document.getElementById('fileAnuncio').click()}>Seleccionar fotos</button>
								{imagen.length === 0
									? '     Ninguna foto seleccionada'
									: `  Archivos seleccionados: ${imagen.length}`}
								</div>
							</center>
						</div> 
						<br></br>
						<center>
							<button type='button' className='btnGuardarAnuncio' form='formAnuncio' onClick={handleRedirect}>Guardar cambios</button>
						</center>
					</center>
				</Col>


			</Row>

		</Container>

	</>
	);
}


export default AgentePromocionate2;
