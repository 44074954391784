import React from 'react';
import Layout from "./Components/Layout";
export default function TerminosCondiciones(){
	return (
		<>
			<Layout>
				<div style={{padding: '30px', fontFamily:'geometria'}} className='terminos'>
					<center><h1>Terminos y Condiciones</h1>	</center>
					<center><h2>¡Bienvenido(a) a Merkasa!</h2></center>
					<p>Gracias por utilizar nuestros servicios.</p>
					<h3><b>Aviso Legal</b></h3>
					<p>El acceso, navegación o utilización de la plataforma suponen la aceptación expresa y sin
						reservas de todos los Términos y Condiciones de Merkasa, así como de las Políticas de
						Privacidad, teniendo dicha aceptación, la misma validez y eficacia que cualquier contrato
						celebrado por escrito y firmado, por lo que su observancia y cumplimiento será exigible a
						cualquier persona natural o jurídica.
						En tal sentido, se recomienda leer ambos documentos detenidamente.</p>
					<h3><b>I. DEFINICIONES</b></h3>
					<ul>
						<li><span style={{fontWeight:'bold'}}>"Sitio web: "</span> Cuando se utilizan estas palabras en los Términos y Condiciones o la
							Política de Privacidad, nos referiremos a la página web o la plataforma digital de
							nuestra empresa, identificado con el dominio www.merkasa.com.bo</li>

						<li><span style={{fontWeight:'bold'}}>"Usuario: "</span> Hace referencia a cualquier persona que acceda, registre o utilice el sitio
							web de MERKASA, sea quien oferte un inmueble o quién sólo explore la página.
							También haremos referencia al usuario, cuando se menciona palabras en segunda
							persona “tu”, “te”, “has”.</li>

						<li><span style={{fontWeight:'bold'}}>"Oferente: "</span> Indica a quien publica y pone en el mercado un inmueble a través del sitio
							web o la aplicación móvil. En caso de venta se denominará “vendedor”, en caso de
							anticrético “anticretista” y en caso del alquiler “arrendador”.</li>

						<li><span style={{fontWeight:'bold'}}>"Interesado: "</span> Refiere a la persona que busca un inmueble para su uso personal o
							comercial. En caso de venta se denominará “comprador”, en caso de anticrético
							“acreedor” y en caso del alquiler “arrendatario”.</li>

						<li><span style={{fontWeight:'bold'}}>"Agente: "</span>  Persona que actúa como intermediaria entre el oferente y el interesado
							“Inmobiliaria” Empresa dedicada exclusivamente a la intermediación y gestión
							inmobiliaria.</li>

						<li><span style={{fontWeight:'bold'}}>"Terminos: "</span> Nos referimos a términos y condiciones, sólo términos o políticas, para
							identificar el presente documento.</li>

						<li><span style={{fontWeight:'bold'}}>"MERKASA: "</span> Es nuestra empresa que se encarga de gestionar servicios de publicidad
							en el mercado inmobiliario, como se describe en el siguiente acápite. También
							haremos referencia a la empresa cuando mencionemos palabras en primera persona
							plural como “nosotros”, “nuestro” o “nos”.</li>
					</ul>
					<h3><b>II. NUESTRA EMPRESA "MERKASA"</b></h3>
					<p>MERKASA S.R.L. es una Sociedad de Responsabilidad Limitada, empresa legalmente
						constituida e identificada con el Número de Identificación Tributaria (NIT) y Matrícula de
						Comercio No 498600029. En adelante referido como "MERKASA"
						Nuestros canales oficiales de comunicación o contacto se realizan a correo electrónico
						atencionalcliente@merkasa.com.bo y el número de teléfono 71066573.
						Somos una plataforma digital que permite a los usuarios publicar, buscar y contactar con
						titulares o agentes de propiedades inmobiliarias disponibles para la venta, alquiler, anticrético
						e incluso futuros proyectos inmobiliarios. En tal sentido, somos un colaborador estratégico de
						agentes y desarrolladores inmobiliarios, facilitando la conexión entre empresas, instituciones
						y profesionales que forman parte del ecosistema inmobiliario y comercial, y potenciales
						compradores, inversionistas y/o arrendatarios.
						Nuestra misión es conectar de la manera más eficiente a los ofertantes y demandantes que
						participan en el ecosistema inmobiliario. Nuestra visión es transformar la industria inmobiliaria
						en el mercado boliviano aprovechando tecnologías innovadoras que permitan transparentar
						y volver más eficiente esta industria. Al transparentar el mercado, aspiramos a crear un
						entorno más justo, competitivo y próspero para todos los participantes del sector inmobiliario.
						Ante cualquier duda sobre nuestros servicios, el sitio web, puedes dirigirte a nosotros
						directamente a través del correo electrónico atencionalcliente@merkasa.com.bo.</p>

					<h3><b>III. NUESTROS PRINCIPIOS FUNDAMENTALES</b></h3>
					<ol type="a">
						<li><span style={{fontWeight:'bold'}}>"Integridad y Transparencia: "</span>  En MERKASA, nos comprometemos a actuar con
							integridad y transparencia en todas nuestras interacciones con los usuarios y demás
							partes interesadas. Valoramos la honestidad y la veracidad en nuestras
							comunicaciones, así como la transparencia en nuestros procesos y políticas.</li>

						<li><span style={{fontWeight:'bold'}}>"Orientación al cliente: "</span>Nuestros Usuarios son nuestra máxima prioridad. Estamos
							dedicados a comprender y satisfacer sus necesidades de manera efectiva. Buscamos
							brindar una experiencia positiva en nuestra plataforma, ofreciendo soluciones
							personalizadas y un servicio de calidad que supere sus expectativas.</li>

						<li><span style={{fontWeight:'bold'}}>"Innovación y tecnologia avanzada: "</span>En MERKASA, abrazamos la innovación y la
							tecnología avanzada como pilares fundamentales para mejorar y optimizar nuestros
							servicios. Nos esforzamos por integrar tecnologías de vanguardia para proporcionar
							a nuestros Usuarios una experiencia inmobiliaria única y efectiva.</li>

						<li><span style={{fontWeight:'bold'}}>"Trabajo en equipo y profesionalismo: "</span>Nuestro equipo está comprometido con el trabajo
							en conjunto y la ética profesional. Fomentamos un ambiente colaborativo y
							respetuoso, donde cada miembro del equipo está capacitado y motivado para brindar
							un servicio integral y satisfactorio a nuestros Usuarios.</li>

						<li><span style={{fontWeight:'bold'}}>"Privacidad y protección de datos"</span>Respetamos la privacidad de nuestros Usuarios y
							nos comprometemos a proteger sus datos personales de acuerdo con nuestras
							políticas de privacidad. La seguridad y confidencialidad de la información de nuestros
							Usuarios es una prioridad para nosotros.</li>


						<li><span style={{fontWeight:'bold'}}>"Mejora continua: "</span>Estamos comprometidos con la mejora continua en todos los
							aspectos de nuestros servicios. Escuchamos activamente los comentarios de
							nuestros Usuarios y utilizamos esta retroalimentación para perfeccionar y optimizar
							nuestros procesos y servicios.</li>


						<li><span style={{fontWeight:'bold'}}>"Respeto a la diversidad y inclusión"</span>Valoramos la diversidad y la inclusión en
							MERKASA. Respetamos las diferencias culturales, sociales y personales, y nos
							esforzamos por crear una plataforma inclusiva donde todos los Usuarios sean
							bienvenidos y se sientan representados.</li>


						<li><span style={{fontWeight:'bold'}}>"Cumplimiento legal y ético"</span>Cumplimos con todas las leyes y regulaciones aplicables
							en nuestras operaciones y servicios. Promovemos una cultura de ética y
							cumplimiento, rechazando cualquier actividad ilegal o poco ética en nuestra
							plataforma.</li>


						<li><span style={{fontWeight:'bold'}}>"Excelencia en el servicio al cliente: "</span>Buscamos la excelencia en el servicio al cliente,
							brindando asistencia oportuna y efectiva a nuestros Usuarios en todo momento.
							Nuestro objetivo es proporcionar una experiencia inigualable que genere confianza y
							satisfacción en nuestros servicios.</li>
					</ol>
					<p>En MERKASA, estos principios guían nuestras acciones y decisiones diarias, asegurando
						que proporcionar una experiencia inmobiliaria inteligente y satisfactoria para todos nuestros
						Usuarios. Gracias por ser parte de nuestra comunidad, te pedimos que respetes estos
						principios.</p>

					<h3><b>IV. DESCRIPCIÓN DE NUESTROS SERVICIOS</b></h3>
					<p>El objeto de MERKASA es desarrollar, gestionar y mantener una plataforma integral en línea
						destinada a servir como un centro unificado para el ecosistema inmobiliario y comercial. Esto
						incluye la facilitación de la compra, venta, y alquiler de propiedades; la conexión de individuos
						con profesionales y técnicos para servicios de mejora, renovación, y mantenimiento del hogar;
						la colaboración con proveedores de servicios especializados, como agentes, desarrolladores,
						y otros relacionados con el sector inmobiliario; y la operación de una sección de comercio
						electrónico que ofrece productos y servicios referidos para el hogar.
						Nuestra empresa, en el marco de su constitución legal, se encuentra facultada para brindar
						los siguientes servicios:</p>
					<ol type="1">
						<li>Enlistados de inmuebles en la plataforma inmobiliaria</li>
						<li>Conectar a los compradores con los vendedores.</li>
						<li>Conectar a los inquilinos con los propietarios.</li>
						<li>Conectar a los inversores con oportunidades de inversión.</li>
						<li> Conectar a los individuos con profesionales y técnicos para servicios de mejora,
							renovación, y mantenimiento del hogar.</li>
						<li>Proporcionar información del mercado inmobiliario.</li>
						<li>Herramientas de valoración de los inmuebles u ofrecer</li>
						<li>Facilitación de créditos para la compra de inmuebles.</li>
						<li>Facilitación de financiamiento a los compradores de inmuebles con las entidades
							financieras.</li>
						<li>Servicios de publicidad.</li>
						<li>Consultorías del mercado inmobiliario.</li>
						<li>Compra, venta, alquiler, anticrético de inmuebles.</li>
						<li>Servicio de intermediación y facilitación de información de contactos potenciales
							(leads) a terceros interesados.</li>
						<li>Servicios de consultoría especializados en el mercado inmobiliario.</li>
						<li>Operaciones de comercio electrónico que ofrece productos y servicios referidos para
							el hogar.</li>
						<li> Compra, venta y distribución de productos digitales relacionados al mercado
							inmobiliario.</li>
						<li> Oferta de servicios de fotografía, video y multimedia para promoción de actividades
							inmobiliarias.</li>

					</ol>
					<p>Al ser una plataforma digital que permite la interacción de los diversos actores del ecosistema,
						MERKASA ofrece las siguientes ventajas para cada uno de ellos:</p>
					<ol type="1)">
						<li>Al comprador o arrendatario, le permite buscar inmuebles y contactar con los
							vendedores de manera simple y fácil. </li>
						<li>Al inversionista, le permite buscar proyectos en los cuales puede invertir de acuerdo
							en la etapa que se encuentran (preventa, en construcción o finalizados).</li>
						<li>Al agente inmobiliario, le permite publicar sus inmuebles y crear su página personal
							para poder resaltar sus servicios en la plataforma, que le ayude a lograr sus objetivos
							de ventas y captación de inmuebles.</li>
						<li> Al desarrollador inmobiliario o constructor, le permite publicar sus proyectos
							inmobiliarios y crear una página personalizada de su empresa que le permite transmitir
							mayor confianza a sus potenciales clientes.</li>
						<li>A la empresa inmobiliaria, le permite publicar sus inmuebles y también crear una
							página personalizada de su inmobiliaria donde da a conocer a los profesionales
							inmobiliarios que participan en esta.</li>
						<li>Al vendedor particular le permite publicar su inmueble y además poder buscar agentes
							inmobiliarios que le apoyen en la venta de su inmueble.</li>
					</ol>
					<h3><b>V. DESCRIPCION DEL SITIO WEB</b></h3>
					<p>Nuestro sitio web está diseñado de forma dinámica e intuitiva para facilitar la búsqueda de
						inmuebles y el contracto entre los actores del sector inmobiliario. Al ingresar al sitio web
						www.merkasa.com.bo cuentas con las siguientes pestañas:</p>
					<ol type="1">
						<li>Inicio
							<ol type="a">
								<li>Cuadro de Búsqueda</li>
								<li>Descubre lo mas destacado</li>
							</ol>
						</li>
						<li>Nuevos proyectos</li>
						<li>Encuentra tu agente</li>
						<li>Obtén tu crédito</li>
						<li>Publica gratis</li>
						<li>Iniciar sesión
							<ol type="a">
								<li>Soy Particular</li>
								<li>Soy Profesional</li>
							</ol>
						</li>
					</ol>
					<p>A continuación describiremos las secciones mencionadas:</p>
					<ol type="1">
						<li><b>Inicio: </b> Nuestra página de inicio es amplia e interactiva, diseñada para priorizar la
							búsqueda de inmuebles y el contacto con los actores del sector. Por ello, lo primero
							que encontrarás es el buscador o cuadro de búsqueda. Posteriormente, lo más
							destacado de nuestras publicaciones y otro espacio para que explores a los agentes
							y empresas inmobiliarias, que detallamos más adelante.
							<ol type="a">
								<li><b>Cuadro de Búsqueda: </b> El buscador es la herramienta principal del sitio web con
									la finalidad de que los usuarios puedan encontrar fácilmente los inmuebles y
									proyectos que se ajuste a sus preferencias. Al lado izquierdo del recuadro de
									búsqueda, contamos con la pestaña de filtro principal que determina el tipo de
									oferta o modalidad de transacción que busca en el inmueble. Las modalidades
									disponibles son: venta, alquiler, anticrético o un nuevo proyecto. En el recuadro
									de lado derecho se debe especificar el departamento en el que se buscará el
									inmueble (actualmente contamos con cobertura a nivel nacional Bolivia, con
									mayor presencia en Santa Cruz y Tarija).</li>
								<br />
								<p>Una vez activada la búsqueda, aparecerán las publicaciones de inmuebles más
									relacionadas a dichos criterios, junto al mapa interactivo de guía, para encontrar
									inmuebles por sus ubicaciones, así como nuevas opciones de filtros a fin de mejorar
									la búsqueda. Los datos y filtros que pueden utilizarse son los siguientes:</p>
								<ol type='i'>
									<li> <b>Ubicación:</b> El mapa interactivo es una herramienta en la plataforma de
										MERKASA que muestra con íconos de ubicación los inmuebles disponibles
										en diferentes áreas. Los íconos de ubicación indican la posición geográfica
										de los inmuebles ofertados en MERKASA y, permite a los usuarios explorar
										fácilmente propiedades en diversas ubicaciones. Los usuarios pueden
										utilizar el zoom y desplazamiento del mapa para acercarse a áreas
										específicas y obtener más detalles sobre los inmuebles disponibles en esas
										ubicaciones. Puedes hacer clic en los íconos de ubicación para obtener más
										información sobre cada propiedad y acceder a su página detallada.</li>
									<li><b>Precio:</b> El usuario puede establecer el precio máximo que tiene previsto para
										el inmueble. El sitio web usa de referencia la moneda del dólar
										estadounidense, lo cual no implica que las transacciones deban
										consolidarse en esa moneda. Las partes son libres de acordar precio,
										condiciones y monedas.</li>
									<li><b>Tipo de inmueble:</b> En este apartado de filtro se establecerá el tipo de
										inmueble que se busca, donde se puede seleccionar la preferencia dentro
										de las siguientes opciones: Todos, Casa, Departamento,Terreno, Oficina,
										Casa de Campo, Edificio, Comercial, Industrial, Parqueo, Agrícola, Otros.</li>
									<li><b>Baños: </b> Se puede establecer la cantidad de baños requeridos por el usuario,
										de 1 a 5 baños o más.</li>
									<li><b>Dormitorios:</b> Al igual que con los baños, se puede filtrar de acuerdo a la
										cantidad de dormitorios que son requeridos: de 1 a 5 baños o más.</li>
									<li><b>Fecha de publicación:</b> Podrás buscar desde las publicaciones más antiguas
										hasta las más recientes, desde los inmuebles que se publicaron hoy, hasta
										los últimos 90 días.</li>
									<li><b>Caracteristicias Adicionales:</b> Este apartado permite establecer preferencias más
										específicas para los inmuebles que estamos buscando, como ser que
										cuente con: Piscina, Sauna, Jardín, Canchas deportivas, Parque para niños,
										Balcón, Elevador, Baulera, Eco sostenible, Coworking, Cochera, Amoblado,
										Dependencia de servicios, Depósito, Churrasquera, Calefacción, Aire,
										Seguridad, Gimnasio, Penthouse, Sala de reuniones, Cine u otros.</li>
								</ol>
								<li><b>Descubre lo mas destacado:</b>  Debajo del buscador, encontrarás las publicaciones
									y novedades más destacadas de nuestro sitio web, para tenerte actualizado dentro
									del mercado inmobiliario. En este espacio, ubicado en la página de inicio, se
									muestra una lista visual de inmuebles disponibles en venta, alquiler, anticrético y
									preventa. Cada propiedad en este espacio se muestra en recuadro, con una
									fotografía de la fachada del inmueble, junto a detalles importantes como el tipo de
									inmueble, ubicación, precio, número de dormitorios y baños, parqueo, superficie y
									tipo de oferta o modalidad de transacción que busca en el inmueble. Puedes
									seleccionar un inmueble para ver más información o hacer clic en “ver todas”. En
									este último caso se redireccionará a la página descrita anteriormente, para realizar
									una mejor búsqueda con el mapa interactivo donde podrá direccionarse a la
									ubicación deseada para ver más opciones de inmuebles.</li>
							</ol>
							<li><b>Nuevos proyectos</b> Al acceder a esta pestaña, podrás estar al tanto de las
								propiedades que se encuentran en construcción, aquellas que cuentan con precios de
								pre venta y de pronta entrega. Es decir, inmuebles que están en lanzamiento. Estamos
								comprometidos con mantenerte actualizado y brindarte la información más relevante
								para que encuentres la propiedad que se ajuste a tus necesidades y preferencias. Las
								publicaciones y su información están sometidas a las políticas de contenido de este
								documento.
								Los nuevos proyectos inmobiliarios podrán ser de tres (3) tipos:</li>
							<ol type="a">
								<li>Preventa: previo al inicio del proyecto inmobiliario.</li>
								<li>En construcción: durante el proyecto de construcción.</li>
								<li>Entrega inmediata: construcción terminada lista para habitar.</li>
							</ol>
							<li><b>Encuentra tu agente:</b> En este espacio del sitio web, encontrará contenido audiovisual
								de los agentes inmobiliarios registrados en MERKASA, a partir de esos videos e
								imágenes, podrá acceder a sus perfiles. Cada perfil contiene información sobre el
								agente, sus inmuebles publicados, sus fotografías e incluso el contacto directo a su
								número de WhatsApp. También se habilita la posibilidad de enviarle sus datos
								personales para que el agente se comunique contigo. La información y el contenido
								que se publique por los agentes inmobiliarios en su perfil, es responsabilidad suya y
								está sometida a las políticas de contenido de este documento, las mismas que se
								detallan más adelante. Asimismo, la entrega de datos personales al agente
								inmobiliario es responsabilidad de cada usuario.</li>
							<li><b>Obtén tu crédito:</b> Esta sección aún no está disponible, sin embargo, tiene la futura
								finalidad de gestionar créditos con las entidades financieras más relevantes del país.
								Cuando este servicio esté habilitado, comunicaremos a nuestros usuarios.</li>
							<li><b>Publica gratis:</b> En esta sección, se habilita la posibilidad a cualquier usuario para
								publicar los inmuebles que tengan disponibles. Para publicar debes contar
								previamente con una cuenta en MERKASA. Más adelante te daremos la información
								para crear y gestionar tu cuenta en nuestra plataforma.</li>
							<p>Pero ya sea que tengas una cuenta particular o una cuenta profesional, puedes publicar
								inmuebles en venta anticrético o alquiler, para su oferta a través de nuestra plataforma.
								También puedes acceder a planes que te ayudarán a llegar a más usuarios. Recuerda que
								para ofertar un inmueble, debes ser propietario, titular o estar autorizado para su
								comercialización.</p>
							<p>Una vez registrada tu cuenta, para publicar ingresa a tu perfil, en la barra superior derecha,
								encontrarás el botón de “Publica gratis”, en la que al hacer clic se te presentará un formulario
								para introducir información detallada del inmueble en 3 pasos. Los pasos son:</p>
							<ul>
								<li style={{listStyle:'none'}}><b>PASO 1: Datos del inmueble </b>
									<ol type="a">
										<li><b>Tipo de inmueble:</b> Puedes seleccionar entres las opciones como ser Casa,
											Departamento, Terreno, Oficina, Casa de Campo, Edificio, Comercial,
											Industrial, Parqueo, Agrícola, Otros.</li>
										<li><b>Operación:</b> Especifica el tipo de transacción a realizarse. Todos, Venta,
											Alquiler o Anticrético. Si seleccionas todo, se entiende que la oferta está
											abierta a todas las modalidades u operaciones mencionadas.</li>
										<li><b>Ubicación:</b> Describe a detalle el lugar en el que se encuentra el inmueble,
											incluyendo Departamento, Provincia, Ciudad/Municipio o Barrio (Opcional).
											También podrás identificar la ubicación en el mapa interactivo. Sobre eldebes estar consciente de que la ubicación de ese inmueble será pública en el sitio web.</li>
									</ol>

								</li>
								<li style={{listStyle:'none'}}><b>PASO 2: Características:</b> En este apartado podrás establecer: 
									<ol type="a">
										<li><b>Precio:</b> Ya sea en bolivianos o en dólares americanos</li>
										<li><b>Dormitorios:</b> Número de habitaciones</li>
										<li><b>Baños:</b> Número de cuartos de baño</li>
										<li><b>Parqueos:</b>Espacio para movilidades en el inmueble</li>
										<li><b>Superficie y construcción:</b> Metros cuadrados (mt2) del terreno y mt2 de
											construcción, además del año de construcción.</li>
										<li><b>Título:</b> En este espacio podrás jugar con tu creatividad y ponerle un título
											atractivo a tu anuncio. Por ejemplo “Lujoso departamento en Equipetrol” o
											“Hermosa casa de campo en Bella Vista”</li>
										<li><b>Descripción:</b> Este es el espacio donde podrás describir detalles adicionales,
											tales como el material de construcción, algunas habitaciones especiales o o
											cualquier información valiosa para los interesados.</li>
										<li><b>Expensas:</b> Deberás especificar si tu inmueble cuenta con expensas, si estas
											son en bolivianos o en dólares,</li>
										<li><b>Garantía:</b> Si pidieras garantía, si esta será en bolivianos o dólares</li>
										<li><b>Características Adicionales:</b> Selecciona si tu inmueble tiene piscina, balcón,
											cochera, churrasquera, gimnasio, sauna, elevador, si se encuentra
											amueblado, calefacción, dependencia de servicios, jardín, baulera o
											penthouse.</li>
									</ol>
								</li>
								<li style={{listStyle:'none'}}><b>PASO 3: Fotografías: </b>
									En este apartado podrás subir desde tu equipo o celular, las
									fotografías del inmueble, con un mínimo de tres (3) fotografías y el máximo es de
									veinte (20) fotografías por inmueble. Recuerda que debes tener las licencias
									necesarias para publicar esas fotografías y que eres responsable de su uso.
									Finalmente, una vez que hayas completado todos los campos requeridos, podrás
									hacer clic en "publicar" para que el aviso de tu inmueble sea visible inmediatamente
									en el sitio web.
								</li>
							</ul>
							<li><b>Iniciar Sesión</b> Para iniciar sesión en MERKASA necesitas una cuenta previa. Si
								no tienes una, puedes hacer clic en “Iniciar sesión “ y luego en “REGISTRARME”. Al
								crear una cuenta, debes considerar que hay dos tipos de cuenta (particular y
								profesional), así como condiciones aplicables que describimos a continuación.</li>

						</li>
					</ol>

					<h3><b>VI. TU CUENTA EN MERKASA</b></h3>
					<ol type="1">
						<li><b>Acceso y registro</b> En esta sección encontrarás tres posibilidades, el registro, la cuenta y
							la recuperación de contraseña.
							<p></p>
							<ol type="a">
								<li><b>Registro en MERKASA: </b>Para tener acceso a nuestros servicios, es necesario
									registrarse en nuestra plataforma. Al registrarse, el usuario acepta proporcionar
									información veraz, actual y completa, incluyendo su nombre completo, dirección de
									correo electrónico, número de teléfono celular y una contraseña segura. Tu número
									de celular servirá para validar tu identidad cuando sea necesario.
									Al registrarse, el usuario debe estar de acuerdo con los términos y condiciones
									establecidos por MERKASA antes de completar el proceso de registro. Al hacer clic
									en el botón "Registrarse", el usuario confirma su consentimiento para crear una cuenta
									en MERKASA y acepta cumplir con tales políticas vigentes.</li>
								<li><b>Acceso con cuenta existente: </b> Si el usuario ya posee una cuenta en MERKASA,
									puede iniciar sesión para acceder a nuestros servicios. Para hacerlo, debe
									proporcionar su dirección de correo electrónico y contraseña asociados con su cuenta</li>
								<li><b>Recuperación de contraseña</b>En caso de haber olvidado la contraseña, el usuario puede hacer clic en "Restablecer
									contraseña". MERKASA se comunicará con el usuario a través del correo electrónico
									proporcionado para ayudar en la recuperación de la contraseña.</li>
							</ol>
						</li>
						<p></p>
						<li><b>Pasos para crear tu cuenta en MERKASA</b>
							<p></p>
							<ol type="a">
								<li><b>Accede a la página de registro: </b> Para crear una cuenta en MERKASA lo primero que
									debes hacer es acceder a la página de registro en el sitio web. Puedes encontrar el
									enlace para registrarte en la página de inicio, haciendo clic en “Inicio de sesión” o en
									la esquina superior derecha de la pantalla.</li>
								<li><b>Escoge tu tipo de cuenta: </b>Deberás escoger el tipo de cuenta que responda a la
									actividad que realizas y al uso que le darás a nuestra plataforma.
									<ol type="a">
										<li><b>Particular: </b>Para personas individuales que visitan nuestra página con la finalidad
											de realizar una búsqueda de inmuebles. También para aquellas personas
											propietarias de un inmueble y que buscan mostrarlo en el mercado. En general
											todas aquellas que no ofrecen servicios.</li>
										<li><b>Profesional: </b>Diseñada para aquellas personas que ofrecen sus servicios
											profesionales dentro o relacionado al rubro de la construcción, los principales
											ejemplos son agentes inmobiliarios, desarrolladores inmobiliarios y empresas
											inmobiliarias, pero también pueden ser de los siguientes tipos:
											<ol type="i">
												<li><b>Inmobiliario: </b> Agente, Desarrollador, Evaluador, Notario, Abogado,
													Asesor Crediticio,</li>
												<li><b>Mejoras para el hogar: </b>Albañil, Arquitecto, Diseñador de Interiores,
													Electricista, Gasfitero, Jardinero, Melamine, Drywall, Carpintero,
													Pintor, Tiendas electrodomésticas, Artistas y Decoración.</li>
											</ol>
										</li>
									</ol>
									<li><b>Proporciona tu información personal: </b>Una vez en la página de registro, se te pedirá
										que proporciones tu información personal. Esto incluye tu nombre completo, dirección
										de correo electrónico y número de teléfono celular. Es importante asegurarse de
										ingresar información veraz y actualizada para poder recibir notificaciones importantes
										y mantener una comunicación efectiva con MERKASA.</li>
									<li><b>Elige una contraseña segura: </b>Para proteger tu cuenta y asegurar que solo tú tengas
										acceso a ella, deberás crear una contraseña segura. Asegúrate de utilizar una
										combinación de letras, números y caracteres especiales, y evita utilizar información
										personal obvia o fácil de adivinar.</li>
									<li><b>Acepta los terminos y condiciones</b>Antes de finalizar el proceso de registro, se te
										solicitará que leas y aceptes los términos y condiciones de MERKASA. Es importante
										que leas detenidamente estos términos para comprender tus derechos y
										responsabilidades al utilizar la plataforma. Si estás de acuerdo con ellos, selecciona
										la casilla de verificación para confirmar tu consentimiento.</li>
									<li><b>Completa el registro: </b> Una vez que hayas proporcionado toda la información requerida
										y aceptado los términos y condiciones, haz clic en el botón "Registrarse" o "Crear
										cuenta" para completar el proceso de registro. Ahora podrás explorar propiedades,
										utilizar el buscador avanzado, guardar propiedades favoritas y, si eres propietario o
										agente inmobiliario, publicar tus inmuebles en la plataforma.</li>
									<li><b>Contraseña: </b> Recuerda que es importante mantener tus credenciales de inicio de
										sesión (correo electrónico y contraseña) de manera segura y no compartirlas con
										terceros para garantizar la seguridad de tu cuenta en MERKASA. Si en algún
										momento olvidas tu contraseña, puedes utilizar la opción de "Recuperación de
										contraseña" para restablecerla siguiendo los pasos proporcionados en la plataforma.</li>
								</li>
							</ol>
							<p></p>
							<li><b>Tu perfil en nuestra plataforma: </b></li>
							<p></p>
							<p>Al tener una cuenta en MERKASA, accederás a un escritorio o “dashboard” desde donde
								tienes acceso a las siguientes opciones:</p>
							<ol type='a'>
								<li>Podrás armar un perfil como usuario, interesado, comprador, propietario o titular de
									un inmueble. También como profesional, según el tipo de cuenta que hayas escogido
									conforme a lo descrito precedentemente sobre los tipos de cuenta.</li>
								<li>Tu nombre será visible en el sitio web cuando hayas iniciado sesión. Al hacer clic en
									tu nombre, tendrás cinco (5) opciones de páginas: “Mis avisos”, “Interesados”, “Vistos
									recientemente”, “Mi cuenta” y “Cerrar Sesión".</li>
								<li>En la pestaña denominada "Mis avisos", encontrarás tus publicaciones vigentes.</li>
								<li>En la pestaña denominada "Interesados" encontrarás la información de personas que
									hayan deseado contactarte desde tu perfil. Te recordamos que eres responsable del
									manejo de esa información y deberás usar la para los fines exclusivos de la
									plataforma.</li>
								<li>En la pestaña “Mi cuenta” encontrarás tus datos personales, como nombre, número
									de celular y correo electrónico. Además de poder cambiar tu fotografía de perfil y
									agregar contenido multimedia para hacerlo más atractivo.</li>
								<li>También podrás acceder a la sección de "Vistos recientemente". Aquí se registrarán
									todos los inmuebles que hayas seleccionado como favoritos de las listas de la galería
									de bienes inmuebles.</li>
								<li>Desde tu escritorio podrás publicar inmuebles en sus diferentes tipos de oferta y
									gestionar tus propiedades publicadas, tal como describimos anteriormente.</li>
								<li>MERKASA te permite ver las estadísticas de tu anuncio, para saber cuántas personas
									se interesaron por él.</li>
								<li>Finalmente, encontrarás el botón "Cerrar sesión", que te permitirá salir y concluir tu
									sesión en cualquier momento que lo desees, brindándote mayor control y seguridad
									en tu experiencia con MERKASA.</li>
							</ol>
						</li>
					</ol>
					<p></p>
					<h3><b>VII. CONDICIONES DE USO</b></h3>
					<p></p>
					<ol type='1'>
						<li><b>Condiciones generales: </b>Al registrar una cuenta en MERKASA, el usuario acepta y
							se compromete a cumplir con los siguientes términos y condiciones:
							<ol type='a'>
								<li><b>Uso del servicio: </b> El Usuario podrá utilizar su cuenta de MERKASA únicamente para
									las finalidades declaradas por la empresa en estos términos. Se prohíbe el uso de la
									cuenta para actividades ilícitas, fraudulentas o que violen los derechos de terceros,
									así como el uso de la cuenta para enviar contenido malicioso, spam, virus o cualquier
									otro elemento que pueda dañar o afectar el funcionamiento del Sitio web o a terceros</li>
								<li><b>Resguardo de la cuenta: </b>El Usuario será el único responsable de mantener la
									confidencialidad de su cuenta y contraseña. Se compromete a no compartir su cuenta
									ni permitir el acceso a terceros sin su supervisión. Cualquier actividad realizada a
									través de la cuenta del Usuario será de su exclusiva responsabilidad.</li>
								<li><b>Derechos sobre el inmueble: </b>Al poner al mercado un inmueble a través de una
									publicación en nuestro sitio web, debes ser el propietario real del inmueble o estar
									legalmente autorizado para su comercialización, ya sea a través de un contrato con el
									propietario del inmueble o a través de un poder suficiente de representación.</li>
								<li><b>Aceptación de Terminos y Condiciones: </b>Al registrar una cuenta en MERKASA, el
									Usuario reconoce haber leído, comprendido y aceptado los Términos y Condiciones,
									así como las Políticas de Privacidad. El Usuario se compromete a cumplir con todas
									las disposiciones establecidas en dichos documentos.</li>
								<li><b>Responsabilidad del Usuario: </b> El Usuario es responsable de todas las acciones
									realizadas a través de su cuenta. MERKASA no se hace responsable por el uso no
									autorizado de la cuenta o por la divulgación de información confidencial del Usuario.</li>
								<li><b>Suspensión o terminación de la cuenta: </b>MERKASA se reserva el derecho de
									suspender, limitar o cancelar la cuenta del Usuario en caso de incumplimiento de los
									términos y condiciones, o por cualquier otra razón justificada. En caso de suspensión
									o terminación, el Usuario será notificado y deberá cesar toda actividad en la
									plataforma.</li>
								<li><b>Eliminación de las cuentas inactivas: </b>Con la finalidad de mantener la información
									actualizada en nuestra plataforma, MERKASA se reserva el derecho de eliminar
									cuentas inactivas por un período mayor a seis (6) meses. MERKASA le enviará un
									correo electrónico previo a la eliminación de su cuenta para que inicie sesión y retome su actividad. Para reactuviar tu cuenta puedes solicitarlo a atencionalcliente@merkasa.com.bo
								</li>
							</ol>
							<li><b>Condiciones de Contenido: </b>Como quedó establecido, al tener una cuenta en nuestro sitio
								web, puedes cargar contenido en publicaciones cuyo fin sea publicitar un bien inmueble y
								lograr la venta, alquiler o anticrético del mismo, lo cual implica responsabilidad sobre la
								calidad de ese contenido, al cual se le aplican las siguientes condiciones.
								<ol type='a'>
									<li><b>Veracidad de la información: </b>El Usuario garantiza que toda la información
										proporcionada durante el proceso de registro es veraz, actual y completa. Asimismo,
										se compromete a mantener sus datos personales actualizados y a notificar a
										MERKASA cualquier cambio en la información suministrada.</li>
									<li><b>Lenguaje y contenido</b> Las publicaciones que realice en nuestro sitio web, deberán
										enmarcarse en los objetivos de esta plataforma, deberán usar un lenguaje amigable y
										respetuoso. De ninguna manera se permitirá publicaciones que inciten al odio, la
										violencia o la discriminación. Esto incluye la prohibición al uso de palabras, imágenes
										o videos, ofensivos, alarmantes, sobre peleas, sangre, o maltrato.</li>
									<li><b>Aspectos Legales: </b> Al ofertar un inmueble a través de nuestro sitio web, el usuario, sea
										propietario, apoderado o intermediario, garantiza que ese inmueble se encuentra
										disponible para el tipo de oferta publicada, que tiene las facultades necesarias para
										su comercialización y que no existe ningún vicio, gravamen o restricción oculta.</li>
									<li><b>Derechos de autor: </b>Las publicaciones solo deben contener fotografías que hayan sido
										tomadas por el titular del inmueble o por quien tenga permiso para usarlas. Esto
										significa que nadie debería subir imágenes o fotografías que no creó ni usar en sus
										publicaciones contenido cuyos derechos de autor pertenezcan a otra persona,
										incluyendo dibujos, videos, pistas musicales, fragmentos de programas protegidos por
										derechos de autor o videos producidos por terceros sin su autorización. Usted
										conservará los derechos de propiedad intelectual que posea sobre dicho contenido,
										en caso que sea de su creación.</li>
									<li><b>Imágenes de menores de edad: </b>Tampoco puedes usar en tus publicaciones fotografías
										donde se evidencie el rostro o el cuerpo de un menor de edad, niño, niña o
										adolescente, en protección a su identidad, integridad, honra y dignidad.</li>
									<li><b>Datos de terceros</b>Tampoco podrás usar en tus publicaciones, datos personales de
										terceros sin su autorización, esto incluye la exposición de números de teléfono,
										dirección, correo, fotografías, imágenes suyas o de su domicilio, bajo responsabilidad
										frente a estas personas.</li>
								</ol>
							</li>
							<li><b>Prohibiciones legales: </b>En el marco de las condiciones establecidas en los puntos
								precedentes, se disponen las siguientes prohibiciones legales.
								<ol type='a'>
									<li><b>Licitud: </b>Está estrictamente prohibido utilizar el Sitio web para actividades ilegales o
										fraudulentas. Cualquier usuario que lleve a cabo acciones que infrinjan la ley o
										constituyan un fraude será responsable legalmente por sus actos y estará sujeto a las
										sanciones correspondientes.</li>
									<li><b>Ciberseguridad: </b>Se prohíbe el uso de robots, spiders u otros medios automatizados
										para acceder al Sitio web o extraer información de los mismos sin autorización expresa
										de MERKASA. El uso de estos medios automatizados puede afectar negativamente
										la integridad y el rendimiento del Sitio, y cualquier infracción será tratada con medidas
										legales y/o técnicas para proteger la plataforma y sus usuarios. Se prohíbe interferirinterrumpir el funcionamiento del Sitio web, así como intentar acceder a áreas no
										autorizadas o realizar acciones que puedan afectar la seguridad o el rendimiento del
										Sitio web. Cualquier intento de interferir con el funcionamiento normal del Sitio, o de
										acceder a áreas restringidas sin autorización, será considerado una violación de las
										políticas de MERKASA y puede resultar en la suspensión o terminación de la cuenta
										del infractor.</li>
									<li><b>Respeto: </b>Queda prohibido acosar, amenazar, difamar o perjudicar a otros usuarios a
										través del Sitio web. En MERKASA promovemos un ambiente de respeto y
										colaboración entre los usuarios, y cualquier forma de acoso, amenaza, difamación o
										perjuicio hacia otros miembros de la comunidad no será tolerada y puede resultar en
										la suspensión o eliminación de la cuenta del infractor.</li>
									<li><b>Spam: </b> No está permitido utilizar el Sitio web para enviar correos no solicitados (spam)
										o realizar cualquier tipo de publicidad no autorizada. El envío de correos no solicitados
										o la publicidad no autorizada puede ser molesta para los usuarios y afectar
										negativamente su experiencia en la plataforma. Los usuarios que violen esta
										prohibición estarán sujetos a acciones disciplinarias.</li>
								</ol>

							</li>
							<li><b>Medidas y sanciones: </b>En MERKASA, nos comprometemos a mantener un entorno seguro
								y confiable para todos nuestros usuarios. Cualquier violación de estas prohibiciones será
								tratada con seriedad y puede conllevar consecuencias legales y/o acciones disciplinarias,
								según sea necesario para proteger los intereses de la comunidad y de la plataforma en
								general.
								<p>Por ello, MERKASA se reserva el derecho de tomar las medidas necesarias, incluyendo pero
									no limitando a las siguientes:</p>
								<ol type='a'>
									<li>Llamada de atención por escrito a los usuarios infractores de estos términos y
										condiciones.</li>
									<li>Eliminación de contenido que incumpla estos términos y condiciones.</li>
									<li>Suspensión o bloqueo de cuentas de usuarios infractores.</li>
									<li>Denuncia a las autoridades competentes, en caso de detectar un posible delito o daño
										civil a terceros en el marco de las leyes vigentes y aplicables.</li>
								</ol>
							</li>
						</li>
					</ol>
					<p></p>
					<h3><b>VIII. CONDICIONES DE PROPIEDAD INTELECTUAL</b></h3>
					<p></p>
					<p>En MERKASA, nos esforzamos por ofrecer servicios de alta calidad y una experiencia
						inmobiliaria excepcional. Nuestra plataforma está diseñada para satisfacer tus necesidades y
						preferencias específicas, facilitando la búsqueda de la propiedad adecuada de manera
						segura y eficiente, siempre en respeto de la propiedad intelectual de las partes que participan</p>
					<ol type='1'>
						<li><b>Sobre tu contenido:</b>
							<ol type='a'>
								<li><b>Concesión de licencia</b>Cuando un usuario ingrese, almacene o publique contenido en
									el sitio web, otorgará a MERKASA y a aquellos con quienes trabajamos, una licencia
									internacional para utilizar, alojar, almacenar, publicar, compartir, reproducir, difundir,
									publicar, ejecutar públicamente y distribuir dicho contenido. Los derechos que usted
									otorga en esta licencia tiene el objetivo de operar, promocionar, publicitar y mejoranuestros servicios o desarrollar otros nuevos. Esta licencia subsistirá aun cuando
									usted deje de utilizar nuestros servicios. Asegúrese de tener los derechos necesarios
									para otorgarnos ésta licencia sobre cualquier contenido que envíe a nuestros
									servicios.
									<li><b>Uso de la licencia concedida: </b>Al utilizar el servicio, los suscriptores autorizan a
										MERKASA el uso de sus marcas o nombres comerciales registrados, u otros signos
										distintivos registrados o no registrados, con el objetivo de operar, promocionar, difundir
										y mejorar nuestros servicios o desarrollar otros nuevos. Asimismo, autorizan a
										MERKASA a mostrar al público su nombre, fotografías e información contenida en su
										perfil, así como las acciones que realiza en el sitio web (todo tipo de publicaciones,
										opiniones o comentarios), incluida la aparición en anuncios y otros contextos
										comerciales.</li>
									<li><b>Derechos de terceros: </b> El usuario que ingrese, suba o publique cualquier tipo de
										información al sitio web, garantiza que ésta no infringe ningún derecho de propiedad
										intelectual, industrial, secreto comercial o cualquier tipo de derechos de terceros; que
										no tiene carácter confidencial, ni es perjudicial. Caso contrario, asumirá la
										responsabilidad emergente, manteniendo indemne a MERKASA por cualquier
										reclamo o perjuicio.</li>
									<li><b>Notificación: </b>Si cualquier usuario considera que alguno de los contenidos del sitio web,
										es ilegal o supone vulneración a derechos de propiedad intelectual, industrial, u otros
										derechos, deberá notificarlo inmediatamente a MERKASA, por escrito, a través de la
										dirección de correo electrónico atencionalcliente@merkasa.com.bo, para que
										podamos proceder a la adopción de las medidas correspondientes.</li>
								</li>
							</ol>
						</li>
						<p></p>
						<li><b>Sobre los derechos de Merkasa</b>
							<p></p>
							<p>MERKASA se reserva el derecho de tomar acciones legales para proteger sus derechos de
								propiedad intelectual y garantizar la exclusividad y respeto a sus contenidos generados.
								Cualquier infracción a esta cláusula dará lugar a la terminación inmediata de la cuenta del
								Usuario y podrá ser objeto de acciones legales para compensar los daños y perjuicios
								causados a MERKASA o terceros:</p>
							<ol type='a'>
								<li><b>Propiedad Intelectual: </b> MERKASA es el único titular de la propiedad intelectual de su
									marca y los derechos de autor sobre el software, así como sobre fotografías, imágenes
									o videos generados por la propia plataforma.</li>
								<li><b>Uso interno y promocional: </b> MERKASA podrá utilizar fotografías, imágenes o videos
									generados por la plataforma con fines internos y promocionales, exclusivamente
									dentro del ámbito de la plataforma y sus actividades comerciales relacionadas. Estos
									contenidos y servicios podrán ser utilizados para mejorar la experiencia del Usuario,
									promocionar propiedades en la plataforma y compartir información relevante sobre los
									inmuebles ofrecidos a través de los canales oficiales de MERKASA. Los derechos son
									reservados.</li>
								<li><b>Uso exclusivo de MERKASA</b>Los Usuarios aceptan que los íconos, fotografías,
									imágenes o videos generados por MERKASA están destinados exclusivamente para
									su visualización y promoción en las redes oficiales de nuestra empresa, aplicaciones
									y sitio web. Cualquier intento de utilizar, copiar, reproducir o compartir estos contenidos a título personal, o para brindar un servicio propio, fuera del alcance o
									previsto en el presente párrafo sin el consentimiento expreso estará estrictamente
									prohibido y dará lugar a consecuencias legales.</li>
								<li><b>Uso restringido y prohibiciones: </b>Los usuarios de MERKASA aceptan que no tienen
									autorización para copiar, reproducir, modificar, vender, utilizar o aprovechar, de
									cualquier manera, las fotografías, imágenes o videos sin autorización para cualquier
									fin, sea promocional o de comercialización con beneficio propio o para beneficio de
									terceros, incluidas otras inmobiliarias o plataformas similares. Cualquier uso no
									autorizado de estos contenidos constituirá una infracción de los derechos de
									propiedad intelectual de MERKASA y estará sujeto a las acciones legales pertinentes</li>
								<li><b>Protección de derechos de terceros: </b>MERKASA se compromete a respetar los
									derechos de propiedad intelectual de terceros y no utilizar ni publicar contenidos que
									infrinjan dichos derechos. Si algún Usuario o tercero considera que alguna fotografía,
									imagen o video ha sido utilizado sin autorización o viola sus derechos de propiedad
									intelectual, nos comprometemos a tomar las medidas adecuadas para defender y
									resolver el asunto de manera justa y diligente.</li>
								<li><b>Responsabilidad del usuario: </b>Los Usuarios son responsables de garantizar que tienen
									los derechos necesarios para cargar, publicar y compartir las fotografías, imágenes o
									videos de las propiedades que se registran en MERKASA. Al hacerlo, los Usuarios
									declaran y garantizan que cuentan con el consentimiento y la autorización necesaria
									de cualquier persona que apareció en las imágenes o videos y que no están
									infringiendo los derechos de terceros al proporcionar estos contenidos o servicios.</li>
								<li><b>Salvaguarda de la propiedad intelectual: </b>MERKASA se reserva el derecho absoluto
									de proteger su propiedad intelectual y tomar todas las medidas legales necesarias
									contra cualquier violación o uso no autorizado de marcas, íconos, software,
									fotografías, imágenes o videos por parte de terceros. Asimismo, MERKASA rechaza
									cualquier intento de apropiación, reproducción no autorizada o utilización indebida de
									estos contenidos o servicios y ejercerá sus derechos para hacer valer su exclusividad
									sobre los mismos, así como para hacer respetar los derechos de terceros.</li>
								<li><b>Medidas aplicables: </b>Cualquier infracción a esta cláusula dará lugar a la suspención o
									cancelación inmediata de la cuenta del usuario, lo que no impide que pueda ser objeto
									de acciones legales para compensar los daños y perjuicios causados a MERKASA o
									terceros.</li>
							</ol>
						</li>
					</ol>
					<p></p>
					<h3><b>IX. MODIFICACION Y CANCELACION DE NUESTROS SERVICIOS</b></h3>
					<p></p>
					<ol type="a">
						<li><b>Cambios en el sistema: </b>En MERKASA nos esforzamos por mejorar constantemente
							nuestros servicios. Por lo tanto, nos reservamos el derecho de realizar cambios y
							mejoras en el sitio web en cualquier momento. Esto incluye la posibilidad de agregar,
							limitar o eliminar funcionalidades, características o servicios, así como suspender o
							interrumpir un servicio en su totalidad. Te avisaremos cuando estos cambios afecten
							tus intereses o información.</li>
						<li><b>Aviso oportuno: </b>Entendemos la importancia de sus datos e información subida o
							publicada en el sitio web. Si en algún momento se cancelará un servicio, haremos
							todo lo posible para notificarle con la debida anticipación. Además, le brindaremos la
							oportunidad de obtener y resguardar su información de manera adecuada, siempre
							que sea razonablemente posible.</li>
						<li><b>Su información: </b> Nuestro objetivo es mantener una experiencia positiva para nuestros
							usuarios y, en caso de realizar cambios significativos o cancelar servicios, nos
							comprometemos a informarles y facilitar el acceso a sus datos para que puedan tomar
							las medidas necesarias para su resguardo. Seguimos trabajando para ofrecer un
							servicio de calidad y brindar soluciones eficaces a las necesidades de nuestros
							usuarios.</li>
					</ol>
					<p></p>
					<h3><b>X. NUESTRAS GARANTIAS Y LIMITES DE RESPONSABILIAD</b></h3>
					<p></p>
					<p>Al utilizar nuestros servicios, los usuarios reconocen haber leído, comprendido y aceptado los
						términos establecidos en esta cláusula de Garantías y Límites de Responsabilidad. Esta
						cláusula constituye un acuerdo vinculante entre MERKASA y los usuarios y rige la relación
						entre ambas partes en el uso de la plataforma.</p>
					<p>MERKASA se compromete a promover el profesionalismo y la excelencia en sus servicios,
						procurando siempre el mejor funcionamiento y seguridad, en la medida de las siguientes
						cláusulas.</p>
					<ol type='1'>
						<li><b>Garantias generales de MERKASA: </b>
							<p></p>
							<ol type='a'>
								<li><b>Resultados: </b>Nuestro objetivo es facilitar el contacto entre usuarios, compradores,
									vendedores, agentes intermediarios y otros actores mencionados del mercado, en
									otras palabras, conectar la oferta con la demanda en el sector inmobiliario, a través
									de la plataforma; sin embargo, no garantizamos ningún tipo de resultado para ambas
									partes. Tampoco garantizamos determinado alcance o cantidad de personas
									interesadas en los inmuebles ofertados. No podemos asegurar la concreción de
									transacciones o la consolidación de venta, el alquiler o el anticrético deseado.</li>
								<li><b>Verificación de la identidad</b>Procuramos la verificación de usuarios a través de su cédula
									de identidad y el nùmero de celular proporcionado; sin embargo, la verificación de
									identidad en Internet es difícil y poco certera, por lo tanto, no asumimos
									responsabilidad por la confirmación de identidad de ninguna persona, ni por la
									veracidad de sus datos personales.</li>
								<li><b>Veracidad de la información: </b>MERKASA procurará revisar o verificar la información y
									contenido de las publicaciones, sin embargo, no es responsable por la veracidad de
									la información publicada, tanto respecto a las características del inmueble, como
									respecto a las imágenes y contacto de los propietarios.</li>
								<li><b>Situación legal del inmueble: </b>MERKASA no formará parte de ningún tipo de contrato
									que pueda surgir del contacto entre usuarios, ni será responsable por los resultados,
									la calidad o la situación legal del inmueble ofrecido. No garantizamos la legalidad o
									viabilidad de los tratos comerciales realizados entre los usuarios. Tampoco seremos
									responsables de que exista algún vicio, gravamen o restricción para la venta, alquiler
									o anticrético de un inmueble ofertado en nuestro sitio web. En caso de que haya una
									mala fé o actitud delictiva, agradeceremos informar para poder colaborar en la
									e.investigación correspondiente. De requerir servicios legales adicionales podemos
									recomendar alguno.</li>
								<li><b>Responsabilidad de los usuarios: </b>Los usuarios, oferentes e interesados, entienden y
									aceptan que MERKASA no asume responsabilidad por los resultados, consecuencias
									o calidad de las transacciones realizadas entre ellos.</li>
							</ol>
						</li>
						<p> </p>
						<li><b>Responsabilidad por actividad de usuarios</b>
							<p></p>
							<ol type='a'>
								<li><b>Conducta de Usuarios: </b>A pesar de las medidas de seguridad y las políticas de
									contenido exigidas, MERKASA no puede controlar ni dirigir lo que los usuarios hacen
									o dicen, dentro o fuera de Internet, y no somos responsables por sus publicaciones,
									comentarios, acciones o conductas. No garantizamos la veracidad, integridad, ética o
									actualización del contenido que los usuarios comparten en la plataforma.</li>
								<li><b>Inmuebles ofertados: </b>No garantizamos que los inmuebles publicados en nuestro sitio
									web o aplicación se encuentran en las condiciones declaradas, que las fotos hacen
									honor a la verdad o que no existe algún conflicto, falla o restricción en el inmueble
									para su disposición o transferencia.</li>
								<li><b>Eliminación de contenido: </b>Nos reservamos el derecho de eliminar cualquier
									información o contenido contrario a la verdad, ofensivo, inapropiado, obsceno, ilegal
									o cuestionable, así como aquel que incite a la violencia, la discriminación, el odio o el
									racismo, así como cualquier contenido que vulnere estos términos y condiciones.</li>
								<li><b>Daños o perjuicios: </b>No asumimos responsabilidad por daños o perjuicios que puedan
									originarse por el uso de dichas informaciones o contenidos. Pero de ser notificados
									podremos colaborar en la investigación, proporcionando información en el marco de
									un proceso judicial en curso.</li>
							</ol>
						</li>
						<p></p>
						<li><b>Otras limitaciones de responsabilidad</b>
							<p></p>
							<ol type='a'>
								<li><b>Funcionamiento: </b>Trabajamos arduamente para brindar los servicios ofertados con la
									máxima eficiencia posible, conforme a los medios y las tecnologías disponibles.
									Declaramos que hemos adoptado todas las medidas técnicas y de seguridad
									disponibles para la protección y optimización del sitio web. No obstante, nuestros
									servicios se proporcionan tal como están y no podemos garantizar que siempre serán
									seguros, nunca tendrán errores o funcionarán sin interrupciones, demoras o
									imperfecciones. En la medida en que la ley lo permita, también renunciamos a toda
									garantía, expresa o implícita, incluidas las garantías implícitas de comerciabilidad e
									idoneidad para un fin particular.</li>
								<li><b>Seguridad: </b>MERKASA no garantiza la continuidad o permanencia de los contenidos
									en el sitio web, ni la invulnerabilidad de los sistemas de seguridad. No nos hacemos
									responsables ante la presencia de componentes dañinos en el sitio web o el servidor
									que lo suministra, ni de una posible transmisión de éstos componentes dañinos a
									computadoras o dispositivos móviles de los usuarios.</li>
								<li><b>Daños o perjuicios</b> Tampoco nos hacemos responsables por los daños o perjuicios
									que pudiera causar cualquier infracción a los presentes Términos y Condiciones o las
									Políticas de Privacidad establecidos. En la medida permitida por ley, excluimos todas
									las garantías.</li>
								<li><b>Servicios o ataques ajenos: </b>MERKASA no responderá por daños o perjuicios que
									pudieran surgir ante situaciones ajenas a nuestro control, entre las que se enumeran
									de manera no limitativa, las siguientes: problemas con la provisión regular o el acceso
									al servicio de Internet o electricidad; ataques cibernéticos; inconvenientes técnicos
									que escapen de nuestra gestión diligente; acciones u omisiones de terceros. En todos
									los casos referidos, no habrá lugar a indemnización alguna por parte de MERKASA
									ante cualquier daño o perjuicio que pudiera afectar a los usuarios, en la medida en
									que así lo permita la legislación vigente.</li>
								<li><b>Pérdidas o ganancias: </b>No podemos predecir cuándo puede surgir un problema con
									nuestros servicios. En consecuencia, nuestra responsabilidad se limitará al máximo
									alcance que la ley aplicable permita, y bajo ninguna circunstancia seremos
									responsables ante los usuarios, por la pérdida de ganancias, ingresos o información,
									ni por daños consecuentes, especiales, indirectos, ejemplares, punitivos o
									incidentales que surjan a raíz de los servicios de MERKASA, o en relación con ellos,
									incluso si se nos advirtió sobre la posibilidad de que ocurran dichos daños.</li>
								<li><b>Uso por empresas: </b>Si usa nuestros servicios en nombre de una empresa, esa
									empresa acepta los presentes Términos y Condiciones, así como nuestra Política de
									Privacidad. La empresa mantendrá indemne e indemnizará a MERKASA ante
									cualquier reclamo, pleito o acción relativa al uso indebido de nuestros servicios o el
									incumplimiento de estas condiciones, incluido cualquier costo o responsabilidad que
									surja de reclamos, pérdidas, daños, pleitos, fallos, costos de litigio y honorarios de
									abogados.</li>
								<li>En la medida permitida por la ley, excluimos todas las garantías, incluidas las
									implícitas de comerciabilidad e idoneidad para un fin particular.</li>
							</ol>
						</li>
						<p></p>
						<li>Derechos reconocidos por la ley
							<ol type='a'>
								<li><b>Derechos de los usuarios: </b> MERKASA reconoce los derechos que la Ley otorga a todo
									usuario o consumidor. Ninguna disposición de Términos y Condiciones limitará los
									derechos reconocidos por la Constitución y las Leyes, ni otros derechos que no
									puedan renunciarse contractualmente.</li>
								<li><b>Punto de reclamo: </b>Cualquier hecho o acto que identifique en el sitio web, o en relación
									a nuestros servicios, podrá ser notificada a atencionalcliente@merkasa.com.bo.</li>
							</ol>
						</li>
						<p></p>
					</ol>
				</div>
				<hr></hr>
				<div  style={{padding: '30px', fontFamily:'geometria'}} class='terminos2'>
					<h1><center>POLITICA DE PRIVACIDAD</center></h1>
					<p></p>
					<p>A fin de brindar nuestros servicios satisfactoriamente, debemos trabajar con la información
						de nuestros usuarios. En éste documento explicamos el modo en que tratamos sus datos
						personales y protegemos su privacidad.</p>
					<p>Al utilizar nuestros servicios, usted acepta que podamos utilizar dichos datos de acuerdo con
						nuestras políticas de datos. Comprendemos que se trata de una gran responsabilidad y
						trabajamos arduamente para proteger su información y darle el control sobre ella.</p>
					<p></p>
					<ol type='I'>
						<li><b>INFORMACION QUE RECOPILAMOS</b>
							<p></p>
							<p>El tipo de información que recopilamos depende de la forma en los usuarios utilizan nuestros
								servicios, considerando las siguientes situaciones:</p>
							<ol type='a'>
								<li> Cuando los usuarios navegan en el sitio web, sin identificación alguna, nosotros
									recopilamos información respecto al lugar desde donde navega, su actividad y sus
									preferencias de navegación, con el único objeto de estudiar, evolucionar y
									promocionar nuestros servicios.</li>
								<li>Cuando los usuarios deseen interactuar en el sitio web, lo que implica publicar
									anuncios, accederemos a la identidad y la información que tengan registrada en dicha
									cuenta, así como a la actividad que realice en el sitio web, ello con el único objeto de
									medir, estudiar, evolucionar y promocionar nuestros servicios.</li>
								<li>Para crear una cuenta MERKASA los usuarios deberán proporcionarnos su
									información personal incluyendo: nombre, cédula de identidad, teléfono de contacto y
									correo electrónico. En el caso de registrarse como persona jurídica, los usuarios
									deberán proporcionarnos la razón social o denominación de la empresa, la matrícula
									de comercio, el nombre del representante legal, su teléfono y correo electrónico. Dicha
									información será recopilada con el fin de mantener una base de datos de nuestros
									clientes, verificar su identidad y entablar un canal de comunicación permanente con
									ellos.</li>
								<li> Al crearse un cuenta en nuestro sitio web, el perfil es público, así como las
									publicaciones que se realizan a través del mismo, por lo que los suscriptores son
									conscientes de que toda la información ingresada a su perfil es pública y cualquier
									usuario que visite el sitio web puede acceder a ella, así como pueden acceder a todas
									las publicaciones realizadas a través de tu perfil, incluyendo: fotografías, videos,
									direcciones, ofertas de inmuebles y comentarios. Si el usuario deja su número de
									contacto o WhastApp, debe ser consciente de que muchas personas podrán
									contactarlo en cualquier momento.</li>
								<li>MERKASA recopila la información ingresada en tu perfil, el contenido que crea, carga
									o recibe de otras personas cuando usa nuestros servicios, ello con el único objeto de
									estudiar, evolucionar y promocionar nuestros servicios.</li>
								<li>Podemos recopilar información que forma parte de las cosas que los usuarios suben
									o comparten, como la ubicación de una fotografía o la fecha en que se creó un archivo.
									También podemos recopilar información sobre cómo los usuarios usan nuestros
									servicios, incluidas las interacciones con servicios de terceros o integraciones de otros
									sitios web, así como las personas con las que se conectan o comunican a través de
									nuestros servicios.</li>
								<li>Información que puede ser obtenida cuando visita nuestras redes sociales, sitios web
									de terceros o aplicaciones que usan nuestros servicios o se integran con ellos. Esto
									incluye información sobre los sitios web y las aplicaciones que visitas en relación a la
									utilización de nuestros servicios en dichos sitios web y dichas aplicaciones, así como
									la información que usted como usuario, el desarrollador o editor de la aplicación o el
									sitio web nos proporcionen.</li>
								<li>Información sobre los dispositivos que usa para acceder a nuestros servicios, como el
									sistema operativo, el identificador del dispositivo y la configuración, así como otra
									información a la que usted conceda a nuestros servicios en su dispositivo.</li>
								<li>En el caso de que productos o funciones futuras de MERKASA incluyan compras,
									recopilaremos información sobre tus compras o transacciones, como la información
									de pago, que incluye los datos de su tarjeta de crédito o débito, cuenta y datos de
									autenticación, o la información de facturación y envío.</li>
							</ol>
						</li>
						<p></p>
						<li><b>COMO USAMOS LA INFORMACIÓN</b></li>
						<p></p>
						<ol type='a'>
							<li>Usamos la información recopilada para proporcionar, mejorar, desarrollar y
								comprender nuestros servicios y para ofrecer experiencias, contenido e información
								personalizados para usuarios y otras personas, lo que puede incluir anuncios en
								Internet y otras formas de marketing dentro y fuera de nuestros servicios.</li>
							<li>También usamos su información para garantizar que nuestros servicios funcionen
								según lo previsto, por ejemplo, mediante un seguimiento de las interrupciones en los
								servicios o la solución de los problemas que nos informa. Además, usamos su
								información para implementar mejoras en nuestros servicios, por ejemplo,
								comprender cuáles son los términos de búsqueda que se escriben con errores
								ortográficos con mayor frecuencia nos permite mejorar las funciones de corrección
								ortográfica que usamos en todos nuestros servicios.</li>
							<li> Podemos usar la información que tenemos para realizar un análisis sobre los
								contenidos (textos, imágenes o videos) que los suscriptores publican o comparten y
								cómo los comparten, desde qué plataformas u otros sitios web; para definir tendencias
								y segmentar al público con fines comerciales.</li>
							<li> También podemos usar la información que tenemos para realizar un análisis sobre
								cómo las personas comparten imágenes o inmuebles (por ejemplo, desde el sitio web
								a servicios de terceros), para realizar encuestas e investigaciones y probar funciones
								en desarrollo; de igual modo, podemos analizar la información que tenemos para
								evaluar y mejorar productos y servicios, desarrollar nuevos productos o funciones, y
								realizar auditorías y acciones para solucionar problemas.</li>
							<li> Usamos la información que tenemos, incluida tu información de contacto, para
								comunicarnos contigo sobre la provisión de nuestros servicios, para enviarte
								mensajes de marketing que consideramos que pueden interesarte y para informarte
								sobre nuestros Términos y Condiciones y Políticas de Privacidad. También usamos la
								información que tenemos para responderle cuando se pone en contacto con nosotros</li>
							<li>Es importante aclarar que usamos la información que tenemos para fomentar la
								seguridad y la protección en nuestros servicios y para investigar el uso de nuestros
								servicios, incluidas cualquier actividad sospechosa o las posibles vulneraciones de
								nuestras Políticas de Privacidad, Términos y Condiciones.</li>
							<li>Podemos usar cookies y otras tecnologías para operar, proporcionar, personalizar,
								medir y dar asistencia sobre nuestros servicios; esto comprende prestar servicios
								basados en web, mejorar sus experiencias, entender cómo se usan nuestros servicios
								y protegerlos.</li>
							<li>También podemos usar kits de desarrollo de software (SDK) para leer, almacenar y
								acceder a información de tu dispositivo móvil, con el objeto de ayudar a proporcionanuestros servicios como se describen anteriormente, ello sólo en caso de que, algún momento, las aplicaciones para celulares usen nuestros servicios o se integren
								con ellos. Si la opción está disponible en tu dispositivo o navegador, puedes modificar
								la configuración de las cookies con las instrucciones que te proporcione tu dispositivo
								o el navegador del celular. Ten en cuenta que, si configuras tu navegador o dispositivo
								para que elimine, desactive o bloquee las cookies u otras tecnologías, es posible que
								no puedas acceder a algunas partes de los servicios y que otras partes de ellos no
								funcionen correctamente.</li>

						</ol>
						<p></p>
						<li><b>ACCESO Y ELIMINACIÓN DE LA INFORMACIÓN</b></li>
						<p></p>
						<ol type='1'>
							<li><b>Derechos ARCO: </b> En MERKASA, respetamos tu privacidad y te ofrecemos opciones para
								acceder, corregir, objetar el uso y eliminar tu información cuando lo desees, en el marco
								de la legislación vigente. A continuación, te explicamos cómo funcionan nuestros procesos
								sobre datos personales:
								<p></p>
								<ol type='a'>
									<li><b>Almacenamiento de información: </b>Conservamos tu información durante el tiempo
										necesario para proporcionar nuestros servicios de manera efectiva a ti ya otros
										usuarios, tal como se ha descrito anteriormente. Si tienes una cuenta en MERKASA,
										la información asociada a ella se mantendrá hasta que decidas eliminar tu cuenta.</li>
									<li><b>Acceso a la información: </b>En cualquier momento, podrás solicitarnos a través de un
										correo electrónico a atencionalcliente@merkasa.com.bo, la información personal que
										almacenamos sobre tí. MERKASA tiene la obligación de entregarte tu propia
										información, pero no así la de terceros, por lo que verificará tu identidad antes de
										entregarla.</li>
									<li><b>Rectificación: </b>El sitio web tiene la opción de modificar los datos de tu cuenta o perfil
										para poder corregirlos. Si hay información que no consideres correcta y no puedes
										cambiarla, puedes solicitarlo al correo electrónico atencionalcliente@merkasa.com.bo
										y nosotros nos ocuparemos de rectificar la información.</li>
									<li><b>Objeción: </b>Si ya no deseas que tu información sea utilizada para alguna de nuestras
										finalidades declaradas podrás solicitarnos a través de un correo electrónico al
										atencionalcliente@merkasa.com.bo en ejercicio de tu autodeterminación
										informativa.Pero debes estar consciente de que algunos servicios ya no podrán ser
										brindados sin acceso a tu información.</li>
									<li><b>Eliminación de cuenta: </b> Si deseas eliminar tu cuenta, tienes la opción de hacerlo en
										cualquier momento. Al eliminar tu cuenta, tu información personal y los datos
										asociados dejarán de estar disponibles en MERKASA. Sin embargo, es posible que
										por medidas de seguridad y resguardo legal, se mantenga almacenada por un tiempo
										prudente.</li>
									<li><b>Eliminación de publicaciones: </b> Para garantizar información actualizada, las
										publicaciones que ofrecen inmuebles se verificarán cada tres (3) meses, de no estar
										vigentes, se ocultaran del sitio web. Sin embargo, la información será conservada con
										fines de análisis y estadística. Si deseas renovar la publicación, puedes solicitarlo al
										correo atencionalcliente@merkasa.com.bo</li>
									<li><b>Retención de información cierta: </b> Es importante tener en cuenta que parte de la
										información que ha compartido con otros usuarios, y que no está directamente
										relacionada con su cuenta, no se eliminará automáticamente al eliminar su cuenta.
										Además, ciertos datos, como registros en nuestros sistemas de copia de seguridad,
										pueden permanecer almacenados por motivos técnicos o comerciales durante un
										período razonable.</li>
									<li><b>Desvinculación de información personal: </b>Al eliminar tu cuenta, el material o contenido
										que hayas compartido en MERKASA dejará de estar asociado a tu información
										personal identificable. Sin embargo, es posible que dicho material permanezca
										disponible en la plataforma de manera anónima o vinculado a otros usuarios para
										nuestros fines comerciales.</li>
									<p>Queremos asegurarnos de que tengas control sobre tus datos personales y te brindamos la
										posibilidad de acceder y eliminar tu información cuando lo desees. Si tienes alguna duda o
										necesitas asistencia para acceder o eliminar tu cuenta, no dudes en contactarnos a través de
										atencionalcliente@merkasa.com.bo Estamos aquí para ayudarte y proteger tu privacidad en
										todo momento.</p>
								</ol>
							</li>
							<p></p>
							<li><b>Respuesta a requerimientos y a prevención de daños: </b> En MERKASA, nos
								comprometemos a proteger la privacidad y seguridad de nuestros usuarios y su información.
								Sin embargo, en determinadas circunstancias, podemos acceder, conservar y compartir la
								información de los usuarios en respuesta a los requisitos legales o para evitar daños. A
								continuación, detallamos cómo podemos actuar:</li>
							<p></p>
							<ul>
								<li><b>Cumplimiento de requisitos legales: </b> Podemos acceder, conservar y compartir
									información sobre los usuarios si recibimos un requerimiento legal válido, como una
									orden judicial, citación, requerimiento fiscal u otra solicitud de una autoridad
									competente. En tales casos, actuaremos de buena fe y de acuerdo con la ley aplicable</li>
								<li><b>Prevención de fraudes y actividades ilegales: </b> Podemos acceder, preservar y compartir
									información si creemos de buena fe que es necesario detectar, prevenir o combatir
									actividades fraudulentas u otras conductas ilegales. También lo haremos para
									proteger nuestros servicios, a nuestros usuarios y otras personas de posibles daños
									o perjuicios.</li>
								<li><b>Investigaciones y seguridad: </b>En aras de la seguridad y para cumplir con nuestras
									políticas, podemos consultar, procesar y conservar información sobre los usuarios,
									incluida la información sobre transacciones financieras, durante un período
									prolongado si existe una solicitud u obligación legal, investigaciones
									gubernamentales, o investigaciones relacionadas con posibles infracciones de
									nuestras políticas o condiciones.</li>
								<li><b>Conservación de datos</b>En caso de que desactivemos cuentas de usuarios que hayan
									infringido nuestras condiciones, conservaremos sus datos durante un período mínimo
									de un año para evitar la repetición de conductas abusivas o infracciones de nuestras
									políticas.</li>
							</ul>
						</ol>
						<p></p>
						<p>Estamos comprometidos a actuar de manera responsable y cumplir con las leyes y aplicar
							para proteger a nuestros usuarios y mantener la integridad de nuestros servicios. Si tiene
							alguna pregunta o inquietud sobre cómo manejamos la información y los requisitos legales,
							no dude en contactarnos a través de atencionalcliente@merkasa.com.bo. Estaremos
							encantados de brindarte toda la información que necesitas.</p>
						<p></p>
						<li><b>CAMBIOS A ESTA POLITICA DE PRIVACIDAD</b>
							<p></p>
							<ol type="a">
								<li>atencionalcliente@merkasa.com.bo se reserva el derecho de realizar cambios y
									modificaciones a estas Políticas de Privacidad en cualquier momento y bajo su
									discreción. En caso de que se realice alguna modificación significativa a estas
									Políticas, te notificaremos por medio de una comunicación visible en nuestro sitio web
									y/o a través de un correo electrónico proporcionado por ti, con al menos quince (15)
									días de anticipación a la fecha en que los cambios entren en vigencia.</li>
								<li>Te recomendamos revisar periódicamente nuestras Políticas de Privacidad para estar
									informado sobre las actualizaciones. La "Fecha de entrada en vigor" en la parte
									superior de estas Políticas indicará la fecha en que se hizo la última modificación.</li>
								<li>Si tiene alguna duda o inquietud con respecto a nuestras Políticas de Privacidad o
									sobre nuestros servicios en general, no dude en contactarnos a través de la dirección
									de correo electrónico atencionalcliente@merkasa.com.bo. Estaremos encantados de
									atender todas tus consultas y brindarte la información que necesites.</li>
							</ol>
						</li>
						<p></p>
						<li><b>SOLUCIÓN DE CONTROVERSIAS</b>
							<p></p>
							<p>En caso de surgir alguna controversia relacionada con la prestación de nuestros servicios o
								la interpretación, aplicación, cumplimiento y ejecución de este Contrato, incluyendo los
								Términos y Condiciones y las Políticas de Privacidad, todos los usuarios, de cualquier
								nacionalidad, acuerdan resolver dicha controversia con MERKASA de la siguiente manera:</p>
							<p></p>
							<ul>
								<li><b>Comunicación amistosa: </b> En primera instancia, las partes se comprometen a intentar
									resolver la controversia a través de comunicaciones electrónicas amistosas enviadas
									al correo electrónico atencionalcliente@merkasa.com.bo.</li>
								<li><b>Conciliación</b>Si las comunicaciones electrónicas no logran llegar a una solución
									pacífica, las partes se someterán a conciliación en cualquier Centro de Conciliación
									autorizado en la ciudad de Santa Cruz de la Sierra, Bolivia.</li>
								<li><b>Arbitraje</b> En caso de que la conciliación no resulte en un acuerdo voluntario, la
									controversia será resuelta definitivamente mediante arbitraje institucional en el Centro
									de Conciliación y Arbitraje de la Cámara de Comercio y Servicios de la ciudad de
									Cochabamba, de acuerdo con el Reglamento vigente al momento de presentar la
									solicitud. El Tribunal Arbitral estará compuesto por un único árbitro y el idioma del
									arbitraje será el español.</li>
								<li><b>Renuncia a la instancia judicial: </b> Este acuerdo de solución de controversias implica la
									renuncia expresa por parte de los usuarios a iniciar cualquier proceso judicial contra
									MERKASA. A través de esta vía de resolución, buscamos lograr un procedimien●
									equitativo y eficiente para resolver cualquier diferencia que pudiera surgir,
									promoviendo la transparencia y la confianza en nuestras relaciones con los usuarios</li>
								<li><b>Daños y perjuicios</b> Lo dispuesto precedentemente no implica que no puedan iniciarse
									acciones legales o judiciales cuando existan daños y perjuicios a MERKASA o a
									terceros, ocasionados por otros usuarios o sujetos ajenos a la plataforma. En
									resguardo de los derechos constitucionales y el patrimonio de la empresa.</li>
							</ul>
						</li>
						<p></p>
						<li><b>ACERCA DE LOS TERMINOS Y CONDICIONES</b>
							<p></p>
							<ol type="a">
								<li>En MERKASA, nos encontramos en constante crecimiento y aprendizaje, lo que
									implica que nuestros estándares y expectativas pueden evolucionar con el tiempo. Por
									esta razón, podemos modificar nuestros Términos y Condiciones o a las Políticas de
									Privacidad o implementar condiciones adicionales cuando sea necesario, ya sea por
									cambios en nuestras instrucciones, sitio web o servicios ofrecidos. Siempre
									comunicaremos y publicaremos avisos en el sitio web para informar sobre cualquier
									modificación realizada a los Términos y Condiciones o a las Políticas de Privacidad,
									así como la implementación de condiciones adicionales.</li>
								<li>Es responsabilidad de los usuarios revisar periódicamente los Términos y Condiciones
									y las Políticas de Privacidad. Las modificaciones no se aplicarán retroactivamente y
									entrarán en vigencia quince (15) días después de su publicación. No obstante, las
									modificaciones que reflejan nuevas funciones de un servicio o cambios legales
									entrarán en vigencia de manera inmediata.</li>
								<li>Si no estás de acuerdo con las condiciones modificadas de un servicio, te sugerimos
									dejar de utilizarlo o dar de baja tu cuenta. De lo contrario, seguir utilizando nuestro
									Sitio web o cualquiera de nuestros servicios implicará una aceptación tácita de dichas
									modificaciones.</li>
								<li>En caso de conflictos entre los presentes Términos y Condiciones y condiciones
									adicionales, prevalecerán las condiciones adicionales en todo lo referente al conflicto.</li>
								<li> Nuestros Términos y Condiciones y las Políticas de Privacidad rigen la relación entre
									MERKASA y tú, y no otorgan derechos a favor de terceros. Si no cumplimos
									inmediatamente con alguna de estas condiciones, no significa que renunciamos a
									cualquier derecho o acción que podamos ejercer en el futuro.</li>
								<li> Si alguna disposición de los Términos y Condiciones resulta inválida o inejecutable,
									ello no afectará la validez de las demás disposiciones.</li>
								<li>Si deseas comunicarte con MERKASA, visita nuestra página "Sobre nosotros" en el sitio web o envia tus consultas a atencionalcliente@merkasa.com.bo, estaremos encantados de atenderte.</li>
							</ol>


						</li>
					</ol>

				</div>
				<p><b><center>¡Gracias por utilizar nuestro servicio!</center></b></p>
			</Layout>
		</>
		)
}
