import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../useLocalStorage";
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';


  var map;
  var geocoder;
  const API_KEY = 'AIzaSyDF09ajXzzNFYGK6tPuWX6yCBzK0DUeSmM';

 export default function SearchGoogleMapsRegistro({Vlat,Vlng})
  {
    const [address,setAddress] = useLocalStorage('address','');
    const [coordinates,setCoordinstes] = useLocalStorage('coordinates',{
      lat:null,
      lng:null
  })



geocoder = new window.google.maps.Geocoder();

    const handleSelect = async value => {
    
    renderMap();

    const results = await geocodeByAddress(value);
    
    const ll = await getLatLng(results[0]);

    let infowindow = new window.google.maps.InfoWindow();
    let marker,i;

    map.setCenter(results[0].geometry.location);

    setAddress(value);
    //

    

    //console.log(ll.lat);

    marker = new window.google.maps.Marker({
      map: map,
      position:  new window.google.maps.LatLng(ll.lat, ll.lng),
      draggable: true,
      icon: "https://merkasa.com.bo/img/MerkasaIcon.svg"
    });

    window.google.maps.event.addListener(marker, 'dragend', function(ev){
     // alert(ll.lat + ' ' + ll.lng); // always the same LatLng-Object...
      //alert(JSON.parse(marker.getPosition())); // new LatLng-Object after dragend-event...
     // alert(marker.get);

     sessionStorage.setItem("CoordinatesLatRegAnun",JSON.stringify(marker.getPosition().lat()));
     sessionStorage.setItem("CoordinatesLngRegAnun",JSON.stringify(marker.getPosition().lng()));
     map.setCenter(marker.getPosition());
     geocodePosition(marker.getPosition());
  });



    //setCoordinstes(ll);

    sessionStorage.setItem("CoordinatesLatRegAnun",JSON.stringify(ll.lat));
        sessionStorage.setItem("CoordinatesLngRegAnun",JSON.stringify(ll.lng));
        sessionStorage.setItem("DireccionRegAnun",JSON.stringify(value));

    }

    function geocodePosition(pos) {
      geocoder.geocode({
        latLng: pos
      }, function(responses) {
        if (responses && responses.length > 0) {
          sessionStorage.setItem("DireccionRegAnun",JSON.stringify(responses[0].formatted_address));
          setAddress(responses[0].formatted_address);
        } else {
          alert('Cannot determine address at this location.');
        }
      });
    }

    function renderMap()
    {
        const coords = { lat: Vlat, lng: Vlng };
        const el = document.getElementById('mapaDetalle');
    
        if (el) {
          map = new window.google.maps.Map(el, {
            zoom: 12,
            center: {
              lat: coords.lat,
              lng: coords.lng,
            },
            mapTypeId: window.google.maps.MapTypeId.ROADMAP
          });
    
          return map;
        } else {
          return null;
        }
      }

      async function CargarMapa()
      {
          if (!window.google) {
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.src =
                `https://maps.googleapis.com/maps/api/js?key=` +
                API_KEY +
                `&libraries=geometry,places`;
              script.id = 'googleMaps';
              script.async = true;
              script.defer = true;
              document.body.appendChild(script);
              script.addEventListener('load', (e) => {
                renderMap();
              });
            } else {
              renderMap();
            }
      }



    useEffect(() => {
        
      
        
        /*sessionStorage.setItem("CoordinatesLatRegAnun",JSON.stringify(coordinates.lat));
        sessionStorage.setItem("CoordinatesLngRegAnun",JSON.stringify(coordinates.lng));
        sessionStorage.setItem("DireccionRegAnun",JSON.stringify(address));*/
        
        CargarMapa();

        const Lat = sessionStorage.getItem("CoordinatesLatRegAnun");
        const Lng = sessionStorage.getItem("CoordinatesLngRegAnun");
        let infowindow = new window.google.maps.InfoWindow();
        let marker_f,i;
        if((Lat != null && Lng != null))
        {
          

          marker_f = new window.google.maps.Marker({
                    map: map,
                    draggable: true,
                    position:  new window.google.maps.LatLng(JSON.parse(Lat), JSON.parse(Lng)),
                    icon: "https://merkasa.com.bo/img/MerkasaIcon.svg"
                  });
      
          map.setCenter(marker_f.getPosition());
          
          window.google.maps.event.addListener(marker_f, 'dragend', function(ev){
            // alert(ll.lat + ' ' + ll.lng); // always the same LatLng-Object...
             //alert(JSON.parse(marker.getPosition())); // new LatLng-Object after dragend-event...
            // alert(marker.get);
       
            sessionStorage.setItem("CoordinatesLatRegAnun",JSON.stringify(marker_f.getPosition().lat()));
            sessionStorage.setItem("CoordinatesLngRegAnun",JSON.stringify(marker_f.getPosition().lng()));
            map.setCenter(marker_f.getPosition());
            geocodePosition(marker_f.getPosition());
         });

 

          return;
        }

        
    },[])

    const searchOptions = {
      componentRestrictions: { country: ['bo'] },
    }


    return(
        <>
        <PlacesAutocomplete
        searchOptions={searchOptions}
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
			<div className="form-control borderInputSearch2 searchconfig">            
				<input id="txtBusquedaRegistro"
              {...getInputProps({
                placeholder: 'Busca un lugar ...',
                //className: 'location-search-input',
                className:'location-search-input borderInputSearch2'
              })}
            />
            <div>
              {loading && <div>Buscando...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: 'orange', cursor: 'pointer', width: '280px'}
                  : { backgroundColor: '#fafafa', cursor: 'pointer' }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                      
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <br></br>
      <br></br>
      <div id='mapaDetalle'>

      </div>
      </>
    )

  }
