import React,{useState,useEffect} from "react";
import axios from 'axios'; // npm i axios
import imgcama from "../img/cama.svg";
import imgbanio from "../img/banio.svg";
import imgcochera from "../img/cochera.svg";
import imgterreno from "../img/medida_02.svg";
import imgconstruido from "../img/medida_01.svg";
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoadingSpinner from "./LoadingSpinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from "react-bootstrap/Carousel";
import {useNavigate } from "react-router-dom";
import imgcorazon1 from "../img/corazon_2.svg";
import imgcorazon2 from "../img/corazon1.svg";
const zoomOutProperties = {
	duration: 5000,
	transitionDuration: false,
	infinite: false,
	indicators: false,
	scale: 0.4,
	arrows: true
};

function CardAnunciosAgente({Id,direccion,Precio,TipoInmueble,ListaImagenes,VisibleDestacar,terreno,construccion,dormitorios,banios,parqueos,CodigoAnuncio,Latitud,Longitud,Descripcion,Transaccion,Titulo,Nombre,ApellidoPaterno,Celular,EstadoProyecto,FechaEntrega,Favoritos}) 
{
	//Slideshow(ListaImagenes);
	//console.log(ListaImagenes);
	const [items, setItems] = useState();
	const [isVisible, setIsVisble] = useState('');
	const [index, setIndex] = useState(0);
	const [megusta, setMegusta] = useState(false);

	async function fetchApi()
	{
		/*let fd = new FormData() 
   fd.append("idanuncio",CodigoAnuncio)

   const res = await axios.post('https://merkasa.com.bo/api/ApiListarAnunciosImg.php', fd);*/

		const jsonNew = JSON.parse(ListaImagenes);
		setItems(jsonNew.items);

		//setItems(res.data.items);
		setIsVisble(VisibleDestacar);
		}

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
		};

	const navigate = useNavigate();

	const handleRedirect = () => {
		window.open('DetalleAnuncio/'+ btoa(CodigoAnuncio));
		}

	useEffect(() => {
		fetchApi();
	},[])

	//setItems(jsonNew);

	//setItems();
	const inmueble = () => {
	 let tipo = parseInt(TipoInmueble);
    if (tipo === 1) {
      return "Casa";
    } else if (tipo === 2) {
      return "Departamento";
    } else if (tipo === 3) {
      return "Terreno";
    } else if (tipo === 4) {
      return "Oficina";
    } else if (tipo === 5) {
      return "Casa de campo";
    } else if (tipo === 6) {
      return "Local Comercial";
    } else if (tipo === 7) {
      return "Comercial";
    } else if (tipo === 8) {
      return "Industrial";
    } else if (tipo === 9) {
      return "Parqueo";
    } else if (tipo === 10) {
      return "Agrícola";
    } else {
      return "";
    }
  };
	function estadoProyecto(){
		if (EstadoProyecto === '1') {
			return "En Planos";
			}
		if (EstadoProyecto === '2') {
			return "En Construcción";
			}	
		if (EstadoProyecto === '3') {
			return "Finalizado";
			}
		}
	function calcularFavoritos(id){
		if (Favoritos == null)
			{
			return;
			}
		for (let index = 0; index < Favoritos.length; index++) {
			if(Favoritos.length > 0){
				if(Favoritos[index].idAnuncio == id){
					setMegusta(true)
					break
					}
				setMegusta(false)
				}
			}
		}
async function handleClick(tipo) {


		if(tipo == 1)
			{  
			const idusuario = localStorage.getItem("idusuario");

			if(idusuario==null || idusuario.length < 1){
				alert("Debe iniciar sesión para poder guardar este anuncio a favoritos");
				return
				}
			if(megusta == false)
				{
				setMegusta(true);
				if (idusuario != ''){
					const data = JSON.stringify({
						pidusuario: idusuario,pidanuncio:CodigoAnuncio
						})
					await fetch('https://merkasa.com.bo/api/ApiRegistroFavoritos.php', {
						method: 'POST',
						body: data,
						headers: {
							'Content-type': 'application/json; charset=UTF-8',
							}
						})
					.then((response) => response.json())
					.then((data) => {
						//setPosts((posts) => [data, ...posts]);

						const respuesta = data.idAnuncioF;
						console.log("respuesta: ",respuesta);

						if(respuesta == 0)
							{
							console.log("data: ",data);
							return; 
							}else{

								if(isNaN(respuesta))
									{
									alert("Hubo un error en el registro del anuncio")
									}
								//handleRedirect();
								}
						})
					}
				}
			else{
				setMegusta(false);
				const data = JSON.stringify({
					idanuncio : CodigoAnuncio,
					})

				await fetch('https://merkasa.com.bo/api/ApiEliminarFavorito.php', {
					method: 'POST',
					body: data,
					headers: {
						'Content-type': 'application/json; charset=UTF-8',
						},
					})
				.then((response) => response.json())
				.then((data) => {
					//setPosts((posts) => [data, ...posts]);

					const respuesta = JSON.parse(data);

					if(respuesta != '1')
						{
						alert("Hubo un error en el sistema, inténtelo mas tarde.")

						}})
				.catch((err) => {
					console.log(err.message);
					});

				}

			}
		}
function formatoCaracteristicas(){
		let caracteristicas = [];
		if (dormitorios > 0){
			caracteristicas.push(dormitorios+" Dorm ");
			}
		if (banios > 0){
			if (banios == 0){
				caracteristicas.push(banios+" Baños ");
				}
			else{
				caracteristicas.push(banios+" Baño ");
				}
			}
		if (parqueos > 0){
			caracteristicas.push(parqueos+" Parq ");
			}
		if (terreno > 0){
			caracteristicas.push(terreno+" m2 tot.");
			}
		return caracteristicas.join(" | ");
		}


			return (
				<> 
					<Card   style={{width:'298px', height:'380px', border:'1px solid black',cursor:'pointer',overflow:'hidden'}} className="cardVistos">
				<Card.Body>
					<Card.Title style={{width: "100%",
						height: "200px" }}>
						<Carousel activeIndex={index} onSelect={handleSelect} interval={null} indicators={true} >

							{ !items ? <LoadingSpinner></LoadingSpinner> : items.map( (item) => {
								return (
									<Carousel.Item onClick={(e) => handleRedirect()}>
										<LazyLoadImage
											src={item.imagen}
											style={{
												objectFit: "cover",width:'100%',margin:'0 0 0 0',height:'200px' }}
											></LazyLoadImage>
										</Carousel.Item>
									)
									})      
								}
							</Carousel>
						</Card.Title>
						<Card.Text style={{minHeight:'10rem',widht:'100%'}} onClick={handleRedirect}>
							<br></br>
							<Container >
								{Transaccion =="Proyecto" ? 
								<Row style={{height:'43px'}}>
									<Col xs={6} sm={7}>
										<div className='divPrecioTopNew'>
											<p style={{display:'flex'}}>Desde $us {parseInt(Precio.toString().replace(/,/g,''))}</p>
										</div>
									</Col>
									<Col xs={6} sm={5}>
										<b><p  className='divTransaccionTopNew'style={{fontSize:"12pt",padding:'0 0 0 0',textAlign:"center",lineHeight:"15px",overflowWrap:"break-word",whiteSpace:'normal'}}>{Transaccion} {estadoProyecto()}</p></b>
									</Col>

								</Row>
										:<Row className='datosAnuncios'>
											<b><p style={{fontSize:"14pt",padding:'0 0 0 0',position:'relative',top:'5px',height:'30px',whiteSpace:'nowrap',margin:'0 0 10px 0'}}>{inmueble()} en {Transaccion}</p></b>
											<div className='divPrecioTopNew' style={{position:'relative',bottom:'2px', margin:'0 0 10px 10px'}}>
												<p>$us {Precio}</p>
											</div>

											<p style={{fontSize:'11pt',margin:'0 0 10px 10px',whiteSpace:'nowrap'}} >{formatoCaracteristicas()}</p> 
											<p style={{fontSize:'10pt',margin:'0 0 10px 10px'}}>{direccion}</p>
									</Row>}
										{Transaccion =='Proyecto' ?
										<><Row style={{flexWrap:'nowrap',height:'20px',gap:'20px'}} id='card-detalle-agente'>
											{dormitorios == 0 ? <></>:<><Col id='dormitorio-project' style={{whiteSpace:'nowrap',padding:'0 0 0 15px', width:'33%'}}>
										<p style={{fontSize:"8pt"}}><img src={imgcama} style={{height:'12px'}} ></img><b> Desde {dormitorios} dormitorios</b></p>
						</Col></>}

						{banios == 0 ? <></>:<><Col style={{whiteSpace:'nowrap',padding:'0 0 0 10px', width:'33%'}} id='banio-project'>
					<p style={{fontSize:"8pt"}} ><img src={imgbanio} style={{height:'12px'}}></img><b> Desde {banios} baños</b></p>
	</Col></>}

	{construccion == 0 ? <></>:<><Col style={{whiteSpace:'nowrap',padding:'0 0 0 0', width:'33%'}} id='const-project'>
<p style={{fontSize:"8pt"}} ><img src={imgconstruido} style={{height:'12px'}} ></img><b> {construccion} m²</b></p>
		  </Col></>}


	  </Row>
	  <Row>
		  <Col xs={12}>
			  <div style={{borderBottom:"1px solid #9c9c9c"}}>
			  </div>
		  </Col>
	  </Row>
	  <Row>
		  <Col xs={12}>
			  <p style={{textAlign:"left",fontSize:"10pt",paddingTop:"10px"}}>{direccion}</p>
		  </Col>
	  </Row>
  </>
												:null}
											</Container>
										</Card.Text>
									</Card.Body>
								</Card>

		</>

		);
		}

		export default CardAnunciosAgente;
