import React, { useState, useEffect } from "react";
import axios from "axios"; // npm i axios
import imgcama from "../img/cama.svg";
import imgbanio from "../img/banio.svg";
import imgcochera from "../img/cochera.svg";
import imgterreno from "../img/medida_02.svg";
import imgconstruido from "../img/medida_01.svg";
import imgcorazon1 from "../img/corazon_2.svg";
import imgcorazon2 from "../img/corazon1.svg";
import "react-slideshow-image/dist/styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoadingSpinner from "./LoadingSpinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Carousel from "react-bootstrap/Carousel";
import { Link, useNavigate } from "react-router-dom";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

const zoomOutProperties = {
  duration: 5000,
  transitionDuration: false,
  infinite: false,
  indicators: false,
  scale: 0.4,
  arrows: true,
};

function CardAnunciosProyectos({
  Id,
  Title,
  Precio,
  ListaImagenes,
  VisibleDestacar,
  terreno,
  construccion,
  dormitorios,
  banios,
  parqueos,
  CodigoAnuncio,
  Latitud,
  Longitud,
  Descripcion,
  EstadoProyecto,
  FechaEntrega,
  Transaccion,
  Titulo,
  Nombre,
  ApellidoPaterno,
  Celular,
  Favoritos,
  Moneda
}) {
  //Slideshow(ListaImagenes);
  //console.log(ListaImagenes);

  const [items, setItems] = useState();
  const [isVisible, setIsVisble] = useState("");
  const [index, setIndex] = useState(0);
  const [megusta, setMegusta] = useState(false);

  async function fetchApi() {
    /*let fd = new FormData() 
   fd.append("idanuncio",CodigoAnuncio)

   const res = await axios.post('https://merkasa.com.bo/api/ApiListarAnunciosImg.php', fd);*/

    const jsonNew = JSON.parse(ListaImagenes);
    setItems(jsonNew.items);

    //setItems(res.data.items);
    setIsVisble(VisibleDestacar);
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const navigate = useNavigate();

  const handleRedirect = () => {
    window.open("DetalleAnuncio/" + btoa(CodigoAnuncio));
  };
  function calcularFavoritos(id) {
    if (Favoritos == null) {
      return;
    }
    for (let index = 0; index < Favoritos.length; index++) {
      if (Favoritos.length > 0) {
        if (Favoritos[index].idAnuncio == id) {
          setMegusta(true);
          break;
        }
        setMegusta(false);
      }
    }
  }

  useEffect(() => {
    fetchApi();
    calcularFavoritos(CodigoAnuncio);
  }, []);

  async function handleClick(tipo) {
    if (tipo == 1) {
      const idusuario = localStorage.getItem("idusuario");
      if (localStorage.length <= 1) {
        alert(
          "Debe iniciar sesión para poder guardar este anuncio a favoritos"
        );
        return;
      }

      if (megusta == false) {
        setMegusta(true);
        if (idusuario != "") {
          const data = JSON.stringify({
            pidusuario: idusuario,
            pidanuncio: CodigoAnuncio,
          });
          await fetch("https://merkasa.com.bo/api/ApiRegistroFavoritos.php", {
            method: "POST",
            body: data,
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              //setPosts((posts) => [data, ...posts]);

              const respuesta = data.idAnuncioF;

              if (respuesta == 0) {
                return;
              } else {
                if (isNaN(respuesta)) {
                  alert("Hubo un error en el registro del anuncio");
                }
                //handleRedirect();
              }
            });
        }
      } else {
        setMegusta(false);
        const data = JSON.stringify({
          idanuncio: CodigoAnuncio,
        });

        await fetch("https://merkasa.com.bo/api/ApiEliminarFavorito.php", {
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            //setPosts((posts) => [data, ...posts]);

            const respuesta = JSON.parse(data);

            if (respuesta != "1") {
              alert("Hubo un error en el sistema, inténtelo mas tarde.");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
  }
  //setItems(jsonNew);

  //setItems();
  function estadoProyecto() {
    if (EstadoProyecto === 1) {
      return "en Planos";
    }
    if (EstadoProyecto === 2) {
      return "en Construcción";
    }
    if (EstadoProyecto === 3) {
      return "Finalizado";
    }
  }
  function precioProyecto() {
    if (Precio.indexOf("-") === -1) {
      return Precio;
    } else {
      return Precio.substring(0, Precio.indexOf("-"));
    }
  }
  return (
    <>
      <Card
        key={Id}
        id="card-proyectos"
        style={{
          width: "100%",
          height: "100%",
          border: "1px solid black",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Card.Body>
          <Card.Title
            style={{ borderRadius: "10px", width: "290px", height: "200px" }}
            className="cardTitleProyectos"
          >
            <div
              className="ToolTipDestacado"
              style={{ visibility: VisibleDestacar }}
            >
              Destacado
            </div>
                        <div
              className="ToolTipCorazonCardAnuncios"
              style={{ visibility: "visible" }}
            >
              {megusta == true ? (
                <>
                  <button
                    type="button"
                    className="icon-container"
                    onClick={() => handleClick(1)}
                  >
                    <MdFavorite className="love-icon" />
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="icon-container"
                    onClick={() => handleClick(1)}
                  >
                    <MdFavoriteBorder className="love-icon" />
                  </button>
                </>
              )}
            </div>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
              indicators={true}
			  touch={false}
              id="carousel-proyectos"
            >
              {!items ? (
                <LoadingSpinner></LoadingSpinner>
              ) : (
                items.map((item) => {
                  return (
                    <Carousel.Item onClick={(e) => handleRedirect()} >
                      <center>
                        <LazyLoadImage
                          effect="blur"
                          className="cardAnuncioProyectos"
                          src={item.imagen}
                          style={{
                            borderRadius: "5px",
                            objectFit: "cover",
                            width: "300px",
                            margin: "0 0 0 0",
							height: "200px",
                          }}
                        ></LazyLoadImage>
                      </center>
                    </Carousel.Item>
                  );
                })
              )}
            </Carousel>
          </Card.Title>
          <Card.Text
            style={{ height: "100%" }}
            onClick={() => handleRedirect()}
          >
            <br></br>
            <Container>
              <Row>
                <Col xs={8} bg={7}>
                  <div className="divPrecioTopNew">
                    <p>Desde {Moneda==="BS"?"Bs":"$us"} {precioProyecto()}</p>
                  </div>
                </Col>
                <Col xs={4} bg={5}>
                  <p
                    style={{
                      fontSize: "10pt",
                      padding: "0 0 0 0",
                      textAlign: "center",
                      lineHeight: "15px",
                      overflowWrap: "break-word",
                    }}
                  >
                    <b>
                      {Transaccion} {estadoProyecto()}
                    </b>
                  </p>
                </Col>
              </Row>
              <Row style={{ height: "60px" }}>
                <Col xs={12} style={{ padding: "0 0 0 15px" }}>
                  {dormitorios == 0 ? (
                    <>
                      <p></p>
                    </>
                  ) : (
                    <>
                      <div>
                        <p style={{ fontSize: "8pt" }}>
                          <img
                            src={imgcama}
                            style={{ height: "10px", padding: "0 10px 0 0" }}
                          ></img>
                          <b>
                            Desde {dormitorios}{" "}
                            {dormitorios == 1 ? "dormitorio" : "dormitorios"}
                          </b>
                        </p>
                      </div>
                    </>
                  )}
                </Col>
                <Col xs={12} style={{ padding: "0 0 0 15px" }}>
                  {banios == 0 ? (
                    <>
                      <p></p>
                    </>
                  ) : (
                    <>
                      <p style={{ fontSize: "8pt" }}>
                        <img
                          src={imgbanio}
                          style={{ height: "13px", padding: "0 10px 0 0" }}
                        ></img>
                        <b>
                          Desde {banios} {banios == 1 ? "baño" : "baños"}
                        </b>
                      </p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      borderBottom: "1px solid #9c9c9c",
                      margin: "5px 0 7px 0",
                    }}
                  ></div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p style={{ textAlign: "left", fontSize: "7pt" }}>
                    <b>{Title}</b>
                  </p>
                </Col>
              </Row>
            </Container>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default CardAnunciosProyectos;
