import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

function Recortar({ imageSrc, onCropComplete }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };
  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const onCropCompleteHandler = (croppedArea, croppedAreaPixels) => {
    onCropComplete(croppedArea, croppedAreaPixels);
  };

  return (
    <div className="crop-container">
      <Cropper
        image={imageSrc}
        crop={crop}
        zoom={zoom}
        aspect={1 / 1} 
        onCropChange={onCropChange}
        onZoomChange={onZoomChange}
        onCropComplete={onCropComplete}
      />
    </div>
  );
}

export default Recortar;

