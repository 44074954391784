import React from "react";
import LogoAmarillo from '../img/logoamarillo.svg';
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
export default function Footer(){

const navigate = useNavigate();
function handleClick(tipo) {
	return function(e) {		//Closure
	e.preventDefault();
		if(tipo == 1){
			sessionStorage.setItem('selectOperacion','1');
		}
		if(tipo == 2){
			sessionStorage.setItem('selectOperacion','2');
			}
		if(tipo == 4){
			sessionStorage.setItem('selectOperacion','4');
		}
		navigate('/ListAnuncios');

}
}
return (
<>
<br></br>
<br></br>
<footer id="footer1" class="text-center text-lg-start bg-light text-muted ">
 
  <section id="footer2" class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
  
    <div class="me-5 d-none d-lg-block">
      <span>Síguenos en nuestras redes:</span>
    </div>
 
    <div id='redes-sociales-footer'>
      <a href="https://www.facebook.com/profile.php?id=61550027876220" target='_blank' rel='noreferrer' class="me-4 text-reset">
        <i class="fab fa-facebook-f fa-lg"></i>
      </a>
      <a href="https://www.tiktok.com/@merkasabolivia" target='_blank' rel='noreferrer' class="me-4 text-reset">
        <i class="fab fa-tiktok fa-lg"></i>
      </a>
      <a href="https://www.instagram.com/merkasabolivia/" target= '_blank' rel='noreferrer'class="me-4 text-reset">
        <i class="fab fa-instagram fa-lg"></i>
      </a>
    </div>
   
  </section>


  <section id="footer3" class="">
    <div class="">
    
      <div class="row mt-3">
        
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 logoMerkasaClass">
        
          <h6 class="text-uppercase fw-bold mb-4">
            <i>
              <img src={LogoAmarillo} id="logoMerkasa" alt="logoMerkasa"></img>
              </i>
          </h6>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 servicios-vap">
 
          <h6 class="text-uppercase fw-bold mb-4">
            SERVICIOS
          </h6>
          <p>
            <a href="#!" class="text-reset" onClick={handleClick(1)}>Venta</a>
          </p>
          <p>
            <a href="#!" class="text-reset" onClick={handleClick(2)}>Alquiler</a>
          </p>
          <p>
            <a href="#!" class="text-reset" onClick={handleClick(4)}>Proyectos</a>
          </p>
        </div>



        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

          <h6 class="text-uppercase fw-bold mb-4">
            MERKASA
          </h6>
          <p>
			  <Link to='/SobreNosotros'style={{color:"inherit"}}>Sobre Nosotros</Link>
          </p>
        </div>

        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4" style={{width:"500px"}}>
          <h6 class="text-uppercase fw-bold mb-4">CONTÁCTANOS</h6>
          <p><i class="fas fa-home me-2"></i> atencionalcliente@merkasa.com.bo</p>
          <p><i class="fas fa-phone me-3"></i>+591 71066573</p>
        </div>
   
      </div>
  
    </div>
  </section>



  <div class="text-center p-4" style={{backgroundColor: '#000', opacity:8, color:'white',fontsize:'6pt'}}>
    Con la colaboración de SpeedAIx | Merkasa | Copyright © 2023. Derechos reservados
  </div>

</footer>
</>
)


}
