import React from 'react';
import PropTypes from 'prop-types'; // ADDED
import {drawImage} from 'canvas-object-fit';

const getBase64StringFromDataURL = (dataURL) =>
    dataURL.replace('data:', '').replace(/^.+,/, '');


const Canvas = ( {source,height,width,index,estilo} ) => { // CHANGED
  const canvas = React.useRef();
  const base64 = '';
	React.useEffect(() => {
		const context = canvas.current.getContext('2d'); 
		const image = new Image();
		image.src = source;
		
		image.onload = () => {
		  drawImage(context, image, 0, 0,width,height,{objectFit: 'cover'});
    };
    //draw(context); // ADDED
  });
  return (

 <canvas
      ref={canvas}
      width={width}
      height={height}
	  className= {estilo + ' canvasf'}
      id={'canvas-'+index}
	  key={index}
    />
  )
};
// ADDED
Canvas.propTypes = {
  height: PropTypes.func.isRequired,
  width: PropTypes.func.isRequired,
};
export default Canvas;
