import React, {useState, useCallback ,useEffect  } from "react";
import ReactPlayer from 'react-player'
import IconEdificio from "../img/iconoEdificio.png";
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link,useNavigate } from "react-router-dom";


const zoomOutProperties = {
	duration: 5000,
	transitionDuration: false,
	infinite: false,
	indicators: true,
	scale: 0.4,
	arrows: false
};

function CardAgentes({Id,ListaImagenes,CantAnuncios,VideoUrl,Nombre,Descripcion,Foto,Facebook,Instagram,Tiktok,NumeroCelular}) 
{
	//Slideshow(ListaImagenes);
	//console.log(ListaImagenes);

	const [items, setItems] = useState();

	async function fetchApi(){

		if(ListaImagenes != null || ListaImagenes != undefined)
		{
			const jsonNew = JSON.parse(ListaImagenes);
			setItems(jsonNew.items)
		}

	}
	const navigate = useNavigate();

	const handleSelect = (idAgente,Nombre) => {


		sessionStorage.setItem("idAgenteSeleccionado",idAgente);
		sessionStorage.setItem("NombreAgenteSeleccionado",Nombre);
		sessionStorage.setItem("CantidadAnuncios",CantAnuncios);
		sessionStorage.setItem("DescripcionAgenteSeleccionado",Descripcion);
		sessionStorage.setItem("FotoPerfilAgenteSeleccionado",Foto);
		sessionStorage.setItem("FacebookAgenteSeleccionado",Facebook);
		sessionStorage.setItem("InstagramAgenteSeleccionado",Instagram);
		sessionStorage.setItem("TiktokAgenteSeleccionado",Tiktok);
		sessionStorage.setItem("NumeroCelularAgenteSeleccionado",NumeroCelular);



		navigate('/MiperfilAgente');

	}

	useEffect(() => {
		fetchApi();
	},[])

	//setItems(jsonNew);

	//setItems();

	return (
		<> 
			<Card key={Id} style={{ width: '300px', height:'100%', borderRadius:'12px',overflow:'hidden'}} className='cardAnuncio'>
				<Card.Body >
					<Card.Title >
							<ReactPlayer
								url={VideoUrl}
								className='react-player'
								playing={false}
								controls={true}
							/>
					</Card.Title>
					<Card.Text>
						<div className='divAgenteTopNew'>
							<Container onClick={(e) => handleSelect(Id,Nombre)}>
								<Row>
									<Col xs={8}>
										<p style={{fontSize:"8pt",paddingTop:"8px",textAlign:"left"}}>{Nombre}</p>
									</Col>
									<Col xs={4}>
										<p><a style={{fontSize:"8pt",paddingTop:"5px",textAlign:"right"}} href="#" onClick={(e) => {handleSelect(Id,Nombre)}}>Ver Perfil</a></p>
									</Col>
								</Row>
								<Row>
									<Col xs={2}>
										<img src={IconEdificio} style={{width:"30px", height:"20px", position:'relative',bottom:'10px'}} alt="emoticono-edificio"></img>
									</Col>
									<Col xs={10}>
										<p style={{textAlign:"left",fontSize:"8pt",paddingTop:"8px", position:'relative', bottom:'10px'}}><b>{CantAnuncios} inmuebles en la lista</b></p>
									</Col>
								</Row>
							</Container>
						</div>
					</Card.Text>
				</Card.Body>
			</Card>


		</>
		);
}

export default CardAgentes;
