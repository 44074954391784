import React, {useState, useCallback ,useEffect  } from "react";
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios'; // npm i axios
import Layout3 from "./Components/Layout3";
import Canvas from './Components/Canvas';
import {FaCheckCircle, FaTrash,BiFilterAlt,FaPlus } from 'react-icons/fa'; // npm i react-icons
import Table from 'react-bootstrap/Table';
import { Link,useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AgentePromocionate2 from "./AgentePromocionate2";




function AgentePromocionate()
{   
    

    return(
        <>
         <Layout3>
            <Container style={{padding:'calc(2em + 2vw) 0 0 0'}}>
                <Row>
                    <Col sm={3}>
                    </Col>
                    <Col sm={6}>
                        <AgentePromocionate2></AgentePromocionate2>
                    </Col>
                    <Col sm={3}>
                    </Col>
                </Row>
            </Container>
        </Layout3>
        </>
    );

}

export default AgentePromocionate;

