import React,{useState,useEffect} from "react";
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from "../useLocalStorage";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';



const zoomOutProperties = {
	duration: 5000,
	transitionDuration: false,
	infinite: false,
	indicators: false,
	scale: 0.4,
	arrows: true
};

function CardAnunciosUsuario({Id,direccion,Precio,ListaImagenes,VisibleDestacar,CodigoAnuncio,Latitud,Longitud,Descripcion,Terreno,Construccion,Dormitorios,Banios,Parqueos,Transaccion,Titulo,Nombre,ApellidoPaterno,Celular,Visualizaciones,Escaneos,Interesados,Imagenes,TipoInmueble,IdTransaccion,CodDepartamento,CodProvincia,CodMunicipio,Barrio,PrecioDesde,PrecioHasta,AnioContruccion,Expensas,MonedaPrecio,MonedaExpensas,FechaEntrega,EstadoProyecto,NroPisosEdificio,NroDepartamentoPiso}) 
{
	/*Variables locales de Registro Anuncio*/
	const [tipoinmueble, settipoinmueble] = useLocalStorage('tipoinmueble','');
	const [tipotransaccion, settipotransaccion] = useLocalStorage('tipotransaccion','');
	const [vdepartamento, setvdepartamento] = useLocalStorage('vdepartamento','');
	const [vprovincia, setvprovincia] = useLocalStorage('vprovincia','');
	const [vmunicipio, setvmunicipio] = useLocalStorage('vmunicipio','');
	const [barrio, setbarrio] = useLocalStorage('barrio','');
	const [address,setAddress] = useLocalStorage('address','');
	const [bvisitaPagina, setVisitaPagina] = useLocalStorage('bvisitaPagina',false);
	const [codigoActual, setCodigoActual] = useLocalStorage('codigoActual','');
	/*Variables locales de Registro Anuncio caracteristicas*/
	const [descripcion, setdescripcion] = useLocalStorage('descripcion','');
	const [preciodesde, setpreciodesde] = useLocalStorage('preciodesde','');
	const [preciohasta, setpreciohasta] = useLocalStorage('preciohasta','');
	const [precio, setprecio] = useLocalStorage('precio','');
	const [terreno, setterreno] = useLocalStorage('terreno','');
	const [construccion, setconstruccion] = useLocalStorage('construccion','');
	const [anioconstruccion, setanioconstruccion] = useLocalStorage('anioconstruccion','');
	const [titulo, settitulo] = useLocalStorage('titulo','');
	const [dormitorios, setdormitorios] = useLocalStorage('dormitorios','');
	const [banios, setbanios] = useLocalStorage('banios','');
	const [parqueos, setparqueos] = useLocalStorage('parqueos','');
	const [expensas, setexpensas] = useLocalStorage('expensas','');
	const [monedaprecio, setmonedaprecio] = useLocalStorage('monedaprecio','US');
	const [monedaexpensas, setmonedaexpensas] = useLocalStorage('monedaexpensas','BS');
	const [fechaEngrega, setFechaEntrega] = useLocalStorage('fechaEngrega','');
	const [estadoproyecto, setEstadoProyecto] = useLocalStorage('estadoproyecto','');
	const [nroPisosEdificio, setNroPisosEdificio] = useLocalStorage('nroPisosEdificio','');
	const [nroDepartamentoPiso, setnroDepartamentoPiso] = useLocalStorage('nroDepartamentoPiso','');
	const [codigoAnuncio, setcodigoAnuncio] = useLocalStorage('codigoAnuncio','');
	/*Fin*/

	const [show, setShow] = useState(false);
	const [motivo, setmotivo] = useState('');

	const navigate = useNavigate();

	const handleClose = () => {
		setShow(false)
		};

	const handleRedirect = () => {
		window.open('DetalleAnuncio/'+ btoa(Id));
		}

	const handleClickEdit = () => {
		settipoinmueble(TipoInmueble)
		settipotransaccion(IdTransaccion)
		setvdepartamento(CodDepartamento)
		setvprovincia(CodProvincia)
		setvmunicipio(CodMunicipio)
		setbarrio(Barrio)
		setAddress(direccion)
		setVisitaPagina(true)
		setCodigoActual(CodigoAnuncio)
		setcodigoAnuncio(CodigoAnuncio)

		/*Variables para caracteristicas del anuncio*/
		setdescripcion(Descripcion)
		setprecio(Precio)
		setterreno(Terreno)
		setconstruccion(Construccion)
		settitulo(Titulo)
		setdormitorios(Dormitorios)
		setbanios(Banios)
		setparqueos(Parqueos)
		setpreciodesde(PrecioDesde)
		setpreciohasta(PrecioHasta)
		setanioconstruccion(AnioContruccion)
		setexpensas(Expensas)
		setmonedaprecio(MonedaPrecio)
		setmonedaexpensas(MonedaExpensas)
		setFechaEntrega(FechaEntrega)
		setEstadoProyecto(EstadoProyecto)
		setNroPisosEdificio(NroPisosEdificio)
		setnroDepartamentoPiso(NroDepartamentoPiso)

		sessionStorage.setItem("CoordinatesLatRegAnun",JSON.stringify(parseFloat(Latitud)));
		sessionStorage.setItem("CoordinatesLngRegAnun",JSON.stringify(parseFloat(Longitud)));
		sessionStorage.setItem("CodAnuncioActual",JSON.stringify(CodigoAnuncio));
		sessionStorage.setItem("tipotransaccionActual",JSON.stringify(IdTransaccion));
		sessionStorage.setItem("DireccionRegAnun",JSON.stringify(direccion));
		navigate('/RegistroAnuncioEdit')


		}

	const handlepreEliminar = () => {

		setShow(true);

		}

	const handleClickEliminar = async() => {

		let fechaActual = new Date();
		fechaActual = fechaActual.toLocaleDateString();

		if(motivo.length == 0)
			{
			alert("Ingrese texto antes de eliminar.")
			return;
			}


		if(window.confirm('Esta apunto de eliminar el anuncio. ¿Desea continuar?'))
			{
			const data = JSON.stringify({
				idanuncio : CodigoAnuncio,
				motivo:motivo,
				fechaInactivo: fechaActual,
				})

			await fetch('https://merkasa.com.bo/api/ApiDesactivaAnuncio.php', {
				method: 'POST',
				body: data,
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
					},
				})
			.then((response) => response.json())
			.then((data) => {
				//setPosts((posts) => [data, ...posts]);

				const respuesta = JSON.parse(data);

				if(respuesta == 1)
					{
					alert("El anuncio fue eliminado."); 
					window.location.reload(true);
					return; 
					}else{

						alert("Hubo un error al actualizar los datos, intente mas tarde.")
						}
				})
			.catch((err) => {
				console.log(err.message);
				});
			}

		}

	const [urlImagen, setUrlimagen] = useState();
	const jsonNew = JSON.parse(Imagenes)
	function precioProyecto(){
		if (Precio.indexOf('-') ===-1){
			return Precio;
			}
		else{
			return Precio.substring(0, Precio.indexOf('-'));
			}
		}
	const inmueble = () => {
	 let tipo = parseInt(TipoInmueble);
    if (tipo === 1) {
      return "Casa";
    } else if (tipo === 2) {
      return "Departamento";
    } else if (tipo === 3) {
      return "Terreno";
    } else if (tipo === 4) {
      return "Oficina";
    } else if (tipo === 5) {
      return "Casa de campo";
    } else if (tipo === 6) {
      return "Local Comercial";
    } else if (tipo === 7) {
      return "Comercial";
    } else if (tipo === 8) {
      return "Industrial";
    } else if (tipo === 9) {
      return "Parqueo";
    } else if (tipo === 10) {
      return "Agrícola";
    } else {
      return "";
    }
  };



	return (
		<> 
			<Card style={{ width: '100%', cursor:'pointer',border:'1px solid black',height:'200px',margin:'0 0 30px 0' }}  className='cardAnuncio' >
				<Card.Body >
					<Card.Text>
						<Container style={{padding:'20px 25px 0 25px'}} id='containerAvisos'>
							<Row >
								<Col md={2} style={{padding:'0 0 0 0'}} onClick={handleRedirect}>
									<img src={(jsonNew.items.length === 0) ? '' : jsonNew.items[0].imagen} style={{width:'100%',height:'140px',borderRadius:'10px',cursor:'pointer', objectFit:'cover',marginBottom:'10px'}} className='imgAvisos' alt='imagenAnuncio' ></img>

								</Col>
								<Col md={3} style={{padding:'0 0 0 25px'}}>
									{Transaccion=='Proyecto' ? 
									<Row className='divPrecioTopNewUsuario'>
										<Col md={12} style={{padding:'0 0 0 4px'}}>
											<p>Desde {MonedaPrecio==="BS" ? "Bs" : "$us"} {precioProyecto()}</p>
										</Col>
									</Row>

											:<Row className='divPrecioTopNewUsuario' onClick={handleRedirect}>
												<Col md={12} style={{padding:'0 0 0 4px'}}>
													<p>{MonedaPrecio ==="BS" ? "Bs" : "$us"} {Precio}</p>
												</Col>
											</Row>
											}	
											{Transaccion=='Proyecto' ?
											<Row onClick={handleRedirect}>
												<Col md={12}>
													<p style={{fontSize:"12pt",paddingTop:'10px'}}>{Transaccion}</p>
												</Col>
											</Row>

													:<Row onClick={handleRedirect}>
														<Col md={12}>
															<p style={{fontSize:"12pt",paddingTop:'10px'}}>{inmueble()} en {Transaccion}</p>
														</Col>
													</Row>
													}
													<Row onClick={handleRedirect}>
														<Col md={12}>
															<div>
																<p style={{textAlign:"left",fontSize:"8pt"}}><b>{direccion}</b></p>
															</div>
														</Col>
													</Row>

													<Row className='editar-eliminar'>
														<Col xs={6}>
															<button type='button' className="btnEditarAnuncio" onClick={handleClickEdit}>Editar Anuncio</button>
														</Col>
														<Col xs={6}>
															<button type='button' className="btnEliminarAnuncio" onClick={handlepreEliminar}>Eliminar Anuncio</button>
														</Col>
													</Row>
												</Col>
												<Col sm={{span:6, offset:1}} onClick={handleRedirect}>
													<Container id='visualizaciones' style={{padding:'0 0 0 0'}}>
														<Row>
															<Col md={10}>
																<p style={{fontSize:'10pt',marginLeft:'12px',position:'relative',top:'10px'}}>Desempeño:</p>
															</Col>
														</Row>
														<Row>
															<Container style={{borderRadius:'10px', backgroundColor:'#D7D7D7', height:'100%',width:'90%'}}>
																<Row id='fila-visualizaciones'>
																	<Col xs={4} style={{borderRight:'1px solid black'}} id='col-vis'>
																		<p style={{fontSize:'8pt',textAlign:'center'}}>Visualizaciones</p>
																	</Col>
																	<Col xs={4} style={{borderRight:'1px solid black'}}>
																		<p style={{fontSize:'8pt',textAlign:'center'}}>Interesados</p>
																	</Col>
																	<Col xs={4} >
																		<p style={{fontSize:'8pt',textAlign:'center'}}>Escaneos QR</p>
																	</Col>

																</Row>

																<Row id='fila-valores'>

																	<Col xs={4} style={{borderRight:'1px solid black' }} id='col-valores'>
																		<p style={{fontSize:'14pt',textAlign:'center'}}><b>{Visualizaciones}</b></p>
																	</Col>
																	<Col xs={4} style={{borderRight:'1px solid black'}}>
																		<p style={{fontSize:'14pt',textAlign:'center'}}><b>{Interesados}</b></p>
																	</Col>
																	<Col xs={4}>
																		<p style={{fontSize:'14pt',textAlign:'center'}}><b>{Escaneos}</b></p>
																	</Col>

																</Row>
															</Container>
														</Row>
													</Container>
												</Col>
											</Row>
											<Row>
											</Row>
										</Container>
									</Card.Text>

								</Card.Body>
							</Card>
							<Modal centered={true} show={show} onHide={handleClose}>
								<Modal.Header closeButton>
									<Container>
										<Row>
											<Col sm={12}>
												<center><h5>Indique el motivo de eliminar el anuncio</h5></center>
											</Col>
										</Row>
									</Container>
								</Modal.Header>
								<Modal.Body>
									<Form.Group className="sm-3">
										<Form.Label>Ingrese motivo</Form.Label>
										<Form.Control
											type="text"
											value={motivo}
											onChange={(e) => setmotivo(e.target.value)}

											//required
		/>
	</Form.Group>
	<br></br>
	<center><button type='button'  className='btnEliminarAnuncio' onClick={handleClickEliminar}>Eliminar Anuncio</button></center>
</Modal.Body>
</Modal>
</>

);
}

export default CardAnunciosUsuario;
