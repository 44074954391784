import React, { useState,useEffect } from 'react';
import Layoutf from "./Components/Layoutf";
import SearchGoogleMaps from './Components/SearchGoogleMaps';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function HomeF(){


    return(
        <>
        <Layoutf>
            
        </Layoutf>
        </>
    );


}


export default HomeF;