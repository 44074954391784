import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
//import App , {Menu} from "./App"; //{App,App2} para devolver varias funciones

import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Home from "./Home";
import MiComponente from "./ListAnuncios";
import ListAgentes from './ListAgentes';
import MiperfilAgente from "./MiperfilAgente";
import RegistroAnuncio from './RegistroAnuncio';
import RegistroAnuncioCaracteristicas from './RegistroAnuncioCaracteristicas';
import RegistroAnuncioFotos from './RegistroAnuncioFotos';
import DetalleAnuncio from './DetalleAnuncio';
import DetalleUsuario from './DetalleUsuario';
import DetalleUsuarioI from './DetalleUsuarioI';
import DetalleUsuarioM from './DetalleUsuarioM';
import DetalleUsuarioV from './DetalleUsuarioV';
import AgentePromocionate from './AgentePromocionate';
import HomeF from './HomeF';
import RegistroAnuncioEdit from './RegistroAnuncioEdit';
import RegistroAnuncioCaracteristicasEdit from './RegistroAnuncioCaracteristicasEdit';
import RegistroAnuncioFotosEdit from './RegistroAnuncioFotosEdit';
import Nosotros from './SobreNosotros';
import TerminosCondiciones from './TerminosCondiciones';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 
<>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} exact />
        <Route path="/ListAnuncios" element={<MiComponente/>} />
        <Route path="/ListAgentes" element={<ListAgentes/>} />
        <Route path="/MiperfilAgente" element={<MiperfilAgente/>} />
		<Route path="/DetalleAnuncio/:idAnuncio" element={<DetalleAnuncio/>} />
        <Route path="/RegistroAnuncio" element={<RegistroAnuncio/>} />
        <Route path="/RegistroAnuncioCaracteristicas" element={<RegistroAnuncioCaracteristicas/>} />
        <Route path="/RegistroAnuncioFotos" element={<RegistroAnuncioFotos/>} />
        <Route path="/DetalleUsuario" element={<DetalleUsuario/>} />
        <Route path="/DetalleUsuarioI" element={<DetalleUsuarioI/>} />
        <Route path="/DetalleUsuarioM" element={<DetalleUsuarioM/>} />
        <Route path="/DetalleUsuarioV" element={<DetalleUsuarioV/>} />
        <Route path="/AgentePromocionate" element={<AgentePromocionate/>} />
        <Route path="/Homef" element={<HomeF/>} />
		<Route path="/RegistroAnuncioEdit" element={<RegistroAnuncioEdit/>} />
        <Route path="/RegistroAnuncioCaracteristicasEdit" element={<RegistroAnuncioCaracteristicasEdit/>} />
        <Route path="/RegistroAnuncioFotosEdit" element={<RegistroAnuncioFotosEdit/>} />
        <Route path="/SobreNosotros" element={<Nosotros/>} />
        <Route path="/TerminosCondiciones" element={<TerminosCondiciones/>} />
      </Routes>
    </BrowserRouter>
  </>

)

