import React from "react"
import Header2 from "./Components/Header2"
import {useEffect} from "react"
import CasaMision from "./img/CasaMision.png"
import EdificioVision from "./img/EdificioVision.png"
import FamiliaValores from "./img/FamiliaValores.png"

function Nosotros(){
	useEffect(()=>{
			window.scrollTo(10, 0);
	})
	return(
		<>
		<Header2/>
		{window.innerWidth > 768 ?
		<>
			<h1 id='tituloMerkasa'><b>Conoce sobre <b style={{color:'orange'}}>Merkasa</b></b></h1>
		<div id="nosotros">
			<div>
			<h1><b>Misión</b></h1>
			<p style={{textAlign:'justify',lineHeight:'14pt'}}>La misión de Merkasa es actuar como colaborador estratégico en el mercado inmobiliario, utilizando tecnología e innovación para hacer el proceso y todos los servicios relacionados más accesibles, transparentes y eficientes, creando sinergias y ofreciendo una experiencia completa a todos los participantes.</p>
		</div>
			<img src={CasaMision} alt="mision"></img>
			<img src={EdificioVision}></img>
			<div>
<h1><b>Visión</b></h1>

			<p style={{textAlign:'justify',width:'53%',lineHeight:'14pt'}}>Nuestra visión es proporcionar un acceso integral a la mayor fuente de información del mercado inmobiliario, creemos firmemente que aportaremos al desarrollo de nuestro país al aplicar innovación y estar a la vanguardia con la tecnología inmobiliaria global. Al transparentar el mercado, aspiramos a crear un entorno más justo, competitivo y próspero para todos los participantes del sector inmobiliario. Juntos, forjaremos un futuro donde las oportunidades sean accesibles, la información sea confiable y el mercado inmobiliario impulse el crecimiento de nuestro país.</p>
		</div>
			<div style={{lineHeight:'14pt'}}>
<h1><b>Valores</b></h1>
						<ul>
							<li><p><i>Innovación: </i>Impulsar la adopción de nuevas tecnologías y soluciones para mejorar la experiencia y eficiencia en el mercado inmobiliario.</p></li>
							<li><p><i>Confianza: </i>Establecer relaciones sólidas y basadas en la transparencia con los usuarios y socios del ecosistema inmobiliario.</p></li>
							<li><p><i>Empoderamiento: </i>Capacitar a los usuarios para tomar decisiones informadas y brindarles herramientas y recursos que los ayuden a lograr sus objetivos inmobiliarios.</p></li>
							<li><p><i>Centrados en el cliente: </i>Brindar una atención excepcional al cliente y buscar constantemente formas de mejorar su experiencia.</p></li>
							<li><p><i>Compromiso con el pais: </i>Contribuir al desarrollo del país en la que opera la plataforma, apoyando iniciativas sociales y locales.</p></li>
							<li><p><i>Integridad: </i>Actuar con honestidad, ética y transparencia en todas las interacciones.</p></li>
							<li><p><i>Colaboración: </i>Fomentar el trabajo en equipo, la comunicación abierta y la colaboración tanto internamente como con socios externos.</p></li>
							<li><p><i>Agilidad: </i>Adaptarse rápidamente a los cambios del mercado y aprender de los errores para mejorar continuamente.</p></li>
							<li><p><i>Disfrutar del proceso: </i>Creemos importante tener un balance en nuestra vidas para poder seguir creciendo y funcionando como equipo de alto rendimiento.</p></li>

						</ul>
												</div>

							<img src={FamiliaValores}></img>
		</div>
		</>: <div id="nosotros-cel">
			<h1><b>Misión</b></h1>
			<p>La misión de Merkasa es actuar como colaborador estratégico en el mercado inmobiliario, utilizando tecnología e innovación para hacer el proceso y todos los servicios relacionados más accesibles, transparentes y eficientes, creando sinergias y ofreciendo una experiencia completa a todos los participantes."</p>
						<h1><b>Visión</b></h1>

			<p>Nuestra visión es proporcionar un acceso integral a la mayor fuente de información del mercado inmobiliario, creemos firmemente que aportaremos al desarrollo de nuestro país al aplicar innovación y estar a la vanguardia con la tecnología inmobiliaria global. Al transparentar el mercado, aspiramos a crear un entorno más justo, competitivo y próspero para todos los participantes del sector inmobiliario. Juntos, forjaremos un futuro donde las oportunidades sean accesibles, la información sea confiable y el mercado inmobiliario impulse el crecimiento de nuestro país.</p>

						<h1><b>Valores</b></h1>
						<ul>
							<li><p><i>Innovación: </i>Impulsar la adopción de nuevas tecnologías y soluciones para mejorar la experiencia y eficiencia en el mercado inmobiliario.</p></li>
							<li><p><i>Confianza: </i>Establecer relaciones sólidas y basadas en la transparencia con los usuarios y socios del ecosistema inmobiliario.</p></li>
							<li><p><i>Empoderamiento: </i>Capacitar a los usuarios para tomar decisiones informadas y brindarles herramientas y recursos que los ayuden a lograr sus objetivos inmobiliarios.</p></li>
							<li><p><i>Centrados en el cliente: </i>Brindar una atención excepcional al cliente y buscar constantemente formas de mejorar su experiencia.</p></li>
							<li><p><i>Compromiso con el pais: </i>Contribuir al desarrollo del país en la que opera la plataforma, apoyando iniciativas sociales y locales.</p></li>
							<li><p><i>Integridad: </i>Actuar con honestidad, ética y transparencia en todas las interacciones.</p></li>
							<li><p><i>Colaboración: </i>Fomentar el trabajo en equipo, la comunicación abierta y la colaboración tanto internamente como con socios externos.</p></li>
							<li><p><i>Agilidad: </i>Adaptarse rápidamente a los cambios del mercado y aprender de los errores para mejorar continuamente.</p></li>
							<li><p><i>Disfrutar del proceso: </i>Creemos importante tener un balance en nuestra vidas para poder seguir creciendo y funcionando como equipo de alto rendimiento.</p></li>

						</ul>
		</div>}

		</>
	)
}
export default Nosotros
