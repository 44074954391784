import React, { useState, useCallback, useEffect } from "react";
import ReactPlayer from 'react-player'
import IconEdificio from "../img/iconoEdificio.png";
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import LoadingSpinner from "./LoadingSpinner";
import axios from 'axios'; // npm i axios


const zoomOutProperties = {
	duration: 5000,
	transitionDuration: false,
	infinite: false,
	indicators: true,
	scale: 0.4,
	arrows: false
};

function CardAgentesDetalle({ Id, ListaImagenes, VideoUrl, Nombre, Visible }) {
	//Slideshow(ListaImagenes);
	//console.log(ListaImagenes);

	const [items, setItems] = useState();
	const [index, setIndex] = useState(0);

	async function fetchApi() {

		const pIdAgente = sessionStorage.getItem("idAgenteSeleccionado");

		let fd = new FormData()
		fd.append("idagente", pIdAgente)

		const response = await axios.post('https://merkasa.com.bo/api/ApiListarVideosAgentes.php', fd);

		setItems(response.data.items);
	}
	const navigate = useNavigate();

	const handleSelect = (idAgente, Nombre) => {

		sessionStorage.setItem("idAgenteSeleccionado", idAgente);
		sessionStorage.setItem("NombreAgenteSeleccionado", Nombre);


		navigate('/MiperfilAgente');

	}

	const handleSelectSlider = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	useEffect(() => {
		fetchApi();
	}, [])

	//setItems(jsonNew);

	//setItems();

	return (
		<>
			<Card key={Id} style={{ width: '75%', height: '95%' }}>
				<Carousel activeIndex={index} onSelect={handleSelectSlider} interval={null} indicators={true} id="carousel-perfil-agente">

					{!items ? <p style={{ fontSize: '8pt', height: '25rem', backgroundColor: 'grey', color: 'white' }}>Sin videos que mostrar</p> :

						items.map((item) => {
							return (
								<Carousel.Item style={{ heigth: '200px' }}>

									<ReactPlayer
										url={item.urlVideo}
										className='react-player'
										playing={false}
										width='100%'
										height='100%'
										controls={false}
									/>
								</Carousel.Item>)
						})

					}

				</Carousel>
			</Card>


		</>



	);
}

export default CardAgentesDetalle;
