import React from 'react';
import {useState,useEffect} from 'react';
import { useLocalStorage } from '../useLocalStorage';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
export default function ModalRegistroAgente(){
	const [items, setItems] = useState();
	const [show,setShow] = useState(false);
	const [perfil, setPerfil] = useState('');
	const [numerodocumento, setnumerodocumento] = useState('');
	const [nombre, setNombre] = useState('');
	const [apellido, setApellido] = useState('');
	const [celular, setCelular] = useState('');
	const [correo, setCorreo] = useState('');
	const [contraseña, setContraseña] = useState('');
	const [perfilSeleccionadoRegistro, setperfilSeleccionadoRegistro] = useLocalStorage('perfilSeleccionadoRegistro','')
	const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
	const [tipodocumento, settipodocumento] = useState('1');
	const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
	const navigate = useNavigate();
	const [ischecked, setChecked] = useState(false);

	const handleClose = () => {
		setShow((prev) => !prev);
	};

	const handleClick = () => {

		
		const UsuActivo = sessionStorage.getItem("UsuarioActivo");
		if(UsuActivo !== null)
			{
			const tipocliente = JSON.parse(sessionStorage.getItem("selecttipousuario"));
			if(tipocliente == "1")
				{
				setTabIndex(4); //lo envia a Mi cuenta
				navigate('/DetalleUsuario');
				}else{
				setShow(true);
					}
			}else{
				setShow(true);
				}


		}; 

	function soloNumeros(number)
	{

		let newValor = number.replace(/[^0-9]|^E/i, "");

		return newValor;
	}

	function formatCadena(cadena)
	{
		let newValor = cadena.replace(/[^A-Za-z ñ]/,"");

		return newValor;
	}
const handleSubmit = (e) => {

		e.preventDefault();
		setperfilSeleccionadoRegistro(2);
		RegistroAcceso(numerodocumento,nombre,apellido,celular,correo, contraseña);

	}; 

	const RegistroAcceso = async (numerodocumento,nombre,apellido,celular,correo, contraseña) => {

		const iperfil = 1;

		if(iperfil == 0)
		{
			alert("Debe seleccionar un perfil"); 
			return;
		}
		if (!ischecked)
			{
			alert("Debe aceptar los términos y condiciones para continuar");
			return;
			}
		const data = JSON.stringify({
			perfil : iperfil,numerodocumento: numerodocumento,nombre : nombre,apellido : apellido,celular : celular,correo:correo, contrasenia:contraseña,tipodocumento:tipodocumento,tipousuario:2,
		})

		await fetch('https://merkasa.com.bo/api/ApiRegistro.php', {
			method: 'POST',
			body: data,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				//setPosts((posts) => [data, ...posts]);

				console.log(data);
				const respuesta = data.usuarioid;

				if(respuesta === 0)
				{
					alert("El correo ingresado ya cuenta con un usuario, puede recuperar su contraseña desde la opción 'Recuperar Contraseña'");  
					return; 
				}else{
					console.log(respuesta);
					localStorage.setItem("UsuarioActivo",JSON.stringify(correo));
					setIdusuario(parseInt(respuesta));
					navigate('/AgentePromocionate');
					window.location.reload(true);
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
		};

	const fetchApi = async () => {
		const response2 = await fetch('https://merkasa.com.bo/api/ApiListarTipoUsuario.php')
		const responseJSON2 = await response2.json()
		setItems(responseJSON2.items)  
	}
	useEffect(() => {
		fetchApi(); 
		window.scrollTo(0, 0);
	},[])
const handleCheckboxChange = (e) => {
		setChecked(e.target.checked);	
		}

	return(
		<>
	<button type='button' className='btnPromocionate' onClick={handleClick}><b>Promociónate AQUÍ</b></button> <></>
	<Modal centered={true} show={show} onHide={handleClose}>
		<Modal.Header closeButton>
							<Container>
								<Row>
									<Col sm={12}>
										<center><h1>Bienvenido</h1></center>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<center><h6>Registrate para publicar</h6></center> 
									</Col>
								</Row>
							</Container>
						</Modal.Header>
						<Modal.Body>
							<Form onSubmit={handleSubmit}>

								<Container style={{paddingBottom:'20px',paddingTop:'20px'}}>
									<Row>
										<Col sm={1}>
										</Col>
										<Col sm={5}>
											<Form.Group className="sm-3" controlId="exampleForm.ControlInput0">
												<Form.Label style={{fontSize:'10pt',color:'black'}}>Perfil</Form.Label>
												<Form.Select 

													value='1'
													onChange={(e) => setPerfil(e.target.value)}
													disabled
													required
													style={{fontSize:'7pt',color:'black'}}
												>
													{ !items ? 'Cargando....' : items.map( (item,index) => {
														return <option value={item.codtipocliente}>{item.descripcion}</option>
															})
														}
													</Form.Select>
												</Form.Group>
											</Col>
											<Col sm={5}>
											</Col>
											<Col sm={1}>
											</Col>
										</Row>
										<Row>
											<Col sm={1}>
											</Col>
											<Col sm={5}>
												<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
													<Form.Label style={{fontSize:'10pt',color:'black'}}>Nombre</Form.Label>
													<Form.Control
														type="text"
														value={nombre}
														onChange={(e) => setNombre(formatCadena(e.target.value))}
														minLength="4"
														required
														style={{fontSize:'9pt',color:'black'}}
													/>
												</Form.Group>
											</Col>
											<Col sm={5}>
												<Form.Group className="sm-3" controlId="exampleForm.ControlInput3">
													<Form.Label style={{fontSize:'10pt',color:'black'}}>Apellido</Form.Label>
													<Form.Control
														type="text"
														value={apellido}
														minLength="4"
														onChange={(e) => setApellido(formatCadena(e.target.value))}
						required
						style={{fontSize:'9pt',color:'black'}}
						/>
					</Form.Group>
				</Col>
				<Col sm={1}>
				</Col>
			</Row>
			<Row>
				<Col sm={1}>
				</Col>
				<Col sm={5}>
					<Form.Group className="sm-3" controlId="exampleForm.ControlInput4">
						<Form.Label style={{fontSize:'10pt',color:'black'}}>Celular</Form.Label>
						<Form.Control
							type="text"
							minLength="8"
							value={celular}
							onChange={(e) => 
							e.target.value.length<=8 ?
						setCelular(soloNumeros(e.target.value)):null}
						required
						style={{fontSize:'9pt',color:'black'}}
					/>
				</Form.Group>
			</Col>
			<Col sm={5}>
				<Form.Group className="sm-3" controlId="exampleForm.ControlInput5">
					<Form.Label style={{fontSize:'10pt',color:'black'}}>Email</Form.Label>
					<Form.Control
						type="email"
						placeholder="nombre@dominio.com"
						value={correo}
						onChange={(e) => setCorreo(e.target.value)}
						required
						style={{fontSize:'9pt',color:'black'}}
					/>
				</Form.Group>
			</Col>
			<Col sm={1}>
			</Col>
		</Row>
		<Row>
			<Col sm={1}>
			</Col>
			<Col sm={10}>
				<Form.Group className="sm-3" controlId="exampleForm.ControlInput6">
					<Form.Label style={{fontSize:'10pt',color:'black'}}>Contraseña</Form.Label>
					<Form.Control
						type="password"
						value={contraseña}
						minLength="8"
						onChange={(e) => setContraseña(e.target.value)}
						required
						style={{fontSize:'9pt',color:'black'}}
					/>
				</Form.Group>
			</Col>
			<Col sm={1}>
			</Col>
		</Row>
	</Container>        
	<Container>
		<Row>
			<Col sm={12}>
				<div style={{display:'flex',justifyContent:'center',gap:'1%'}}>
					<input
						type="checkbox"
						checked={ischecked}
						onChange={handleCheckboxChange}
					/>
					<Link to='/TerminosCondiciones' target='_blank'><label style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue',fontSize:'8pt' }}>
							He leído y acepto los términos y condiciones y políticas de privacidad.
					</label></Link>
				</div>
			</Col>
		</Row>
		<br></br>
		<Row>
			<Col sm={12}>
				<center>

					<Button variant="primary4" type='submit'>
						Aceptar
					</Button>
				</center> 
			</Col>
		</Row>
	</Container>


</Form>
</Modal.Body>
	</Modal>
	</>
	)
}
