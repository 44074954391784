import React from "react"
import Header2 from "./Header2"
import Footer from "./Footer"
class Layout3 extends React.Component {
  render(){
    return (
      <>
        <Header2/>
        <main>{this.props.children}</main>
      </>
    )
  }
}
export default Layout3;