import Modal from "react-bootstrap/Modal";
import imgLogo from "../img/logo_new.svg";
function MensajeModal({Mensaje,Mostrar,setClose}) {
	return(
	<>
		<Modal
			centered={true}
			className="modal-box"
			show={Mostrar}
			onHide={setClose}
			animation={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<img
						src={imgLogo}
						className="logoMerkasa-modal"
						alt="Merkasa"
					></img>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="modal-text">{Mensaje}</p>
			</Modal.Body>
		</Modal>

	</>

	)
}
export default MensajeModal
