import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import axios from "axios"; // npm i axios
import { BiFilterAlt } from "react-icons/bi"; // npm i react-icons
import CardAnunciosView from "./CardAnunciosView";
import LoadingSpinner from "./LoadingSpinner";
import imgBtnLupa from "../img/Lupa.png";
import { useLocalStorage } from "../useLocalStorage";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import Pagination from "./Pagination";
import Modal from "react-bootstrap/Modal";
import Header2 from "./Header2";

var map;
const API_KEY = "AIzaSyDF09ajXzzNFYGK6tPuWX6yCBzK0DUeSmM";
function ListaMapaAnuncios() {
	const [isLoading, setIsLoading] = useState(false);
	const [y, setY] = useState(0);
	const [listaAnuncios, setListaAnuncios] = useState([]);
	const [maestrosTipoInmueble, setMaestrosTipoInmueble] = useState();
	const [tipoinmueble, settipoinmueble] = useState(null);
	const [dormitorios, setdormitorios] = useState(null);
	const [banios, setbanios] = useState(null);
	const [address, setAddress] = useLocalStorage("addressh", "");
	const [coordinates, setCoordinates] = useState({
		lat: null,
		lng: null,
		});
	const [maestrosTipoOperacion, setMaestrosTipoOperacion] = useState();
	const [operacion, setOperacion] = useState(null);

	const url = "https://merkasa.com.bo/api/ApiListarMenu.php";
	const [items, setItems] = useState();
	const [countlista, setCountlista] = useState(0);
	const [vermas, setVermas] = useLocalStorage("vermas", 0);
	const [bVisibleRange, setVisibleRange] = useLocalStorage(
		"bVisibleRange",
		false
		);
	const [precioFiltro, setprecio] = useState("0 - 2,000000");
	//const [ value1, setValue1 ] = useLocalStorage('value1',0);
	//const [ value2, setValue2 ] = useLocalStorage('value2',2000000);
	const [value1, setValue1] = useState(0);
	const [value2, setValue2] = useState(2000000);
	const [valorcadena, setValorCadena] = useState("0 - 2,000000");
	const [sumaAnuncios, setSumaAnuncios] = useState(0);
	const [caractetisticas, setcaractetisticas] = useLocalStorage(
		"caractetisticas",
		""
		);
	const [vcaracteristica, setvcaracteristica] = useState([]);
	const [cadenacaracteristicas, setcadenacaracteristicas] = useState("");
	const [bActivaLista, setActivaLista] = useState(false);
	const [fpublicacion, setfPublicacion] = useState(0);
	const [vOrden, setVOrden] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [showModalMapa, setShowModalMapa] = useState(false);
	const [listaFavoritos, setListaFavoritos] = useState([]);

	function renderMap() {
		var zoom;
		const CoordinatesLat = sessionStorage.getItem("CoordinatesLat");
		const CoordinatesLng = sessionStorage.getItem("CoordinatesLng");

		let vCoordinatesLat = "";
		let vCoordinatesLng = "";

		if (CoordinatesLat === null) {
			zoom = 4;
			} else if (
				sessionStorage.getItem("zoom") != null ||
				sessionStorage.getItem("zoom") != undefined
				) {
				zoom = parseInt(sessionStorage.getItem("zoom"));
				} else {
					zoom = 14;
					}

		if (CoordinatesLat != "" || CoordinatesLat != null) {
			vCoordinatesLat = JSON.parse(CoordinatesLat);
			} else {
				vCoordinatesLat = -16.489689;
				}

		if (CoordinatesLng != "" || CoordinatesLng != null) {
			vCoordinatesLng = JSON.parse(CoordinatesLng);
			} else {
				vCoordinatesLng = -68.11929359999999;
				}

		const coords = {
			lat: vCoordinatesLng == null ? -16.489689 : vCoordinatesLat,
			lng: vCoordinatesLng == null ? -68.11929359999999 : vCoordinatesLng,
			};
		let el =
			window.innerWidth > 1024
		? document.getElementById("map")
		: document.getElementById("mapResponsive");
		if (el) {
			map = new window.google.maps.Map(el, {
				zoom: zoom,
				center: {
					lat: coords.lat,
					lng: coords.lng,
					},
				});
			window.google.maps.event.addListener(
				map,
				"bounds_changed",
				handleBoundsChangedDebounced
				);
			return map;
			} else {
				return null;
				}
		}
	function debounce(func, wait) {
		let timeout;
		return function () {
			const context = this;
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				func.apply(ListaMapaAnuncios, [
					0,
					tipoinmueble,
					dormitorios,
					banios,
					value1,
					value2,
					fpublicacion,
					]);
				}, wait);
			};
		}
	let handleBoundsChangedDebounced = debounce(() => {
		runEffect(
			0,
			tipoin.current,
			dormi.current,
			bani.current,
			valueA.current,
			valueB.current,
			fpubli.current
			);
	}, 300);

	const handleRedirect = (
		idAnuncio,
		Latitud,
		Longitud,
		Descripcion,
		Precio,
		Terreno,
		Construccion,
		Dormitorios,
		Banios,
		Parqueos,
		Title,
		Transaccion,
		ListaImagenes,
		Titulo
		) => {
			sessionStorage.setItem(
				"CodAnuncioActualSeleccion",
				JSON.stringify(idAnuncio)
				);
			sessionStorage.setItem("LatitudActualSeleccion", JSON.stringify(Latitud));
			sessionStorage.setItem("LongitudActualSeleccion", JSON.stringify(Longitud));
			sessionStorage.setItem(
				"DescripcionActualSeleccion",
				JSON.stringify(Descripcion)
				);
			sessionStorage.setItem("PrecioActualSeleccion", JSON.stringify(Precio));
			sessionStorage.setItem("TerrenoActualSeleccion", JSON.stringify(Terreno));
			sessionStorage.setItem(
				"ConstruccionActualSeleccion",
				JSON.stringify(Construccion)
				);
			sessionStorage.setItem(
				"DormitoriosActualSeleccion",
				JSON.stringify(Dormitorios)
				);
			sessionStorage.setItem("BaniosActualSeleccion", JSON.stringify(Banios));
			sessionStorage.setItem("ParqueosActualSeleccion", JSON.stringify(Parqueos));
			sessionStorage.setItem("DireccionActualSeleccion", JSON.stringify(Title));
			sessionStorage.setItem(
				"TransaccionActualSeleccion",
				JSON.stringify(Transaccion)
				);
			sessionStorage.setItem("TituloActualSeleccion", JSON.stringify(Titulo));
			const jsonNew = JSON.parse(ListaImagenes);
			sessionStorage.setItem(
				"ImagenActualSeleccion",
				JSON.stringify(jsonNew.items[0].imagen)
				);

			window.open("DetalleAnuncio/" + btoa(idAnuncio));
			};

	const searchOptions = {
		componentRestrictions: { country: ["bo"] },
		};

	const handleSelect = async (value) => {
		setAddress(value);
		};

	const handleClick2 = async () => {
		if (address === "") {
			setCoordinates({ lat: null, lng: null });
			} else {
				const results = await geocodeByAddress(address);

				const ll = await getLatLng(results[0]);

				setCoordinates(ll);
				sessionStorage.setItem("CoordinatesLat", JSON.stringify(ll.lat));
				sessionStorage.setItem("CoordinatesLng", JSON.stringify(ll.lng));
				const lugar = results[0].types[0];
				console.log("lugar", lugar);
				if (lugar === "route") {
					sessionStorage.setItem("zoom", 16);
					}
					if (lugar === "administrative_area_level_1") {
						sessionStorage.setItem("zoom", 8);
						}
					if (lugar === "locality") {
						sessionStorage.setItem("zoom", 10);
						}
				}
		};

	function CargarMapa() {
		var scripts = document.getElementsByTagName("script");

		for (let index = 0; index < scripts.length; index++) {
			var lastScript = scripts[index];
			var scriptName = lastScript.src;

			if (
				scriptName.indexOf(
					"https://maps.googleapis.com/maps/api/js?key=AIzaSyDF09ajXzzNFYGK6tPuWX6yCBzK0DUeSmM&libraries=places"
					) === 0
				) {
				lastScript.parentNode.removeChild(lastScript);
				}
			}
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src =
			`https://maps.googleapis.com/maps/api/js?key=` +
			API_KEY +
			`&libraries=geometry,places`;
		script.id = "googleMaps";
		script.async = true;
		script.defer = true;
		document.head.appendChild(script);
		script.addEventListener("load", (e) => {
			renderMap();
			});
		}

	const handlePageChange = (page) => {
		setCurrentPage(page);
		limpiarMarcadores();
		moverArriba();
		};

	let runEffect = async (
		top,
		tipoinmueble,
		dormitorios,
		banios,
		value1,
		value2,
		fpublicacion
		) => {
			// Paginar las tarjetas
			value1 = value1.toString().replace(/,/g, "");
			value2 = value2.toString().replace(/,/g, "");
			const selectTransaccion = sessionStorage.getItem("selectOperacion");
			setOperacion(selectTransaccion);
			//setIsLoading(true);
			let fd = new FormData();

			fd.append("top", top);
			fd.append("tipoinmueble", tipoinmueble);
			fd.append("dormitorios", dormitorios);
			fd.append("banios", banios);
			fd.append("transaccion", selectTransaccion);
			fd.append("vdesdeprecio", parseInt(value1));
			fd.append("vhastaprecio", parseInt(value2));
			fd.append("fechacreacion", fpublicacion);
			fd.append("vdestacado", 0);

			const response2 = await fetch(url);
			const responseJSON = await response2.json();
			setItems(responseJSON.items);

			let response = await axios.post(
				"https://merkasa.com.bo/api/ApiListarAnunciosAllvf.php",
				fd
				);
			if (response.data == 0) {
				setListaAnuncios([]);
				setSumaAnuncios(0);
				limpiarMarcadores();
				return;
				}
console.log("Respuesta",response.data.items);
			//Capturamos las caracteristicas adicionales
			let anunciosCad = caracteristicasAdicionales(response.data.items);
			//Eliminamos duplicados
			response = eliminarDuplicados(response, "idAnuncio");
			//Filtramos anunciosCad segun las caracteristicas adicionales seleccionadas;
			let anunciosCaracteristicasFiltradas = [];
			if (cadenaCad.current !== "") {
				let listacadenacaracteristicas = cadenaCad.current.split(",");
				//Recorre los anuncios
				for (let i = 0; i < anunciosCad.length; i++) {
					let caracteristicas = anunciosCad[i].caracteristicas;
					//Recorre las caracteristicas
					for (let j = 0; j < listacadenacaracteristicas.length; j++) {
						let caracteristica = parseInt(listacadenacaracteristicas[j]);
						if (!caracteristicas.includes(caracteristica)) {
							break;
							}
						if (j === listacadenacaracteristicas.length - 1) {
							anunciosCaracteristicasFiltradas.push(anunciosCad[i].id);
							}
						}
					}

				// console.log("Anuncios con caracteristicas filtradas: ",anunciosCaracteristicasFiltradas);
				let listaTemporal = [];
				anunciosCaracteristicasFiltradas.map((anunciof) => {
					response.map((anuncio) => {
						if (anuncio.idAnuncio === anunciof) listaTemporal.push(anuncio);
						});
					});
				response = [...listaTemporal];
				}

			//Filtramos según el mapa

			onZoomChanged(response, async (result) => {
				let listaTemporal = [];
				let responseAnuncio = result;
				setSumaAnuncios(responseAnuncio.length);
				responseAnuncio.map((card) => {
					let DistanciaKmFinal = 0;
					// console.log("Lista: ",parseInt(card.Precio.toString().replace(/,/g, '')));

					listaTemporal.push({
						Direccion: card.Direccion,
						Precio: card.Precio,
						Imagenes: card.Imagenes,
						Destacado: card.Destacado,
						CodigoAnuncio: card.idAnuncio,
						Distancia: DistanciaKmFinal,
						Latitud: card.Latitud,
						Longitud: card.Longitud,
						Mapa: map,
						Descripcion: card.Descripcion,
						Terreno: card.terreno,
						Construccion: card.construccion,
						Dormitorios: card.dormitorios,
						Banios: card.banios,
						Parqueos: card.parqueos,
						Transaccion: card.Transaccion,
						Titulo: card.Titulo,
						Nombre: card.Nombre,
						ApellidoPaterno: card.ApellidoPaterno,
						celular: card.celular,
						PrecioNumericDesde: card.PrecioNumericDesde,
						FecUsuCreacion: card.FecUsuCreacion,
						AnioContruccion: card.anioconstruccion,
						TipoInmueble: card.TipoInmueble,
						EstadoProyecto: card.EstadoProyecto,
						});
					});
				setListaAnuncios([...ordenarLista(listaTemporal)]);
				});
			limpiarMarcadores();
			moverArriba();
			};

	var mostrarAnuncios = useRef(1);
	let marcadores = useRef([]);

	function limpiarMarcadores() {
		for (let i = 0; i < marcadores.current.length; i++) {
			marcadores.current[i].setMap(null);
			}
		}

	function caracteristicasAdicionales(anuncios) {
		let listaC = [];
		anuncios.map((anuncio) => {
			if (
				listaC.findIndex((anuncioc) => anuncioc.id === anuncio.idAnuncio) !== -1
				) {
				let posicion = listaC.findIndex(
					(anuncioc) => anuncioc.id === anuncio.idAnuncio
					);
				listaC[posicion].caracteristicas.push(anuncio.caracteristica);
				} else {
					listaC.push({
						id: anuncio.idAnuncio,
						caracteristicas: [anuncio.caracteristica],
						});
					}
			});
		return listaC;
		}
	function eliminarDuplicados(lista, clave) {
		const conjuntoUnico = new Set();
		return lista.data.items.filter((objeto) => {
			const valor = objeto[clave];
			if (!conjuntoUnico.has(valor)) {
				conjuntoUnico.add(valor);
				return true;
				}
			return false;
			});
		}

	function onZoomChanged(response, callback) {
		if (window.innerWidth < 1024) {
			const geocoder = new window.google.maps.Geocoder();
			geocoder.geocode({ address: address }, (results, status) => {
				if (status === "OK" && results[0]) {
					const bounds = results[0].geometry.bounds;
					if (bounds !== undefined) {
						const newResponse = response.filter((marker) =>
							bounds.contains(
								new window.google.maps.LatLng(marker.Latitud, marker.Longitud)
								)
							);
						callback(newResponse);
						} else {
							callback(response);
							}
					} else {
						callback(response);
						}
				});
			} else {
				const bounds = map.getBounds();
				const newResponse = response.filter((marker) =>
					bounds.contains(
						new window.google.maps.LatLng(marker.Latitud, marker.Longitud)
						)
					);
				callback(newResponse);
				}
		}

	const runEffect2 = async () => {
		const url2 = "https://merkasa.com.bo/api/ApiListarMaestroCbo.php";

		const data = JSON.stringify({
			tipo: 1,
			});

		const response3 = await fetch(url2, {
			method: "POST",
			body: data,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				},
			});

		const responseJSON2 = await response3.json();
		setMaestrosTipoInmueble(responseJSON2.items);
		};

	const runEffect3 = async (tipop) => {
		const url = "https://merkasa.com.bo/api/ApiListarMaestroCbo.php";
		const data = JSON.stringify({
			tipo: tipop,
			});

		const response = await fetch(url, {
			method: "POST",
			body: data,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				},
			});
		const responseJSON = await response.json();

		if (tipop == 2) {
			setMaestrosTipoOperacion(responseJSON.items);
			}
		};
	function formatoPrecio(precio) {
		let precioS = precio.replace(/,/g, "");
		if (precioS.indexOf("-") === -1) {
			return precioS;
			} else {
				return precioS.substring(0, precioS.indexOf("-"));
				}
		}
	function ordenarLista(lista) {
		let orden = parseInt(Orden.current);
		switch (orden) {
				case 1: //Antiguo
					lista.sort((a, b) => a.CodigoAnuncio - b.CodigoAnuncio);
				break;

				case 2: //Nuevo
					lista.sort((a, b) => b.CodigoAnuncio - a.CodigoAnuncio);
				break;
				case 3: //Mayor Precio
					lista.sort((a, b) => {
						let precio1 = parseInt(formatoPrecio(a.Precio.toString()));
						let precio2 = parseInt(formatoPrecio(b.Precio.toString()));
						return precio2 - precio1;
						});
				break;
				case 4: //Menor Precio
					lista.sort((a, b) => {
						let precio1 = parseInt(formatoPrecio(a.Precio.toString()));
						let precio2 = parseInt(formatoPrecio(b.Precio.toString()));
						return precio1 - precio2;
						});
				break;
				default:
					}
		return lista;
		}
	function moverArriba() {
		const listaDeAnuncios = document.getElementsByClassName("slider-busqueda");
		listaDeAnuncios[0].scrollTop = 0;
		}
	let dormi = useRef(null);
	let bani = useRef(null);
	let tipoin = useRef(null);
	let valueA = useRef(0);
	let valueB = useRef(2000000);
	let fpubli = useRef("");
	let cadenaCad = useRef("");
	let Orden = useRef(1);

	useEffect(() => {
		dormi.current = dormitorios;
		bani.current = banios;
		tipoin.current = tipoinmueble;
		valueA.current = value1;
		valueB.current = value2;
		fpubli.current = fpublicacion;
		cadenaCad.current = cadenacaracteristicas;
		Orden.current = vOrden;
		}, [
			tipoinmueble,
			dormitorios,
			banios,
			fpublicacion,
			cadenacaracteristicas,
			vOrden,
			]);

	async function fetchApi() {
		if (localStorage.length > 1) {
			let idusuario = localStorage.getItem("idusuario");
			let f = new FormData();
			f.append("idusuario", idusuario);
			const resf = await axios.post(
				"https://merkasa.com.bo/api/ApiListarAnunciosUsuarioFavoritos.php",
				f
				);
			setListaFavoritos(resf.data.items);
			}
		}
	useEffect(() => {
		fetchApi();
		window.scrollTo(0, 0);
		}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (window.innerWidth > 1024) {
			CargarMapa();
			} else {
				runEffect(
					0,
					tipoinmueble,
					dormitorios,
					banios,
					value1,
					value2,
					fpublicacion
					);
				}
		}, []);

	useEffect(() => {
		getCaracteristicas();
		runEffect2();
		runEffect3(2);
		}, []);

	useEffect(() => {
		if (window.innerWidth > 1024) {
			document.getElementById("map").innerHTML = "";
			} else {
				try {
					document.getElementById("mapResponsive").innerHTML = "";
					} catch (err) {
						console.log(err);
						}
				}
		renderMap();
		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}, [coordinates]);

	useEffect(() => {
		if (tipoinmueble == null) {
			return;
			}
		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}, [tipoinmueble]);

	useEffect(() => {
		if (dormitorios == null) {
			return;
			}

		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}, [dormitorios]);

	useEffect(() => {
		if (banios == null) {
			return;
			}

		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}, [banios]);

	useEffect(() => {
		if (vcaracteristica.length == 0) {
			return;
			}
		convertirArregloaCadena(vcaracteristica);
		}, [vcaracteristica]);

	// ordenarLista('listaAnunciosf',tipo);

	useEffect(() => {
		if (vOrden === 0) {
			return;
			}

		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}, [vOrden]);

	useEffect(() => {
		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}, [cadenacaracteristicas]);

	useEffect(() => {
		if (fpublicacion == "") {
			return;
			}

		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}, [fpublicacion]);

	const handleFecha = (e) => {
		setfPublicacion(e.target.value);
		};

	const handleDormitorios = (e) => {
		setdormitorios(e.target.value);
		};

	const handleBanios = (e) => {
		setbanios(e.target.value);
		};

	const handletipoinmueble = (e) => {
		settipoinmueble(e.target.value);
		};

	const handlePrecio = (e) => {
		setprecio(e.target.value);
		};

	const handleOrden = (tipo) => {
		setVOrden(tipo);
		};

	const getCaracteristicas = async () => {
		const url2 = "https://merkasa.com.bo/api/ApiListarMaestroCbo.php";

		const data = JSON.stringify({
			tipo: 3,
			});

		const response = await fetch(url2, {
			method: "POST",
			body: data,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				},
			});
		const responseJSON = await response.json();
		setcaractetisticas(responseJSON.items);
		};

	const handleChange = () => {
		document.getElementById("txtBusqueda").value = "";
		setAddress("");
		};

	function formatNumber(number) {
		let newValor = number.toString().replace(/,/g, "");

		return new Intl.NumberFormat("EN-US").format(newValor);
		}

	const handleOperacion = (e) => {
		sessionStorage.setItem("selectOperacion", e.target.value);
		setOperacion(e.target.value);
		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		};
	function soloNumeros(number) {
		//let newValor = number.replace(/[^0-9]|^E/i, "");
		let newValor = number.replace(/\D/g, "");

		return newValor;
		}

	let handleBuscarPrecio = () => {
		//Pasar values sin formato
		let sfvalue1 = parseInt(value1.toString().replace(/,/g, ""));
		let sfvalue2 = parseInt(value2.toString().replace(/,/g, ""));
		//.............................................................

		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			sfvalue1,
			sfvalue2,
			fpublicacion
			);
		};

	const inputref = useRef(null);
	const cursorPosition = useRef(0);
	const handleChangePrecio1 = (e) => {
		const part = e.target.value.split("-");
		const input = document.getElementById("precio");
		cursorPosition.current = input.selectionStart;
		if (part.length == 2) {
			const valor1 = formatNumber(soloNumeros(part[0]));
			const valor2 = formatNumber(soloNumeros(part[1]));

			setValue1(valor1);
			setValue2(valor2);
			setValorCadena(` ${valor1}-${valor2}`);
			} else {
				return;
				}
		};
	/* const debouncedHandleBuscarPrecio = debounce(() => { */
	/*     contador+=1; */
	/*     handleBuscarPrecio(contador); */
	/* },2000) */
	/*  */
	useEffect(() => {
		if (inputref.current !== null) {
			inputref.current.setSelectionRange(
				cursorPosition.current,
				cursorPosition.current
				);
			} /* debouncedHandleBuscarPrecio();  */
		}, [valorcadena]);
	useEffect(() => {
		//Si la página actual es mayor a las páginas totales ir a la pagina 1
		const anunciosPorPágina = Math.ceil(sumaAnuncios / 24);
		if (anunciosPorPágina < currentPage) {
			setCurrentPage(1);
			}
		}, [sumaAnuncios]);

	const handleRange = (e) => {
		if (bVisibleRange) {
			setVisibleRange(false);
			} else {
				setVisibleRange(true);
				}
		};

	function mostrarcaracteristicas() {
		var x = document.getElementById("caracteristicas");

		if (x.className === "popupcarac") {
			x.className += " activo";
			} else {
				x.className = "popupcarac";
				}
		}
	function mostrarcaracteristicas2() {
		var x = document.getElementById("caracteristicas2");

		if (x.className === "popupcarac") {
			x.className += " activo";
			} else {
				x.className = "popupcarac";
				}
		}
	function btncaracteristicas(index, valor) {
		var x = document.getElementById("btncaract_" + index);

		if (x.className === "btncaracteristicas") {
			x.className += " activo";
			setvcaracteristica((vcaracteristica) => [
				...vcaracteristica,
				{ id: valor },
				]);
			} else {
				x.className = "btncaracteristicas";
				setvcaracteristica(
					vcaracteristica.filter((item) => parseInt(item.id) !== parseInt(valor))
					);
				}

		const elementos = document.getElementsByClassName(
			"btncaracteristicas activo"
			);

		if (elementos.length === 0) {
			setcadenacaracteristicas("");
			}
		}

	function btncaracteristicas2(index, valor) {
		var x = document.getElementById("btncaract__" + index);

		if (x.className === "btncaracteristicas2") {
			x.className += " activo";
			setvcaracteristica((vcaracteristica) => [
				...vcaracteristica,
				{ id: valor },
				]);
			} else {
				x.className = "btncaracteristicas2";
				setvcaracteristica(
					vcaracteristica.filter((item) => parseInt(item.id) !== parseInt(valor))
					);
				}

		const elementos = document.getElementsByClassName(
			"btncaracteristicas2 activo"
			);

		if (elementos.length === 0) {
			setcadenacaracteristicas("");
			}
		}
	function convertirArregloaCadena(object) {
		var cadenaConComas = object
		.map(function (valor, index) {
			if (index == 0) {
				return valor.id; // Mantén el primer valor sin comas
				} else {
					return "," + valor.id; // Agrega una coma antes de los valores restantes
					}
			})
		.join("");
		setcadenacaracteristicas(cadenaConComas);
		}
	function handleClose() {
		setShowModal(false);
		}
	function handleCloseMapa() {
		setShowModalMapa(false);
		}
	function crearMapa() {
		setShowModalMapa(true);
		mostrarAnuncios.current = 0;
		CargarMapa();
		runEffect(
			0,
			tipoinmueble,
			dormitorios,
			banios,
			value1,
			value2,
			fpublicacion
			);
		}
	useEffect(() => {
		/* const botones = document.querySelectorAll(".botonMapaFiltros"); */
		/* if (botones.length > 0) { */
		/*     window.addEventListener("scroll", function () { */
		/*         const scrollY = window.scrollY; */
		/*         let contenedorBlanco = document.createElement("div"); */
		/*         if (scrollY > 200) { */
		/*             botones.forEach((boton) => { */
		/*                 boton.classList.add("sticky"); */
		/*             }); */
		/*             botones.item(1).classList.add("mover-left"); */
		/*             document.body.appendChild(contenedorBlanco); */
		/*             contenedorBlanco.classList.add("fondo-blanco"); */
		/*         } else { */
		/*             botones.forEach((boton) => { */
		/*                 boton.classList.remove("sticky"); */
		/*             }); */
		/*             botones.item(1).classList.remove("mover-left"); */
		/*             const contenedor = document.querySelectorAll(".fondo-blanco"); */
		/*             if (contenedor !== null && scrollY === 0) { */
		/*                 contenedor.forEach((element) => { */
		/*                     element.remove(); */
		/*                 }); */
		/*             } */
		/*         } */
		/*     }); */
		/* } */
		}, []);
	return (
		<>
			<Header2></Header2>
			<div>

				{/* small screen model plus button */}
				{window.screen.width < 1024 ? (
					<Container fluid style={{ padding: "0 0 0 0" }}>
						<div className="top_nav_header">
							{/* scrch section */}
							<div
								style={{
									display: "flex",
									gap: "0px",
									justifyContent: "center",
									position: "relative",
									top: '8px',
									marginBottom: "7px",
								}}
							>
								<Form.Select
									className="tipo-operacion"
									value={operacion}
									onChange={(e) => handleOperacion(e)}
								>
									{!maestrosTipoOperacion
									? "Cargando...."
										: maestrosTipoOperacion.map((item, index) => {
											return (
												<option key={index} value={item.Codigo}>
													{item.Descripcion}
												</option>
												);
											})}
										</Form.Select>
										<PlacesAutocomplete
											searchOptions={searchOptions}
											value={address}
											onChange={setAddress}
											onSelect={handleSelect}
										>
											{({
												getInputProps,
												suggestions,
												getSuggestionItemProps,
												loading,
												}) => (
													<>
														<input
															class="txtBusqueda"
															type="search"
															{...getInputProps({
																placeholder: "Busca un lugar ...",
																// className: 'location-search-input',
																// className:'location-search-input borderInputSearch',
																})}
															/>
															{/* <div>
					  {loading && <div>Buscando...</div>}
					  {suggestions.map((suggestion) => {
						const className = suggestion.active
						  ? "suggestion-item2"
						  : "suggestion-item2";
						  // inline style for demonstration purpose
						const style = suggestion.active
						  ? { backgroundColor: "orange", cursor: "pointer" }
						  : { backgroundColor: "#ffffff", cursor: "pointer" };
						return (
							<div
							{...getSuggestionItemProps(suggestion, {
							  className,
							  style,
							})}
						  >
							  <span>{suggestion.description}</span>
						  </div>
						);
					  })}
				  </div> */}
					  <div
						  className={`${suggestions.length ? "suggestion-container" : ""
							  }`}
						  >
							  {loading && <div>Buscando...</div>}
							  {suggestions.map((suggestion) => {
								  const style = suggestion.active
								  ? { backgroundColor: "orange", cursor: "pointer"}
								  : { backgroundColor: "#ffffff", cursor: "pointer"};
								  return (
									  <div
										  {...getSuggestionItemProps(suggestion, {
											  style,
										  })}
										  className="suggestion-box"
									  >
										  <span>{suggestion.description}</span>
									  </div>
									  );
								  })}
							  </div>
						  </>
					  )}
				  </PlacesAutocomplete>

				  <div class="contenedor-lupa">
					  <img
						  className="lupa"
						  onClick={(e) => handleClick2()}
						  src={imgBtnLupa}
						  alt="lupa"
					  ></img>
				  </div>
			  </div>

			  {/* small screen button */}
			  <div class="mapa-filtros">
				  <button
					  class="botonMapaFiltros-mapa botonMapaFiltros"
					  type="button"
					  onClick={crearMapa}
				  >
					  Ver Mapa
				  </button>
				  <button
					  class="botonMapaFiltros-filtros botonMapaFiltros"
					  type="button"
					  onClick={(e) => setShowModal(true)}
				  >
					  Filtrar
				  </button>
			  </div>
		  </div>

		  {/* small screen model */}
		  <Modal
			  style={{
				  width: "100vw",
				  overflowX: "hidden",
				  padding: "0 0 0 0",
			  }}
			  show={showModalMapa}
			  onHide={handleCloseMapa}
		  >
			  <Modal.Header closeButton>
				  <Modal.Title>
					  <center>Merkasa</center>
				  </Modal.Title>
			  </Modal.Header>
			  <Modal.Body className="flex align-content-center justify-content-center">
				  <div class="mapa-filtros-modal">
					  <button
						  class="botonMapaFiltros-lista botonMapaFiltros"
						  type="button"
						  onClick={handleCloseMapa}
						  style={{
							  display: "flex",
							  lineHeight: "10px !important",
							  justifyContent: "center",
							  alignItems: "center",
						  }}
					  >
						  Ver lista
					  </button>
					  <button
						  class="botonMapaFiltros-filtros botonMapaFiltros"
						  type="button"
						  id="dropdownMenuButton"
						  onClick={(e) => setShowModal(true)}
					  >
						  Filtrar
					  </button>
				  </div>
				  <div id="mapResponsive" />
			  </Modal.Body>
		  </Modal>

		  <Modal
			  style={{
				  width: "100%",
				  height: "100%",
				  overflowX: "hidden",
				  overflowY: "scroll",
				  position: "fixed",
			  }}
			  show={showModal}
			  onHide={handleClose}
		  >
			  <Modal.Header closeButton>
				  <Modal.Title>Filtros</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
				  <Container style={{ padding: "0 0 0 0" }}>
					  <Row>
						  <Col sm={2}>
							  <Form.Group
								  className="sm-3"
								  controlId="exampleForm.ControlInput2"
								  as={Row}
							  >
								  <Form.Label
									  style={{
										  float: "left",
										  fontSize: "8pt",
										  margin: "0 0 0 0",
										  color: "black",
									  }}
								  >
									  <b>Precio $</b>
								  </Form.Label>
								  <Col sm={12}>
									  <Form.Control
										  id="precio"
										  type="text"
										  ref={inputref}
										  value={valorcadena}
										  onChange={(e) => handleChangePrecio1(e)}
										  onKeyPress={(e) =>
										  e.key === "Enter" ? handleBuscarPrecio() : null
											  }
											  disabled={false}
											  style={{
												  height: "30px",
												  fontSize: "8pt",
												  color: "black",
												  cursor: "pointer",
												  //   padding: "0 0 0 0",
												  }}
											  />
										  </Col>
									  </Form.Group>
								  </Col>
								  <Col sm={2}>
									  <Form.Group
										  className="sm-3"
										  controlId="exampleForm.ControlInput0"
									  >
										  <Form.Label
											  style={{
												  float: "left",
												  fontSize: "8pt",
												  margin: "0 0 0 0",
												  color: "black",
											  }}
										  >
											  <b>Tipo</b>
										  </Form.Label>
										  <Form.Select
											  value={tipoinmueble}
											  onChange={(e) => handletipoinmueble(e)}
											  style={{
												  height: "30%",
												  fontSize: "8pt",
												  color: "black",
											  }}
										  >
											  {!maestrosTipoInmueble
											  ? "Cargando...."
												  : maestrosTipoInmueble.map((item, index) => {
													  return (
														  <option value={item.Codigo}>
															  {item.Descripcion}
														  </option>
														  );
													  })}
												  </Form.Select>
											  </Form.Group>
										  </Col>
										  <Col sm={2}>
											  <Form.Group
												  className="sm-3"
												  controlId="exampleForm.ControlInput2"
											  >
												  <Form.Label
													  style={{
														  float: "left",
														  fontSize: "8pt",
														  margin: "0 0 0 0",
														  color: "black",
													  }}
												  >
													  <b>Baños</b>
												  </Form.Label>
												  <Form.Select
													  value={banios}
													  onChange={(e) => handleBanios(e)}
													  style={{
														  height: "30%",
														  fontSize: "8pt",
														  color: "black",
													  }}
												  >
													  <option value={0}>Todos</option>
													  <option value={1}>1</option>
													  <option value={2}>2</option>
													  <option value={3}>3</option>
													  <option value={4}>4</option>
													  <option value={5}>5 o más</option>
												  </Form.Select>
											  </Form.Group>
										  </Col>
										  <Col sm={2}>
											  <Form.Group
												  className="sm-3"
												  controlId="exampleForm.ControlInput0"
											  >
												  <Form.Label
													  style={{
														  float: "left",
														  fontSize: "8pt",
														  margin: "0 0 0 0",
														  color: "black",
													  }}
												  >
													  <b>Dormitorios</b>
												  </Form.Label>
												  <Form.Select
													  value={dormitorios}
													  onChange={(e) => handleDormitorios(e)}
													  style={{
														  height: "30%",
														  fontSize: "8pt",
														  color: "black",
													  }}
												  >
													  <option value={0}>Todos</option>
													  <option value={1}>1</option>
													  <option value={2}>2</option>
													  <option value={3}>3</option>
													  <option value={4}>4</option>
													  <option value={5}>5 o más</option>
												  </Form.Select>
											  </Form.Group>
										  </Col>
										  <Col sm={2}>
											  <Form.Group
												  className="sm-3"
												  controlId="exampleForm.ControlInput0"
											  >
												  <Form.Label
													  style={{
														  float: "left",
														  fontSize: "8pt",
														  margin: "0 0 0 0",
														  color: "black",
													  }}
												  >
													  <b>Fecha de Publicación</b>
												  </Form.Label>
												  <Form.Select
													  value={fpublicacion}
													  onChange={(e) => handleFecha(e)}
													  style={{
														  height: "30%",
														  fontSize: "8pt",
														  color: "black",
													  }}
												  >
													  <option value={0}>Todos</option>
													  <option value={1}>Hoy</option>
													  <option value={7}>Última semana</option>
													  <option value={15}>Últimos 15 días</option>
													  <option value={30}>Últimos 30 días</option>
													  <option value={60}>Últimos 60 días</option>
													  <option value={90}>Últimos 90 días</option>
												  </Form.Select>
											  </Form.Group>
										  </Col>
									  </Row>
									  <div id="caracteristicas2" className="popupcarac2">
										  <ul
											  className="popupcarac_ul"
											  onMouseLeave={mostrarcaracteristicas}
										  >
											  <p
												  style={{
													  fontSize: "10pt",
													  paddingLeft: "10px",
													  paddingTop: "20px",
												  }}
											  >
												  <b>Características</b>
											  </p>
											  {!caractetisticas ? (
												  <>
													  <LoadingSpinner></LoadingSpinner>
												  </>
												  ) : (
													  caractetisticas.map((item, index) => {
														  return (
															  <>
																  <li>
																	  <img
																		  id={"btncaract__" + index}
																		  src={item.UrlImg}
																		  onClick={(e) =>
																		  btncaracteristicas2(index, item.Codigo)
																			  }
																			  className="btncaracteristicas2"
																			  alt="filtros"
																		  ></img>
																	  </li>
																  </>
																  );
																  })
															  )}
														  </ul>
													  </div>
												  </Container>
												  <div
													  className="text-center"
													  style={{ position: "relative", top: "5px" }}
												  >
													  <Button
														  className="btn btn-primary center"
														  variant="primary"
														  onClick={handleClose}
														  style={{
															  alignContent: "center",
															  justifyContent: "center",
															  lineHeight: "70%",
														  }}
													  >
														  Aceptar
													  </Button>
												  </div>
											  </Modal.Body>
										  </Modal>
									  </Container>
								  ) : null}

								  {/* Big screen top header section */}
								  {window.screen.width > 1024 ? (
									  <div className="big-top-header">
										  <PlacesAutocomplete
											  searchOptions={searchOptions}
											  value={address}
											  onChange={setAddress}
											  onSelect={handleSelect}
											  className="input-search"
										  >
											  {({
												  getInputProps,
												  suggestions,
												  getSuggestionItemProps,
												  loading,
												  }) => (
													  <div className="tipo-operacion-lupa">
														  <Form.Select
															  className="tipo-operacion"
															  value={operacion}
															  onChange={(e) => handleOperacion(e)}
														  >
															  {!maestrosTipoOperacion
															  ? "Cargando...."
																  : maestrosTipoOperacion.map((item, index) => {
																	  return (
																		  <option key={index} value={item.Codigo}>
																			  {item.Descripcion}
																		  </option>
																		  );
																	  })}
																  </Form.Select>
																  <input
																	  class="txtBusqueda"
																	  type="search"
																	  {...getInputProps({
																		  placeholder: "Busca un lugar ...",
																		  //className: 'location-search-input',
																		  // className:'location-search-input borderInputSearch',
																		  })}
																	  />
																	  <img
																		  className="lupa"
																		  onClick={(e) => handleClick2()}
																		  src={imgBtnLupa}
																		  alt="lupa"
																	  ></img>
																	  {/* <div className="bg-info w-0">
					{suggestions.map((suggestion) => {
					  const className = suggestion.active
						? "suggestion-item2"
						: "suggestion-item2";
						// inline style for demonstration purpose
					  const style = suggestion.active
						? { backgroundColor: "orange", cursor: "pointer" }
						: { backgroundColor: "#ffffff", cursor: "pointer" };
					  return (
						  <div
						  {...getSuggestionItemProps(suggestion, {
							className,
							style,
						  })}
						>
							<span>{suggestion.description}</span>
						</div>
					  );
					})}
				</div> */}
					<div
						className={`${suggestions.length ? "suggestion-container" : ""
							}`}
						>
							{suggestions.map((suggestion) => {
								const style = suggestion.active
								? { backgroundColor: "orange", cursor: "pointer" }
								: { backgroundColor: "#ffffff", cursor: "pointer" };
								return (
									<div
										{...getSuggestionItemProps(suggestion, {
											style,
										})}
										className="suggestion-box"
									>
										<span>{suggestion.description}</span>
									</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>

				<Container fluid style={{ padding: "0 0 0 0" }} id="filtros">
					<div style={{ width: "160px" }}>
						<Form.Label
							style={{
								float: "left",
								fontSize: "8pt",
								margin: "0 0 0 0",
								color: "black",
							}}
						>
							<b>Precio $</b>
						</Form.Label>
						<Form.Control
							id="precio"
							type="text"
							ref={inputref}
							value={valorcadena}
							onChange={(e) => handleChangePrecio1(e)}
							onKeyPress={(e) =>
							e.key === "Enter" ? handleBuscarPrecio() : null
								}
								disabled={false}
								style={{
									height: "30px",
									fontSize: "8pt",
									color: "black",
									cursor: "pointer",
								}}
							/>
						</div>
						<div style={{ width: "140px" }}>
							<Form.Label
								style={{
									float: "left",
									fontSize: "8pt",
									margin: "0 0 0 0",
									color: "black",
								}}
							>
								<b>Tipo</b>
							</Form.Label>
							<Form.Select
								value={tipoinmueble}
								onChange={(e) => handletipoinmueble(e)}
								style={{ height: "60%", fontSize: "8pt", color: "black" }}
							>
								{!maestrosTipoInmueble
								? "Cargando...."
									: maestrosTipoInmueble.map((item, index) => {
										return (
											<option value={item.Codigo}>
												{item.Descripcion}
											</option>
											);
										})}
									</Form.Select>
								</div>

								<div style={{ width: "90px" }}>
									<Form.Label
										style={{
											float: "left",
											fontSize: "8pt",
											margin: "0 0 0 0",
											color: "black",
										}}
									>
										<b>Dormitorios</b>
									</Form.Label>
									<Form.Select
										value={dormitorios}
										onChange={(e) => handleDormitorios(e)}
										style={{ height: "60%", fontSize: "8pt", color: "black" }}
									>
										<option value={0}>Todos</option>
										<option value={1}>1</option>
										<option value={2}>2</option>
										<option value={3}>3</option>
										<option value={4}>4</option>
										<option value={5}>5 o más</option>
									</Form.Select>
								</div>
								<div style={{ width: "90px" }}>
									<Form.Label
										style={{
											float: "left",
											fontSize: "8pt",
											margin: "0 0 0 0",
											color: "black",
										}}
									>
										<b>Baños</b>
									</Form.Label>
									<Form.Select
										value={banios}
										onChange={(e) => handleBanios(e)}
										style={{ height: "60%", fontSize: "8pt", color: "black" }}
									>
										<option value={0}>Todos</option>
										<option value={1}>1</option>
										<option value={2}>2</option>
										<option value={3}>3</option>
										<option value={4}>4</option>
										<option value={5}>5 o más</option>
									</Form.Select>
								</div>

								<div style={{ width: "153px" }}>
									<Form.Label
										style={{
											float: "left",
											fontSize: "8pt",
											color: "black",
											position: "relative",
											whiteSpace: "nowrap",
											top: "2px",
										}}
									>
										<b>Fecha de Publicación</b>
									</Form.Label>
									<Form.Select
										value={fpublicacion}
										onChange={(e) => handleFecha(e)}
										style={{
											height: "60%",
											fontSize: "8pt",
											color: "black",
											position: "relative",
											bottom: "5px",
										}}
									>
										<option value={0}>Todos</option>
										<option value={1}>Hoy</option>
										<option value={7}>Última semana</option>
										<option value={15}>Últimos 15 días</option>
										<option value={30}>Últimos 30 días</option>
										<option value={60}>Últimos 60 días</option>
										<option value={90}>Últimos 90 días</option>
									</Form.Select>
								</div>

								<i
									style={{ cursor: "pointer", paddingTop: "1.5vmin" }}
									onClick={mostrarcaracteristicas}
								>
									<BiFilterAlt style={{ height: "30px", width: "30px" }} />
								</i>
							</Container>
						</div>
					) : null}

					<div id="caracteristicas" className="popupcarac">
						<ul className="popupcarac_ul" onMouseLeave={mostrarcaracteristicas}>
							<p
								style={{
									fontSize: "10pt",
									paddingLeft: "10px",
									paddingTop: "20px",
								}}
							>
								<b>Características</b>
							</p>
							{!caractetisticas ? (
								<>
									<LoadingSpinner></LoadingSpinner>
								</>
								) : (
									caractetisticas.map((item, index) => {
										return (
											<>
												<li>
													<img
														id={"btncaract_" + index}
														src={item.UrlImg}
														onClick={(e) => btncaracteristicas(index, item.Codigo)}
														className="btncaracteristicas"
														alt="filtros"
													></img>
												</li>
											</>
											);
											})
										)}
									</ul>
								</div>

								<Container
									className="titulos-anuncios"
									style={{ maxWidth: "720px" }}
								>
									<Row id="titulos-anuncios-row">
										<Col style={{ padding: "0 0 0 0" }}>
											<p
												id="inmuebles-encontrados"
											>
												<b>Inmuebles encontrados {sumaAnuncios}</b>
											</p>
										</Col>
										<Col style={{ padding: "0 0 0 0" }}>
											<Container style={{ padding: "0 0 0 0" }}>
												<Row style={{ margin: "0 0 0 0" }} id="ordenar-container">
													<Col style={{ padding: "0 0 0 0", marginRight: "4px" }}>
														<p
															id="ordenar"
															style={{
																position: "relative",
																textAlign: "right",
																fontSize: "10pt",
															}}
														>
															<b>Ordenar:</b>
														</p>
													</Col>
													<Col style={{ padding: "0 0 0 0" }}>
														<Form.Select
															value={vOrden}
															onChange={(e) => handleOrden(e.target.value)}
															style={{ height: "75%", fontSize: "8pt", color: "black" ,width:"120px"}}
															id="ordenar-opciones"
														>
															<option value={1}>Antiguo</option>
															<option value={2}>Reciente</option>
															<option value={3}>Mayor Precio</option>
															<option value={4}>Menor Precio</option>
														</Form.Select>
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>

							</div>

							<div id="ListaMapaAnuncios">


								{isLoading ? (
									<LoadingSpinner></LoadingSpinner>
									) : (
										<div id="listaDeAnuncios">
											{/* <div > */}
											<ul className="slider-busqueda">
												{
													//.sort((a, b) => a.Distancia - b.Distancia)
													listaAnuncios
													.slice((currentPage - 1) * 24, currentPage * 24)
													.map((item) => {
														// console.log(item.Latitud,"---",item.Longitud);
														let marker, i;

														let infowindow = new window.google.maps.InfoWindow();

														marker = new window.google.maps.Marker({
															map: map,
															position: new window.google.maps.LatLng(
																item.Latitud,
																item.Longitud
																),
																icon: "https://merkasa.com.bo/img/MerkasaIcon.svg",
																draggable: false,
																});
																marcadores.current.push(marker);
																window.google.maps.event.addListener(
																	marker,
																	"click",
																	(function (marker, i) {
																		return function (e) {
																			handleRedirect(
																				item.CodigoAnuncio,
																				item.Latitud,
																				item.Longitud,
																				item.Descripcion,
																				item.Precio,
																				item.Terreno,
																				item.Construccion,
																				item.Dormitorios,
																				item.Banios,
																				item.Parqueos,
																				item.Direccion,
																				item.Transaccion,
																				item.Imagenes,
																				item.Titulo
																				);
																				};
																				})(marker, i)
																				);

																				window.google.maps.event.addListener(
																					marker,
																					"mouseover",
																					(function (marker, i) {
																						return function () {
																							try {
																								//infowindow.setContent(item.Direccion);
																								const jsonNew = JSON.parse(item.Imagenes);
																								//console.log(jsonNew.items[0].imagen);

																								infowindow.setContent(
																									"<div style='float:left;max-height:100px'><img src='" +
																											jsonNew.items[0].imagen +
																											"' style='height:80px;width:80px;border-radius:10px'></div><div style='float:right; padding: 10px;'><b>Merkasa</b><br/>" +
																											item.Direccion +
																											"<br/>$" +
																											item.Precio +
																											"</div>"
																								);

																								infowindow.open(item.Mapa, marker);
																								} catch (e) { }
																								};
																								})(marker, i)
																								);

																								window.google.maps.event.addListener(
																									marker,
																									"mouseout",
																									(function (marker, i) {
																										return function () {
																											infowindow.close();
																											};
																											})(marker, i)
																											);

																											return (
																												<li
																													className="slide-busqueda"
																													id={item.FecUsuCreacion}
																													data-precio={item.Precio}
																													key={item.CodigoAnuncio}
																												>
																													<CardAnunciosView
																														Id={item.CodigoAnuncio}
																														direccion={item.Direccion}
																														Precio={item.Precio}
																														ListaImagenes={item.Imagenes}
																														VisibleDestacar={item.Destacado}
																														CodigoAnuncio={item.CodigoAnuncio}
																														terreno={item.Terreno}
																														construccion={item.Construccion}
																														dormitorios={item.Dormitorios}
																														banios={item.Banios}
																														parqueos={item.Parqueos}
																														Transaccion={item.Transaccion}
																														TipoInmueble={item.TipoInmueble}
																														Favoritos={listaFavoritos}
																														EstadoProyecto={item.EstadoProyecto} Moneda={item.moneda}
																													></CardAnunciosView>
																												</li>
																												);
																												})
																											}
																											<div id="pages">
																												<Pagination
																													pages={Math.ceil(sumaAnuncios / 24)}
																													current={currentPage}
																													onPageChange={handlePageChange}
																												/>
																											</div>
																										</ul>
																										{/* </div> */}
																										<div id="map"></div>
																									</div>
																								)}
																							</div>
																						</>
		);
}

export default ListaMapaAnuncios;
