import React, { useState, useEffect, useRef } from "react";
import imgcama from "../img/cama.svg";
import imgbanio from "../img/banio.svg";
import imgcochera from "../img/cochera.svg";
import imgterreno from "../img/medida_02.svg";
import imgconstruido from "../img/medida_01.svg";
import "react-slideshow-image/dist/styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import axios from "axios"; // npm i axios
import { useLocalStorage } from "../useLocalStorage";
// style was imported in index.css
// import "react-slideshow-image/dist/styles.css";
//const images = [image1, image2, image3, image4, image5];

const zoomOutProperties = {
  duration: 5000,
  transitionDuration: false,
  infinite: false,
  indicators: false,
  scale: 0.4,
  arrows: true,
};

function CardAnunciosViewDetails({
  Direccion,
  Precio,
  Terreno,
  Construccion,
  codigoAnuncio,
  Dormitorios,
  Banios,
  Parqueos,
  Transaccion,
  Anio,
  FechaEntrega,
  EstadoProyecto,
  TipoInmueble,
	Moneda,
	MonedaExp
}) {
  //Slideshow(ListaImagenes);
  //console.log(ListaImagenes);

  const [caractetisticas, setcaractetisticas] = useState();
  async function fetchApi() {
    let fd2 = new FormData();
    fd2.append("idanuncio", codigoAnuncio);

    const resc = await axios.post(
      "https://merkasa.com.bo/api/ApiObtenerAnuncioCaracteristicas.php",
      fd2
    );
    setcaractetisticas(resc.data.items);
  }
  useEffect(() => {
    fetchApi();
  }, []);
  //setItems(jsonNew);

  //setItems();

  function Estado() {
    if (EstadoProyecto === "1") {
      return "En Planos";
    }
    if (EstadoProyecto === "2") {
      return "En Construcción";
    }
    if (EstadoProyecto === "3") {
      return "Finalizado";
    }
  }
  const inmueble = () => {
	 let tipo = parseInt(TipoInmueble);
    if (tipo === 1) {
      return "Casa";
    } else if (tipo === 2) {
      return "Departamento";
    } else if (tipo === 3) {
      return "Terreno";
    } else if (tipo === 4) {
      return "Oficina";
    } else if (tipo === 5) {
      return "Casa de campo";
    } else if (tipo === 6) {
      return "Local Comercial";
    } else if (tipo === 7) {
      return "Comercial";
    } else if (tipo === 8) {
      return "Industrial";
    } else if (tipo === 9) {
      return "Parqueo";
    } else if (tipo === 10) {
      return "Agrícola";
    } else {
      return "Ambiente";
    }
  };
  function precioProyecto() {
    if (Precio.indexOf("-") === -1) {
      return Precio;
    } else {
      return Precio.substring(0, Precio.indexOf("-"));
    }
  }

  return (
    <>
      <Card style={{ width: "100%", height: "100%" }} className="card-anuncio-detalle">
        <Card.Body>
          <Card.Text>
            <br></br>
            <Container fluid>
              <Row>
                <Col>
                  <center>
                    <b>
                      <p
                        style={{
                          fontSize: "11pt",
                          padding: "0 0 0 0",
                          float: "left",
                          position: "relative",
                          bottom: "18px",
                          height: "30px",
                          wordBreak: "break-word",
                          hyphens: "auto",
                          overflowWrap: "break-word",
                        }}
                      >
                        {inmueble()} en {Transaccion}
                      </p>
                    </b>
                  </center>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className="divPrecioTopNew"
                    style={{ position: "relative", bottom: "30px" }}
                  >
                    <p>{Moneda==="BS"?"Bs":"$us"} {Precio}</p>
                  </div>
                </Col>
              </Row>
              {Transaccion == "Proyecto" ? (
                <>
                  <Row
                    style={{
                      flexWrap: "nowrap",
                      justifyContent: "flex-end",
                      gap: "0.5%",
                    }}
                  >
                    {Dormitorios == 0 ? (
                      <></>
                    ) : (
                      <>
                        <Col style={{ whiteSpace: "nowrap" }}>
                          <p
                            id="letra-dormitorios-project"
                            style={{ fontSize: "8pt" }}
                          >
                            <img src={imgcama} style={{ height: "12px" }}></img>
                            <b> Desde {Dormitorios} dormitorios</b>
                          </p>
                        </Col>
                      </>
                    )}

                    {Banios == 0 ? (
                      <></>
                    ) : (
                      <>
                        <Col id="banio-project">
                          <p
                            id="letra-banio-project"
                            style={{ fontSize: "8pt" }}
                          >
                            <img
                              src={imgbanio}
                              style={{ height: "12px" }}
                            ></img>
                            <b> Desde {Banios} baños</b>
                          </p>
                        </Col>
                      </>
                    )}

                    {Construccion == 0 ? (
                      <></>
                    ) : (
                      <>
                        <Col id="const-project">
                          <p
                            id="letra-const-project"
                            style={{ fontSize: "8pt" }}
                          >
                            <img
                              src={imgconstruido}
                              style={{ height: "12px" }}
                            ></img>
                            <b> {Construccion} m²</b>
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    {FechaEntrega == "" ? (
                      <></>
                    ) : (
                      <>
                        <Col xs={7}>
                          <p style={{ fontSize: "8pt", whiteSpace: "nowrap" }}>
                            {" "}
                            Fecha de Entrega: <b>{FechaEntrega}</b>
                          </p>
                        </Col>
                      </>
                    )}
                    {EstadoProyecto == "" ? (
                      <></>
                    ) : (
                      <>
                        <Col xs={5}>
                          <p style={{ fontSize: "8pt", whiteSpace: "nowrap" }}>
                            {" "}
                            Estado: <b>{Estado()}</b>
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              ) : (
                <Row style={{ flexWrap: "nowrap", padding: "0 0 0 15px" }}>
                  {Dormitorios == 0 ? (
                    <></>
                  ) : (
                    <>
                      <Col
                        style={{
                          width: "20%",
                          whiteSpace: "nowrap",
                          padding: "0 0 0 0",
                        }}
                      >
                        <p style={{ fontSize: "8pt" }}>
                          <img src={imgcama} style={{ height: "12px" }}></img>
                          <b> {Dormitorios}</b>
                        </p>
                      </Col>
                    </>
                  )}

                  {Banios == 0 ? (
                    <></>
                  ) : (
                    <>
                      <Col
                        style={{
                          width: "17%",
                          whiteSpace: "nowrap",
                          padding: "0 0 0 0",
                        }}
                      >
                        <p style={{ fontSize: "8pt" }}>
                          <img src={imgbanio} style={{ height: "12px" }}></img>
                          <b>{Banios}</b>
                        </p>
                      </Col>
                    </>
                  )}

                  {Parqueos == 0 ? (
                    <></>
                  ) : (
                    <>
                      <Col
                        style={{
                          width: "17%",
                          whiteSpace: "nowrap",
                          padding: "0 0 0 0",
                        }}
                      >
                        <p style={{ fontSize: "8pt" }}>
                          <img
                            src={imgcochera}
                            style={{ height: "12px" }}
                          ></img>
                          <b> {Parqueos}</b>
                        </p>
                      </Col>
                    </>
                  )}

                  {Construccion == 0 ? (
                    <></>
                  ) : (
                    <>
                      <Col
                        style={{
                          width: "25%",
                          whiteSpace: "nowrap",
                          padding: "0 0 0 0",
                        }}
                      >
                        <p style={{ fontSize: "8pt" }}>
                          <img
                            src={imgconstruido}
                            style={{ height: "12px" }}
                          ></img>
                          <b> {Construccion} m²</b>
                        </p>
                      </Col>
                    </>
                  )}

                  {Terreno == 0 ? (
                    <></>
                  ) : (
                    <>
                      <Col
                        style={{
                          width: "25%",
                          whiteSpace: "nowrap",
                          padding: "0 0 0 0",
                        }}
                      >
                        <p style={{ fontSize: "8pt" }}>
                          <img
                            src={imgterreno}
                            style={{ height: "12px" }}
                          ></img>
                          <b> {Terreno} m²</b>
                        </p>
                      </Col>
                    </>
                  )}
                </Row>
              )}
              <Row>
                <Col xs={12}>
                  <div style={{ borderBottom: "1px solid #9c9c9c" }}></div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} style={{ padding: "0 0 0 0" }}>
                  <ul className="registrocarac_ul">
                    {!caractetisticas
                      ? "No hay caracteristicas que mostrar."
                      : caractetisticas.map((item, index) => {
                          return (
                            <>
                              <li>
                                <img
                                  id={"btncaracteristicasregistro_" + index}
                                  src={item.UrlImg}
                                  className="btncaracteristicasanuncio"
                                  alt="Caracteristicas Adicionales"
                                ></img>
                              </li>
                            </>
                          );
                        })}
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div style={{ borderBottom: "1px solid #9c9c9c" }}></div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "8pt",
                      paddingTop: "10px",
                    }}
                  >
                    <b>{Direccion}</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div id="VerEnMapa"></div>
                </Col>
              </Row>
            </Container>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default CardAnunciosViewDetails;
