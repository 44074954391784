import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import inmobiliarios from "../../src/img/inmobiliarios.png";
import particular from "../../src/img/particular.png";
import agente from "../../src/img/Agente.svg";
import desarrollador from "../../src/img/Desarrollador.svg";
import evaluador from "../../src/img/Evaluador.svg";
import notario from "../../src/img/Notario.svg";
import abogado from "../../src/img/Abogado.svg";
import asesorcrediticio from "../../src/img/AsesorCrediticio.svg";
import albanil from "../../src/img/Albanil.svg";
import arquitecto from "../../src/img/Arquitecto.svg";
import diseñadorInteriores from "../../src/img/DisenadorInteriores.svg";
import electricista from "../../src/img/Electricista.svg";
import gasfitero from "../../src/img/Gasfitero.svg";
import jardinero from "../../src/img/jardinero.svg";
import melanine from "../../src/img/Melamine.svg";
import drywall from "../../src/img/Drywall.svg";
import carpintero from "../../src/img/Carpintero.svg";
import pintor from "../../src/img/Pintor.svg";
import tiendasElectrodomesticas from "../../src/img/TiendasElectrodomesticas.svg";
import artista from "../../src/img/ArtistaDecoracion.svg";
import { useLocalStorage } from '../useLocalStorage';
import {useNavigate } from "react-router-dom";
import {Link} from "react-router-dom";



function Registro({Tipo,ocultarModal,MostrarButton}) {
	const [show2, setShow2] = useState(false);

	const [ischecked, setChecked] = useState(false);
	const [perfil, setPerfil] = useState('');
	const [numerodocumento, setnumerodocumento] = useState('');
	const [nombre, setNombre] = useState('');
	const [apellido, setApellido] = useState('');
	const [celular, setCelular] = useState('');
	const [correo, setCorreo] = useState('');
	const [contraseña, setContraseña] = useState('');
	const [respuesta, setRespuesta] = useState('');
	const [verSeleccionarPerfil, setSeleccionarPerfil] = useState(true)
	const [verSeleccionarPerfilProfesional, setSeleccionarPerfilProfesional] = useState(false)
	const [perfilSeleccionadoRegistro, setperfilSeleccionadoRegistro] = useLocalStorage('perfilSeleccionadoRegistro','')
	const [tipoperfilseleccionadoRegistro, settipoperfilseleccionadoRegistro] = useLocalStorage('tipoperfilseleccionadoRegistro',0)
	const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
	const [tipodocumento, settipodocumento] = useState('1');


	const url = 'https://merkasa.com.bo/api/ApiListarTipoUsuario.php'
	const [items, setItems] = useState();
	const UsuActivo = sessionStorage.getItem("UsuarioActivo");
	const handleClose2 = async() => {
		setShow2(false)
		setSeleccionarPerfil(true)
		setSeleccionarPerfilProfesional(false)
		if ((Tipo === 2)&&(idusuario != null)){
			navigate('/RegistroAnuncio')
			}
		};
	const handleShow = () => {
		setShow2(true); 
		typeof(ocultarModal) === 'function' && ocultarModal();
		};


	const clear = () => {
		setPerfil('');
		setApellido('');
		setNombre('');
		setnumerodocumento('');
		setCelular('');
		setCorreo('');
		setContraseña('');
		}


	const fetchApi = async () => {

		const response = await fetch(url)
		const responseJSON = await response.json()
		console.log("json",responseJSON);
		setItems(responseJSON.items)   
		}

	useEffect(() => {
		fetchApi();
		},[])

	const navigate = useNavigate();

	const RegistroAcceso = async (perfil,numerodocumento,nombre,apellido,celular,correo, contraseña) => {
		const iperfil = localStorage.getItem('tipoperfilseleccionadoRegistro')

		const vperfil = JSON.parse(iperfil);
		if(vperfil == 0)
			{
			alert("Debe seleccionar un perfil"); 
			return;
			}

		/* if(tipodocumento == '') */
			/* { */
			/* alert("Debe seleccionar un tipo de documento."); */
			/* return; */
			/* } */

		if (!ischecked)
			{

			alert("Debe aceptar los términos y condiciones para continuar");
			return;
			}
		if (!/[A-Z]/.test(contraseña)) {
			alert ("La contraseña debe contener al menos una letra mayúscula.");
			return;
			}

		// Restricción 3: Como mínimo un número
		if (!/\d/.test(contraseña)) {
			alert ("La contraseña debe contener al menos un número.");
			return
			}


		const data = JSON.stringify({
			perfil : vperfil,numerodocumento: -1,nombre : nombre,apellido : apellido,celular : celular,correo:correo, contrasenia:contraseña,tipodocumento:tipodocumento,tipousuario:perfilSeleccionadoRegistro,
			})


		await fetch('https://merkasa.com.bo/api/ApiRegistro.php', {
			method: 'POST',
			body: data,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				},
			})
		.then((response) => response.json())
		.then((data) => {
			const respuesta = parseInt(data.usuarioid);
			if(data === 0)
			{
			   alert("El correo ingresado ya cuenta con un usuario, puede recuperar su contraseña desde la opción 'Recuperar Contraseña'");
			   return;
			}
			else{
				localStorage.setItem("UsuarioActivo",JSON.stringify(correo));
				setIdusuario(respuesta);
				handleClose2();
				}
			window.location.reload(true);


			})
		.catch((err) => {
			console.log(err.message);
			});
		};

	function formatCadena(cadena)
	{
		let newValor = cadena.replace(/[^A-Za-z ñ]/,"");

		return newValor;
		}

	function soloNumeros(number)
	{

		let newValor = number.replace(/\D/g, "");

		return newValor;
		}

	const handleClick = (tipo) =>{

		if(tipo == 1)
			{
			setperfilSeleccionadoRegistro("1");
			settipoperfilseleccionadoRegistro(2) //dueño de casa
			setSeleccionarPerfil(false);
			}else if(tipo == 2)
			{
				setSeleccionarPerfilProfesional(true);
				setperfilSeleccionadoRegistro("2");
				}
		else if(tipo == 3)
			{
			setSeleccionarPerfil(false);
			setSeleccionarPerfilProfesional(false);
			}
		}
	var firstClick = 0;
	const handleClickTipoPerfil = (tipo) =>{
		const now = new Date().getTime();	
		if(now - firstClick < 1000){
			handleClick(3);
			}
		else{
			firstClick = now;
			var selectbtn = document.getElementsByClassName("btnperfil");

			for (let index = 0; index < selectbtn.length; index++) 
				{
				selectbtn[index].className = "btnperfil";  
				}

			var x = document.getElementById("btnperfil_"+tipo);

			if (x.className === "btnperfil") 
				{
				x.className += " activo";

				} else {
					x.className = "btnperfil";

					}
			settipoperfilseleccionadoRegistro(tipo)
			}
		}

	const handleSubmit = (e) => {

		e.preventDefault();
		RegistroAcceso(perfil,numerodocumento,nombre,apellido,celular,correo, contraseña);

		}; 

	const handleCheckboxChange = (e) => {
		setChecked(e.target.checked);	
		}
	return (
		<>
			{MostrarButton && <Button variant="primary6" type='button' onClick={handleShow}>
		Empezar
					</Button>}
						<Button id='registro' onClick={handleShow}></Button>

					{verSeleccionarPerfil == true ?  
					<>
						{verSeleccionarPerfilProfesional == true ? 
						<>
							<Modal size="lg" show={show2} onHide={(e) => setShow2(false)}>
								<Modal.Header closeButton>
									<Container>
										<Row>
											<Col sm={12}>
												<center><h5><b>Selecciona tu perfil</b></h5></center>
											</Col>
										</Row>
									</Container>
								</Modal.Header>
								<Modal.Body>
									<Container style={{padding:'0 0 0 0'}}>
										<Row>
											<Col sm={1}>

											</Col>
											<Col sm={10}>
												<Container style={{padding:'0 0 0 0'}}>
													<Row>
														<Col sm={12}>
															<p><b>Inmobiliario</b></p>
														</Col>
														<Col sm={12}>
															<Container style={{padding:'0 0 0 0'}}>
																<Row>
																	<Col sm={2} style={{borderBottom:'1px solid ##FFE521'}}>
																		<img src={agente} id='btnperfil_1' style={{cursor:'pointer'}} className='btnperfil' onClick={(e) => handleClickTipoPerfil(1)}></img>
																	</Col>
																	<Col sm={2}>
																		<img src={desarrollador} id='btnperfil_6' style={{cursor:'pointer'}} className='btnperfil' onClick={(e) => handleClickTipoPerfil(6)}></img>
																	</Col>
																	<Col sm={2} className='perfiles'>
																		<img src={evaluador} id='btnperfil_7' className='btnperfil2' ></img>
																	</Col>
																	<Col sm={2} className='perfiles'>
																		<img src={notario} id='btnperfil_8' className='btnperfil2' ></img>
																	</Col>
																	<Col sm={2} className='perfiles'>
																		<img src={abogado} id='btnperfil_9'  className='btnperfil2' ></img>
																	</Col>
																	<Col sm={2} className='perfiles'>
																		<img src={asesorcrediticio} id='btnperfil_10' className='btnperfil2' ></img>
																	</Col>

																</Row>
															</Container>
														</Col>
													</Row>

												</Container>
											</Col>
											<Col sm={1}>

											</Col>
										</Row>
										<Row>
											<Col sm={1}>

											</Col>
											<Col sm={10}>
												<Container style={{padding:'0 0 0 0'}}>
													<Row>
														<Col sm={12} className='perfiles'>
															<p><b>Mejoras para el hogar </b> <i>(Próximamente en Merkasa)</i></p>
														</Col>
														<Col sm={12}>
															<Container style={{padding:'0 0 0 0'}} className='perfiles'>
																<Row>
																	<Col sm={2}>
																		<img src={albanil} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={arquitecto} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={diseñadorInteriores} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={electricista} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={gasfitero} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={jardinero} className='btnperfil2'></img>
																	</Col>

																</Row>
															</Container>
														</Col>
														<Col sm={12}>
															<Container style={{padding:'0 0 0 0'}} className='perfiles'>
																<Row>
																	<Col sm={2}>
																		<img src={melanine} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={drywall} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={carpintero} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={pintor} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={tiendasElectrodomesticas} className='btnperfil2'></img>
																	</Col>
																	<Col sm={2}>
																		<img src={artista} className='btnperfil2'></img>
																	</Col>

																</Row>
															</Container>
														</Col>
													</Row>
													<br></br>
													<Row>
														<Col sm={12}>
															<center><Button className='btnLogin' variant='primary4' onClick={(e) => handleClick(3)}>
																	<b>Aceptar</b>
															</Button></center>
														</Col>
													</Row>

												</Container>
											</Col>
											<Col sm={1}>

											</Col>
										</Row>
									</Container>
								</Modal.Body>
							</Modal>
						</> : 

						<>
							<Modal size="lg" show={show2} onHide={() => setShow2(false)}>
								<Modal.Header style={{border:'none'}} closeButton>
									<Container>
										<Row>
											<Col sm={12}>
												<br></br>
											</Col>
										</Row>
										<Row>
											<Col sm={12}>
												<center><h5 style={{color:'#000'}}><b>Selecciona tu perfil</b></h5></center>
											</Col>
										</Row>
									</Container>
								</Modal.Header>
								<Modal.Body>
									<Container>
										<Row>
											<Col sm={2}>

											</Col>
											<Col sm={4}>
												<Container style={{padding:'0 0 20px 0',cursor:'pointer'}} onClick={(e) => handleClick(1)}>
													<Row>
														<Col sm={12}>
															<center><img src={particular} style={{width:'170px'}}></img></center> 
														</Col>
														<Col sm={12}>
															<center><p style={{color:'#000'}}>Particular/Propietario</p></center>
														</Col>
														<Col sm={12}>
															<center><Button className='btnLogin' variant='primary6' onClick={(e) => handleClick(1)}>
																	<b>Continuemos</b>
															</Button></center>

														</Col>
													</Row>
												</Container>
											</Col>
											<Col sm={4}>
												<Container style={{padding:'0 0 0 0',cursor:'pointer'}} onClick={(e) => handleClick(2)}>
													<Row>
														<Col sm={12}>
															<center><img src={inmobiliarios} style={{width:'147px'}}></img></center> 
														</Col>
														<Col sm={12}>
															<center><p style={{color:'#000'}}>Inmobiliarios</p></center>
														</Col>
														<Col sm={12}>
															<center><Button className='btnLogin' variant='primary6' onClick={(e) => handleClick(2)}>
																	<b>Continuemos</b>
															</Button></center>

														</Col>
													</Row>
												</Container>
											</Col>
											<Col sm={2}>

											</Col>
										</Row>
									</Container>
								</Modal.Body>
							</Modal>
					</>}


				</> : 
				//Registro de particular
				<>
					<Modal show={show2} onHide={() => setShow2(false)}>
						<Modal.Header closeButton>
							<Container>
								<Row>
									<Col sm={12}>
										<center><h1>Bienvenido</h1></center>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<center><h6>Registrate para publicar</h6></center> 
									</Col>
								</Row>
							</Container>
						</Modal.Header>
						<Modal.Body>
							<Form onSubmit={handleSubmit}>

								<Container style={{paddingBottom:'20px',paddingTop:'20px'}}>
									<Row>
										<Col sm={1}>
										</Col>
										<Col sm={5}>
											<Form.Group className="sm-3" controlId="exampleForm.ControlInput0">
												<Form.Label style={{fontSize:'10pt',color:'black'}}>Perfil</Form.Label>
												<Form.Select 

													value={tipoperfilseleccionadoRegistro}
													onChange={(e) => setPerfil(e.target.value)}
													disabled
													required
													style={{fontSize:'7pt',color:'black'}}
												>
													{ !items ? 'Cargando....' : items.map( (item,index) => {
														return <option value={item.codtipocliente}>{item.descripcion}</option>
															})
														}
													</Form.Select>
												</Form.Group>
											</Col>
											<Col sm={5}>
											</Col>
											<Col sm={1}>
											</Col>
										</Row>
										<Row>
											<Col sm={1}>
											</Col>
											<Col sm={5}>
												<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
													<Form.Label style={{fontSize:'10pt',color:'black'}}>Nombre</Form.Label>
													<Form.Control
														type="text"
														value={nombre}
														onChange={(e) => setNombre(formatCadena(e.target.value))}
														minLength="4"
														required
														style={{fontSize:'9pt',color:'black'}}
													/>
												</Form.Group>
											</Col>
											<Col sm={5}>
												<Form.Group className="sm-3" controlId="exampleForm.ControlInput3">
													<Form.Label style={{fontSize:'10pt',color:'black'}}>Apellido</Form.Label>
													<Form.Control
														type="text"
														value={apellido}
														minLength="4"
														onChange={(e) => setApellido(formatCadena(e.target.value))}
						required
						style={{fontSize:'9pt',color:'black'}}
						/>
					</Form.Group>
				</Col>
				<Col sm={1}>
				</Col>
			</Row>
			<Row>
				<Col sm={1}>
				</Col>
				<Col sm={5}>
					<Form.Group className="sm-3" controlId="exampleForm.ControlInput4">
						<Form.Label style={{fontSize:'10pt',color:'black'}}>Celular</Form.Label>
						<Form.Control
							type="text"
							minLength="8"
							value={celular}
							onChange={(e) => 
							e.target.value.length<=8 ?
						setCelular(soloNumeros(e.target.value)):null}
						required
						style={{fontSize:'9pt',color:'black'}}
					/>
				</Form.Group>
			</Col>
			<Col sm={5}>
				<Form.Group className="sm-3" controlId="exampleForm.ControlInput5">
					<Form.Label style={{fontSize:'10pt',color:'black'}}>Email</Form.Label>
					<Form.Control
						type="email"
						placeholder="nombre@dominio.com"
						value={correo}
						onChange={(e) => setCorreo(e.target.value)}
						required
						style={{fontSize:'9pt',color:'black'}}
					/>
				</Form.Group>
			</Col>
			<Col sm={1}>
			</Col>
		</Row>
		<Row>
			<Col sm={1}>
			</Col>
			<Col sm={10}>
				<Form.Group className="sm-3" controlId="exampleForm.ControlInput6">
					<Form.Label style={{fontSize:'10pt',color:'black'}}>Contraseña</Form.Label>
					<Form.Control
						type="password"
						value={contraseña}
						minLength="8"
						onChange={(e) => setContraseña(e.target.value)}
						required
						style={{fontSize:'9pt',color:'black'}}
					/>
				</Form.Group>
			</Col>
			<Col sm={1}>
			</Col>
		</Row>
	</Container>        
	<Container>
		<Row>
			<Col sm={12}>
				<div style={{display:'flex',justifyContent:'center',gap:'1%'}}>
					<input
						type="checkbox"
						checked={ischecked}
						onChange={handleCheckboxChange}
					/>
					<Link to='/TerminosCondiciones' target='_blank'><label style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue',fontSize:'8pt' }}>
							He leído y acepto los términos y condiciones y políticas de privacidad.
					</label></Link>
				</div>
			</Col>
		</Row>
		<br></br>
		<Row>
			<Col sm={12}>
				<center>

					<Button variant="primary4" type='submit'>
						Aceptar
					</Button>
				</center> 
			</Col>
		</Row>
	</Container>


</Form>
</Modal.Body>
	  </Modal>
	  </>}

  </>
		);
}

export default Registro;
