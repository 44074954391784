import Modal from 'react-bootstrap/Modal';
import Carousel from "react-bootstrap/Carousel";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoadingSpinner from "./LoadingSpinner";

function ModalImagenes({ListaImagenes,mostrar,pos,handleClose,handleSelect}) {

	return (
		<>
			<Modal show={mostrar} onHide={handleClose} fullscreen={true} style={{overflow:'hidden'}} scrollable={false}>
				<Modal.Header closeButton style={{height:'5px'}}>
				</Modal.Header>
				<Modal.Body style={{
					backgroundColor:'black',
					padding:'0 0 0 0',
					display:'flex',
					justifyContent:'center',
					alignItems:'center',
					overflow:'hidden'}}>
					<Carousel activeIndex={pos} onSelect={handleSelect} interval={null} indicators={true} id='carousel-anuncios'>
						{ !ListaImagenes ? <LoadingSpinner></LoadingSpinner> : ListaImagenes.map( (item) => {
							return (
								<Carousel.Item>
									<center><LazyLoadImage
											effect="blur"
											src={item.imagen}
											className='img-fluid'
											style={{height: '100vh',width: '80vw',objectFit:'contain'}}
										></LazyLoadImage></center>
								</Carousel.Item>
							)
							})      
						}
					</Carousel> 
				</Modal.Body>
			</Modal>
		</>
		);
}

export default ModalImagenes;
