import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Modal, Button } from "react-bootstrap";
import axios from "axios"; // npm i axios
import imgVendedor from "../src/img/face_no perfil.svg";
import imgRegresarLista from "../src/img/volver_buscar.svg";
import { Link, useNavigate } from "react-router-dom";
import imgcorazon1 from "../src/img/corazon_2.svg";
import imgcorazon2 from "../src/img/corazon1.svg";
import imgwsp from "../src/img/whatsaap.svg";
import imgshared from "../src/img/shared.svg";
import { FaCopy, FaWhatsapp } from "react-icons/fa";
import { EditorState, convertFromRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom";
import {
	FacebookShareButton,
	FacebookIcon,
	WhatsappShareButton,
	WhatsappIcon,
} from "react-share";

import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { LuShare2 } from "react-icons/lu";

//Componentes
import CardAnunciosViewDetails from "./Components/CardAnunciosViewDetails";
import Mapadetalleanuncio from "./Components/Mapadetallesanuncio";
import ModalImagenes from "./Components/Modal_Imagenes";
import { useLocalStorage } from "./useLocalStorage";
import LoadingSpinner from "./Components/LoadingSpinner";
import Layout from "./Components/Layout";
import { useOrientation } from "react-use";
import Carousel from "react-bootstrap/Carousel";
import ReactPlayer from 'react-player'
import imgLogo from "./img/logo_new.svg";
var map;

function DetalleAnuncio() {
	const { angle, type } = useOrientation();
	const isPortrait = type.includes("portrait");
	const [mostrarmensaje,setMostrarMensaje] = useState(false);

	// console.log("isPortrait:", isPortrait);

	const url = "https://merkasa.com.bo/api/ApiListarAnunciosImg.php";
	const [lista, setLista] = useState();
	const [iCantidadMostrar, setiCantidadMostrar] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [icantidad, setiCantidad] = useState(0);

	const [latitud, setLatitud] = useState();
	const [longitud, setlongitud] = useState();
	const [descripcion, setDescripcion] = useState();
	const [terreno, setTerreno] = useState();
	const [construccion, setConstruccion] = useState();
	const [dormitorios, setDormitorios] = useState();
	const [banios, setBanios] = useState();
	const [parqueos, setParqueos] = useState();
	const [direccion, setDireccion] = useState();
	const [urlImagen, setUrl] = useState();
	const [transaccion, setTransaccion] = useState();
	const [titulo, setTitulo] = useState();
	const [nombre, setNombre] = useState();
	const [apellidopaterno, setApellidoPaterno] = useState();
	const [celular, setCelular] = useState();
	const [bvisibleDatosContacto, setVisibleDatosContacto] = useState(true);
	const [tipobotton, setTipoBotton] = useState("submit");
	const [megusta, setMegusta] = useState(false);
	const [fechacreacionanuncio, setfechacreacionanuncio] = useState(false);
	const [anio, setanio] = useState("");
	const [fechaEntrega, setFechaEntrega] = useState();
	const [estadoproyecto, setEstadoProyecto] = useState();
	const [tipoinmueble, setTipoInmueble] = useState();
	const [mostrar, setMostrar] = useState(false);
	const [mostrarCompartir, setMostrarCompartir] = useState(false);

	/*DATOS PARA FORMULARIO*/

	const [correoContacto, setCorreoContacto] = useState("");
	const [nombreContacto, setNombreContacto] = useState("");
	const [apellidoContacto, setApellidoContacto] = useState("");
	const [celularContacto, setCelularContacto] = useState("");
	const [bCompletoFormulario, setCompletoFormulario] = useState(false);
	const [idusuario, setIdusuario] = useLocalStorage("idusuario", "");
	const [show, setShow] = useState(false);
	const [copied, setCopied] = useState(false);
	const [posicion, setPosicion] = useState(0);
	const [richText, setRichText] = useState("");
	// const [editorState, setEditorState] = useState(() =>{const rawContent = sessionStorage.getItem("DescripcionActualSeleccion");
	//     return rawContent ? convertFromRaw(rawContent) : EditorState.createEmpty()});
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const sjcl = require("sjcl");
	let { idAnuncio } = useParams();
	const CodAnuncio = atob(idAnuncio);
	// idAnuncio = idAnuncio.replace(/a*2/g, '"');
	// const CodAnuncio = sjcl.decrypt("claveAnuncioEncriptado",decodeURIComponent(idAnuncio));
	const [anuncios, setAnuncios] = useState();
	const [agentes, setAgentes] = useState();
	const [mostrarvideo, setMostrarVideo] = useState(false);
	const [listavideo, setListaVideo] =  useState();
	function capitalizar(cadena) {
		if (cadena !== undefined) {
			const oraciones = cadena.split(".");

			const oracionesCapitalizadas = oraciones.map((oracion) => {
				oracion = oracion.trim();

				return oracion.charAt(0).toUpperCase() + oracion.slice(1).toLowerCase();
				});

			const parrafo = oracionesCapitalizadas.join(". ");

			return parrafo;
			}
		}
	let precioP = useRef("");


	const getImagenesyVideos = async () =>{

		let fd = new FormData() 
		fd.append("idanuncio",CodAnuncio)
		const res = await axios.post('https://merkasa.com.bo/api/ApiListarAnunciosImg.php', fd);

		setiCantidad(res.data.items.length)
		setLista(res.data.items)
		setIsLoading(false);


		let fd2 = new FormData() 
		fd2.append("idanuncio",CodAnuncio)
		const res2 = await axios.post('https://merkasa.com.bo/api/ApiListarAnunciosVideos.php', fd2);
		setListaVideo(res2.data.items)

		} 

	const registrarvisitas = async () =>{


		const data = JSON.stringify({
			pidusuario:(idusuario == '' ? 0 : idusuario),pidanuncio:CodAnuncio,pcorreo:'',
			})
		await fetch('https://merkasa.com.bo/api/ApiRegistroAnuncioVisita.php', {
			method: 'POST',
			body: data,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				},
			})
		.then((response) => response.json())
		.then((data) => {
			//setPosts((posts) => [data, ...posts]);

			const respuesta = data.idAnuncioV;

			if(respuesta == 0)
				{
				//alert("El Anuncio no fue registrado, intente nuevamente.");  
				return; 
				}else{

					if(!isNaN(respuesta))
						{
						//agregar una acción si es necesario
						}
					//handleRedirect();
					}
			})
		.catch((err) => {
			console.log(err.message);
			});
		}


	const navigate = useNavigate();

	const handleRedirect = () => {
		sessionStorage.setItem("CodAnuncioActualSeleccion", null);
		sessionStorage.setItem("DescripcionActualSeleccion", null);
		sessionStorage.setItem("PrecioActualSeleccion", null);
		sessionStorage.setItem("TerrenoActualSeleccion", null);
		sessionStorage.setItem("ConstruccionActualSeleccion", null);
		sessionStorage.setItem("DormitoriosActualSeleccion", null);
		sessionStorage.setItem("BaniosActualSeleccion", null);
		sessionStorage.setItem("ParqueosActualSeleccion", null);
		sessionStorage.setItem("DireccionActualSeleccion", null);
		sessionStorage.setItem("ImagenActualSeleccion", null);
		sessionStorage.setItem("FechaCreacionActualSeleccion", null);

		//window.open(window.location.origin +"/ListAnuncios", "_self");
		navigate(-1);
		};
	const errorCallback = (error) => {
		console.log("error", error);
		};
	function generateClave() {
		const clave =
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15);
		return clave;
		}
	function calcularFavoritos(id) {}

	function registrarVisita() {
		let codigo;
		if (localStorage.getItem("clave") == undefined) {
			codigo = generateClave();
			localStorage.setItem("clave", codigo);
			} else {
				codigo = localStorage.getItem("clave");
				}

		const fecha = new Date().toLocaleString();
		const tipoDeDispositivo = navigator.userAgent + "/Anuncio";

		const data = {
			fecha: fecha,
			latitud: "",
			longitud: "",
			tipoDeDispositivo: tipoDeDispositivo,
			clave: codigo,
			};

		enviarDatos(data);
		}

	useEffect(() => {
		navigator.geolocation.getCurrentPosition((position) => {
			if (position) {
				const latitud = position.coords.latitude;
				const longitud = position.coords.longitude;

				const data = {
					fecha: "",
					latitud: latitud,
					longitud: longitud,
					tipoDeDipositivo: "",
					codigo: "",
					};

				enviarDatos(data);
				}
			}, errorCallback);

		registrarVisita();
		}, []);
	async function enviarDatos(data) {
		await fetch("https://merkasa.com.bo/api/ApiRegistroVisitas.php", {
			method: "POST",
			mode: "no-cors",
			headers: {
				"Content-Type": "application/json",
				},
			body: JSON.stringify(data),
			})
		.then((response) => console.log("Datos enviados"))
		.catch((error) => console.error("Error al enviar datos", error));
		}
	useEffect(() => {
		fetchApi();
		getImagenesyVideos();
		registrarvisitas();
		window.scrollTo(0, 0);
		}, []);
	useEffect(() => {
		try {
			if (descripcion !== undefined && descripcion != "") {
				const newContentState = convertFromRaw(JSON.parse(descripcion));
				const newEditorState = EditorState.createWithContent(newContentState);
				setEditorState(newEditorState);
				}
			} catch (error) {
				let contenido = ContentState.createFromText(descripcion);
				setEditorState(EditorState.createWithContent(contenido));
				}
		}, [descripcion]);

	useEffect(() => {
		if (bCompletoFormulario == false) {
			return;
			}

		setNombre(agentes.Nombre);
		setApellidoPaterno(agentes.ApellidoPaterno);
		setCelular(agentes.Celular);
		}, [bCompletoFormulario]);

	async function fetchApi() {
		let fd = new FormData();
		fd.append("pidusuario", idusuario);
		const res3 = await axios.post(
			"https://merkasa.com.bo/api/ApiObtenerUsuariof.php",
			fd
			);

		if (res3.data != 0) {
			res3.data.items.map((item) => {
				setNombreContacto(item.Nombre);
				setApellidoContacto(item.ApellidoPaterno + " " + item.ApellidoMaterno);
				setCorreoContacto(item.correo);
				setCelularContacto(item.celular);
				});
			}
		let fd2 = new FormData();
		fd2.append("idAnuncio", CodAnuncio);
		const res4 = await axios.post(
			"https://merkasa.com.bo/api/ApiListarDetalleAnuncios.php",
			fd2
			);
		setAnuncios(res4.data);
		setTitulo(res4.data.Titulo);
		setDescripcion(res4.data.Descripcion);
		setfechacreacionanuncio(res4.data.FecUsuCreacion);

		let fd3 = new FormData();
		fd3.append("idanuncio", CodAnuncio);

		const res5 = await axios.post(
			"https://merkasa.com.bo/api/ApiListarAgenteDelAnuncio.php",
			fd3
			);
		setAgentes(res5.data);
		let usuario = res5.data;
		let UsuActivo = idusuario;

		setIsLoading(true);

		if (CodAnuncio == null || CodAnuncio == "") {
			//handleRedirectSinSesion();
			return;
			}

		if (UsuActivo != undefined) {
			setVisibleDatosContacto(false);
			setCompletoFormulario(true);
			setTipoBotton("button");
			}

		if (UsuActivo == "undefined" || UsuActivo == null || UsuActivo == "") {
			if (bCompletoFormulario == true) {
				setNombre(usuario.Nombre);
				setApellidoPaterno(usuario.ApellidoPaterno);
				setCelular(usuario.Celular);
				} else {
					setNombre(usuario.Nombre.substring(0, 3) + "*******");
					setApellidoPaterno(usuario.ApellidoPaterno.substring(0, 3) + "*******");
					setCelular(usuario.Celular.substring(0, 3) + "*******");
					}
			} else {
				setNombre(usuario.Nombre);
				setApellidoPaterno(usuario.ApellidoPaterno);
				setCelular(usuario.Celular);
				}

		setIsLoading(false);

		if (localStorage.length > 1) {
			let idusuario = localStorage.getItem("idusuario");
			let f = new FormData();
			f.append("idusuario", idusuario);
			const resf = await axios.post(
				"https://merkasa.com.bo/api/ApiListarAnunciosUsuarioFavoritos.php",
				f
				);
			let Favoritos = resf.data.items;
			if (Favoritos?.length > 0) {
				for (let i = 0; i < Favoritos.length; i++) {
					if (Favoritos[i].idAnuncio == CodAnuncio) {
						setMegusta(true);
						break;
						}
					setMegusta(false);
					}
				}
			}
		}

	const registroanuncio = async () => {
		if (CodAnuncio == null || CodAnuncio == "") {
			alert("Ha ocurrido un error, ingrese nuevamente al anuncio.");
			return;
			}

		if (idusuario == "") {
			//usuario generico cuando no inicia sesión
			let fd = new FormData();
			fd.append("idanuncio", CodAnuncio);
			fd.append("idusuario", 1);
			fd.append("correo", correoContacto);
			fd.append("nombre", nombreContacto);
			fd.append("apellido", apellidoContacto);
			fd.append("celular", celularContacto);

			const res = await axios.post(
				"https://merkasa.com.bo/api/ApiSendMail.php",
				fd
				);
			} else {
				let fd = new FormData();
				fd.append("idanuncio", CodAnuncio);
				fd.append("idusuario", idusuario);
				fd.append("correo", correoContacto);
				fd.append("nombre", nombreContacto);
				fd.append("apellido", apellidoContacto);
				fd.append("celular", celularContacto);

				const res = await axios.post(
					"https://merkasa.com.bo/api/ApiSendMail.php",
					fd
					);
				}
		};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (
			correoContacto == "" ||
			nombreContacto == "" ||
			apellidoContacto == "" ||
			celularContacto == ""
			) {
			alert("Debe llenar los datos para contactar.");
			return;
			}

		//cuando se contacta desactiva validación
		setVisibleDatosContacto(false);
		setCompletoFormulario(true);
		setTipoBotton("button");

		registroanuncio();

		setShow(true);
		setCorreoContacto("");
		setNombreContacto("");
		setApellidoContacto("");
		setCelularContacto("");
		//alert("Tus datos fueron enviados exitosamente");
		};
	function handleClick(tipo) {
		let url = window.location.href;
		if (tipo === 1) {
			window.open(
				"https://wa.me/+591" +
				celular +
				"?text=Hola,%20vi%20este%20inmueble%20en%20Merkasa%20y%20me%20gustaria%20tener%20mas%20información.%0A" +
				url,
				"_blank"
				);
			} else if (tipo === 2) {
				setMostrarCompartir(true);
				}
		}

	function handleCloseVideo(){
		setMostrarVideo(false);
		}
	function handleClickVideos(){
		console.log("numero de links",listavideo.length)
		if (listavideo.length > 0){
			setMostrarVideo(true);
			}
		else{
			setMostrarMensaje(true);
			}
		}
	async function registroLike() {
		const idusuario = localStorage.getItem("idusuario");
		if (idusuario === null || idusuario.length < 1) {
			alert("Debe iniciar sesión para poder guardar este anuncio a favoritos");
			return;
			}
		if (megusta === false) {
			setMegusta(true);
			if (idusuario !== "") {
				const data = JSON.stringify({
					pidusuario: idusuario,
					pidanuncio: CodAnuncio,
					});
				await fetch("https://merkasa.com.bo/api/ApiRegistroFavoritos.php", {
					method: "POST",
					body: data,
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						},
					})
					.then((response) => response.json())
				.then((data) => {
					//setPosts((posts) => [data, ...posts]);

					const respuesta = data.idAnuncioF;

					if (respuesta === 0) {
						return;
						} else {
							if (isNaN(respuesta)) {
								alert("Hubo un error en el registro del anuncio");
								}
							//handleRedirect();
							}
					});
				}
			} else {
				setMegusta(false);
				const data = JSON.stringify({
					idanuncio: CodAnuncio,
					});

				await fetch("https://merkasa.com.bo/api/ApiEliminarFavorito.php", {
					method: "POST",
					body: data,
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						},
					})
				.then((response) => response.json())
				.then((data) => {
					//setPosts((posts) => [data, ...posts]);
					console.log("datos", data);
					const respuesta = JSON.parse(data);

					if (respuesta != "1") {
						alert("Hubo un error en el sistema, inténtelo mas tarde.");
						}
					})
				.catch((err) => {
					console.log(err.message);
					});
				}
		}

	function soloNumeros(number) {
		let newValor = number.replace(/[^0-9]|^E/i, "");

		return newValor;
		}

	function formatCadena(cadena) {
		let newValor = cadena.replace(/[^A-Za-z0-9 ñ]/, "");

		return newValor;
		}

	const handleClose = () => {
		setShow(false);
		};
	const copyToClipboard = () => {
		const el = document.createElement("textarea");
		el.value = window.location.href;
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		setCopied(true);
		};

	return (
		<>
			<Layout>
				<br></br>
				<>
					<Modal centered={true} show={show} onHide={handleClose}>
						<Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
						<Modal.Body>
							<Container style={{ paddingBottom: "calc(30px + 2vmin)" }}>
								<Row>
									<Col sm={2}></Col>
									<Col sm={8}>
										<Row>
											<Col sm={12}>
												<p
													style={{
														fontSize: "18pt",
														color: "black",
														textAlign: "center",
													}}
												>
													<b>Tus datos fueron enviados exitosamente</b>
												</p>
											</Col>
										</Row>
										<Row>
											<Col sm={12}>
												<p
													style={{
														fontSize: "15pt",
														color: "black",
														textAlign: "center",
													}}
												>
													El anunciante se contactará contigo
												</p>
											</Col>
										</Row>
										<Row
											style={{
												height: "calc(130px + 2vmin)",
												borderRadius: "10px",
												background: "#FFE521",
											}}
										>
											<Col sm={12}>
												<Container>
													<Row>
														<Col sm={4} style={{ paddingLeft: "0" }}>
															<img
																src={imgVendedor}
																style={{
																	float: "left",
																	width: "100px",
																	height: "100px",
																	marginTop: "20px",
																}}
															></img>
														</Col>
														<Col sm={8}>
															<div
																className="nombre-apellido"
																style={{
																	height: "50px",
																	width: "100%",
																	marginTop: "40px",
																}}
															>
																<p
																	style={{
																		fontSize: "10pt",
																		margin: "0 0 0 0",
																	}}
																>
																	<b>{nombre + " " + apellidopaterno}</b>
																</p>
																<p style={{ fontSize: "10pt" }}>
																	<b>Cel: {celular}</b>
																</p>
															</div>
														</Col>
													</Row>
												</Container>
											</Col>
										</Row>
									</Col>
									<Col sm={2}></Col>
								</Row>
							</Container>
						</Modal.Body>
					</Modal>
				</>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						padding: "0 5% 0 0",
						margin: "0 0 16px 0",
					}}
				>
					{window.innerWidth < 768 && (
						<>
							{/* <div className="redes">
				{megusta == true ? (
					<>
						<button
					  type={tipobotton}
					  style={{
						backgroundImage: "url(" + imgcorazon2 + ")",
						backgroundRepeat: "no-repeat",
						objectFit: "contain",
						backgroundSize: "40px 50px",
						height: "50px",
						width: "50px",
						backgroundColor: "transparent",
						border: "none",
					  }}
					  onClick={() => registroLike()}
					></button>
			</>
				) : (
					<>
						<button
					  type={tipobotton}
					  style={{
						backgroundImage: "url(" + imgcorazon1 + ")",
						backgroundRepeat: "no-repeat",
						objectFit: "contain",
						backgroundSize: "50px 50px",
						height: "50px",
						width: "50px",
						backgroundColor: "transparent",
						border: "none",
					  }}
					  onClick={() => registroLike()}
					></button>
			</>
				)}
				<button
				  type={tipobotton}
				  style={{
					backgroundImage: "url(" + imgwsp + ")",
					backgroundRepeat: "no-repeat",
					objectFit: "contain",
					backgroundSize: "50px 50px",
					height: "50px",
					width: "50px",
					backgroundColor: "transparent",
					border: "none",
				  }}
				  onClick={() => handleClick(1)}
				></button>
				<button
				  type={tipobotton}
				  style={{
					backgroundImage: "url(" + imgshared + ")",
					backgroundRepeat: "no-repeat",
					objectFit: "contain",
					backgroundSize: "50px 50px",
					height: "50px",
					width: "50px",
					backgroundColor: "transparent",
					border: "none",
				  }}
				  onClick={() => handleClick(2)}
				></button>
		</div> */}
			<div className="redes 1">
				{megusta == true ? (
					<>
						<button
							className="icon-container"
					type={tipobotton}
					onClick={() => registroLike()}
				>
					<MdFavorite className="icon-style" />
				</button>
			</>
			) : (
				<>
					<button
						className="icon-container"
						type={tipobotton}
						onClick={() => registroLike()}
					>
						<MdFavoriteBorder className="icon-style" />
					</button>
				</>
			)}
			<button
				className="icon-container"
				type={tipobotton}
				onClick={() => handleClick(1)}
			>
				<FaWhatsapp className="icon-style" />
			</button>
			<button
				className="icon-container"
				type={tipobotton}
				onClick={() => handleClick(2)}
			>
				<LuShare2 className="icon-style" />
			</button>
		</div>
	</>
)}
{window.innerWidth < 768 && (
	<>
		
		<p style={{ fontSize: "9pt" }}>
			<b>Publicado hace {fechacreacionanuncio} días</b>
		</p>
	</>
)}
		</div>
		{window.innerWidth >= 768 && (
			<>
				<p
				style={{
					fontSize: "9pt",
					display: "flex",
					justifyContent: "flex-end",
				}}
				className="container"
			>
				<b>Publicado hace {fechacreacionanuncio} días</b>
			</p>
		</>
	)}

	<div className="detail-container">
		<Row style={{ margin: "0 0 0 0" }}>
			<Col md={12} bg={12} style={{ padding: "0 0 0 0", width: "100%" }}>
				{isLoading ? (
					<LoadingSpinner />
					) : (
						<div className="gallery-container">
							{!lista
							? "Cargando Top Merkasa...."
									: lista.map((item, index) => {
										if (window.innerWidth > 768) {
											if (index <= 3) {
												return (
													<div className="gallery__img">
														<a>
															<figure>
																<img
																	src={item.imagen}
																	alt="imagen"
																	onClick={() => {
																		setMostrar(true);
																		setPosicion(index);
																	}}
																	style={{ cursor: "pointer" }}
																/>
																{index === 3 ? (
																	<a>
																		<ModalImagenes
																			ListaImagenes={lista}
																			pos={posicion}
																			mostrar={mostrar}
																			handleMostrar={() => setMostrar(true)}
																			handleClose={() => setMostrar(false)}
																			handleSelect={(selectedIndex) =>
																			setPosicion(selectedIndex)
																				}
																				alt="fotos"
																			></ModalImagenes>
																		</a>
																		) : (
																			""
																		)}
																	</figure>
																</a>
															</div>
															);
														}
														} else {
															if (index == 0) {
																return (
																	<div className="gallery__img">
																		<a>
																			<figure>
																				<img
																					src={item.imagen}
																					alt="imagen"
																					onClick={() => setMostrar(true)}
																					style={{ cursor: "pointer" }}
																				/>
																				<ModalImagenes
																					ListaImagenes={lista}
																					mostrar={mostrar}
																					handleMostrar={() => setMostrar(true)}
																					handleClose={() => setMostrar(false)}
																					alt="fotos"
																				></ModalImagenes>
																			</figure>
																		</a>
																	</div>
																	);
																}
															}
										})}
									</div>
						)}
					</Col>
				</Row>

				<div style={{display:'flex',gap:'10px',justifyContent:'center',alignItems:'center'}}>
					<button className='botonesAnuncio botonesAnuncio-photos' onClick={() => setMostrar(true)}>Ver Álbum</button>
					<button className='botonesAnuncio botonesAnuncio-videos' onClick={() => handleClickVideos()}>Ver Videos</button></div>
				<br />

				<Modal show={mostrarvideo} onHide={handleCloseVideo} fullscreen={true}  scrollable={false}>
					<Modal.Header closeButton>
					</Modal.Header>
					<Modal.Body 
						style={{
							backgroundColor:'black',
							padding:'0 0 0 0',
							display:'flex',
							justifyContent:'center',
							alignItems:'center',
							overflow:'hidden', 
							width:'100%',
							height:'100%'
							}}>
								<Carousel id='carousel-detalle-anuncio'>
									{listavideo && listavideo.map((item,index) => {
										return(
											<Carousel.Item key={index}  >
												<ReactPlayer
													url={item.videos}
													className='react-player'
													playing={false}
													controls={true}
												/>
											</Carousel.Item>
										)})}
									</Carousel>
								</Modal.Body>
							</Modal>
							<Modal
								className="modal-box"
								show={mostrarmensaje}
								onHide={() => setMostrarMensaje(false)}
							>
								<Modal.Header closeButton>
									<Modal.Title>
										<img
											src={imgLogo}
											className="logoMerkasa-modal"
											alt="Merkasa"
										></img>
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<p className="modal-text">El anunciante no ha publicado ningún video del anuncio</p>
								</Modal.Body>
							</Modal>


							<Row className="justify-content-between">
								<Col sm={8} lg={7} style={{ padding: "0 0 0 0" }}>
									<Container>
										<Row className="justify-content-center">
											<Col sm={10}>
												{anuncios && (
													<CardAnunciosViewDetails
														Direccion={anuncios.direccion}
														codigoAnuncio={CodAnuncio}
														Precio={anuncios.Precio}
														Terreno={anuncios.terreno}
														Construccion={anuncios.construccion}
														Dormitorios={anuncios.dormitorios}
														Banios={anuncios.banios}
														Parqueos={anuncios.parqueos}
														Transaccion={anuncios.Transaccion}
														Anio={anuncios.anioconstruccion}
														FechaEntrega={anuncios.FechaEntrega}
														EstadoProyecto={anuncios.Estadoproyecto}
														TipoInmueble={anuncios.TipoInmueble}
														Moneda={anuncios.moneda}
														MonedaExp={anuncios.monedaExp}
													></CardAnunciosViewDetails>
												)}
											</Col>
											<Col sm={2}>
												{window.innerWidth >= 768 && (
													<Container>
														<Row>
															<div className="redes flex-column align-items-end">
																<Col>
																	<center>
																		{megusta == true ? (
																			<>
																				<button
																					className="icon-container"
																			type={tipobotton}
																			onClick={() => registroLike()}
																		>
																			<MdFavorite className="icon-style" />
																		</button>
																	</>
																	) : (
																		<>
																			<button
																				className="icon-container"
																				type={tipobotton}
																				onClick={() => registroLike()}
																			>
																				<MdFavoriteBorder className="icon-style" />
																			</button>
																		</>
																	)}
																</center>
															</Col>
															<Col>
																<center>
																	<button
																		className="icon-container"
																		type={tipobotton}
																		onClick={() => handleClick(1)}
																	>
																		<FaWhatsapp className="icon-style" />
																	</button>{" "}
																</center>
															</Col>
															<Col>
																<center>
																	<button
																		className="icon-container"
																		type={tipobotton}
																		onClick={() => handleClick(2)}
																	>
																		<LuShare2 className="icon-style" />
																	</button>
																</center>
															</Col>
														</div>
													</Row>
												</Container>
											)}
										</Col>
									</Row>
									<Modal
										show={mostrarCompartir}
										onHide={() => setMostrarCompartir(false)}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>Compartir</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div>
												<h5>Redes Sociales</h5>
												<div className="icons-redes">
													<FacebookShareButton
														url={window.location.href}
														quote={"Inmueble a la venta"}
														hashtag="#inmueble"
													>
														<FacebookIcon size={36} />
													</FacebookShareButton>
													<WhatsappShareButton
														url={window.location.href}
														quote={"Inmueble a la venta"}
														hashtag="#inmueble"
													>
														<WhatsappIcon size={36} />
													</WhatsappShareButton>
												</div>
											</div>
											<div style={{ marginTop: 20 }}>
												<h5>Copiar enlace</h5>
												<div
													className="input-group mb-3"
													style={{ display: "flex", gap: "0px" }}
												>
													<input
														readOnly
														type="text"
														className="form-control"
														value={window.location.href}
														aria-label="Enlace"
														aria-describedby="basic-addon2"
													/>
													<div className="input-group-append">
														<Button
															variant="outline-secondary"
															onClick={copyToClipboard}
															disabled={copied}
															style={{
																height: "37px",
																position: "relative",
																top: "-2px",
															}}
														>
															{copied ? "Copiado" : "Copiar"}
															<FaCopy style={{ marginLeft: 5 }} />
														</Button>
													</div>
												</div>
											</div>
										</Modal.Body>
									</Modal>
									<br></br>

									<Row>
										<Col
											sm={12}
											className="titulo-anuncio"
											style={{
												textAlign: "left",
												marginTop: "2vmin",
											}}
										>
											<h2>{titulo}</h2>
										</Col>
										<Col
											sm={12}
											style={{ textAlign: "left", fontSize: "9pt" }}
											className="anuncio-editor"
										>
											<Editor
												editorState={editorState}
												readOnly
												toolbarHidden={true}
											/>
										</Col>
									</Row>
									<Row>
										<Col sm={12}>
											{anuncios && (
												<Mapadetalleanuncio
													Precio={anuncios.Precio}
													Latitud={anuncios.Latitud}
													Longitud={anuncios.Longitud}
													Direccion={anuncios.Direccion}
													Imagen={JSON.parse(anuncios.Imagenes).items[0].imagen}
												></Mapadetalleanuncio>
											)}
										</Col>
									</Row>
								</Container>
							</Col>
							<Col sm={4}>
								<Container className="d-flex flex-column">
									<Row
										style={{
											height: "150px",
											borderRadius: "10px",
											background: "#FFE521",
											// marginTop: "10%",
											marginTop: `${window.innerWidth < 768 ? "10%" : "0"}`,
										}}
									>
										<Col sm={12} className="d-flex align-items-center">
											<Container>
												<Row className="align-items-center">
													<Col
														xs={6}
														sm={12}
														lg={6}
														className="d-flex justify-content-center"
													>
														{agentes && (
															<img
																src={
																	agentes.Fotoperfil !== null
																	? agentes.Fotoperfil
																	: imgVendedor
																}
																style={{
																	float: "left",
																	width: "90%",
																	borderRadius: "10px",
																}}
																alt="fotoDePerfil"
															></img>
														)}
													</Col>
													<Col
														xs={6}
														sm={12}
														lg={6}
														className="text-sm-center text-lg-start"
													>
														<div>
															<p style={{ fontSize: "10pt", margin: "0 0 0 0" }}>
																<b>{nombre + " " + apellidopaterno}</b>
															</p>
															<p
																style={{
																	fontSize: "10pt",
																	marginBottom: "0",
																}}
															>
																<b>Cel: {celular}</b>
															</p>
														</div>
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>

									<Row style={{ paddingBottom: "2%" }}></Row>

									<Form className="flex-grow-1" onSubmit={handleSubmit}>
										<Row
											style={{
												border: "2px solid grey",
												//   padding: "50px",
												padding: `${window.innerWidth > 1024 ? "50px" : "20px"}`,
												borderRadius: "10px",
												height: "100%",
											}}
										>
											<Col sm={12}>
												<h5 className="text-center">Contactar al anunciante</h5>
											</Col>
											<Col
												sm={12}
												style={{ textAlign: "left", fontSize: "10pt" }}
											>
												<Form.Group className="sm-3">
													<Form.Label>Email</Form.Label>
													<Form.Control
														value={correoContacto}
														type="Email"
														onChange={(e) => setCorreoContacto(e.target.value)}
														required={bvisibleDatosContacto}
														style={{ fontSize: "9pt" }}
													/>
												</Form.Group>
											</Col>
											<Col
												sm={12}
												style={{ textAlign: "left", fontSize: "10pt" }}
											>
												<Form.Group
													className="sm-3"
													controlId="exampleForm.ControlInput2"
												>
													<Form.Label>Nombre</Form.Label>
													<Form.Control
														type="text"
														value={nombreContacto}
														onChange={(e) =>
														setNombreContacto(formatCadena(e.target.value))
													}
													required={bvisibleDatosContacto}
													style={{ fontSize: "9pt" }}
												/>
											</Form.Group>
										</Col>
										<Col
											sm={12}
											style={{ textAlign: "left", fontSize: "10pt" }}
										>
											<Form.Group
												className="sm-3"
												controlId="exampleForm.ControlInput1"
											>
												<Form.Label>Apellido</Form.Label>
												<Form.Control
													type="text"
													value={apellidoContacto}
													onChange={(e) =>
													setApellidoContacto(formatCadena(e.target.value))
												}
												required={bvisibleDatosContacto}
												style={{ fontSize: "9pt" }}
											/>
										</Form.Group>
									</Col>
									<Col sm={12} style={{ textAlign: "left", fontSize: "9pt" }}>
										<Form.Group
											className="sm-3"
											controlId="exampleForm.ControlInput1"
										>
											<Form.Label>Celular</Form.Label>
											<Form.Control
												type="text"
												nim={8}
												value={celularContacto}
												onChange={(e) =>
												setCelularContacto(soloNumeros(e.target.value))
											}
											required={bvisibleDatosContacto}
											style={{ fontSize: "9pt" }}
										/>
									</Form.Group>
								</Col>
								<Col
									sm={12}
									style={{ textAlign: "center", fontSize: "10pt" }}
								>
									<br></br>
									<center>
										<button
											type="submit"
											style={{
												fontSize: "11pt",
												width: "100%",
												height: "40px",
												border: "none",
												backgroundColor: "#FFE521",
												borderRadius: "10px",
											}}
										>
											<b>Contactar</b>
										</button>
									</center>
								</Col>
							</Row>
						</Form>
					</Container>
				</Col>
			</Row>
		</div>
	</Layout>
</>
  );
}

export default DetalleAnuncio;
