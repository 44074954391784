import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../useLocalStorage";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

export default function SearchGoogleMaps() {
  const [addressh, setAddress] = useLocalStorage("addressh", "");
  const [coordinates, setCoordinstes] = useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const lugar = results[0].types[0];
    if (lugar === "route") {
      sessionStorage.setItem("zoom", 14);
    }
    if (lugar === "administrative_area_level_1") {
      sessionStorage.setItem("zoom", 9);
    }
    if (lugar === "locality") {
      sessionStorage.setItem("zoom", 10);
    }

    const ll = await getLatLng(results[0]);

    setAddress(value);
    setCoordinstes(ll);
  };

  useEffect(() => {
    if (coordinates.lat == null) {
      return;
    }
    sessionStorage.setItem("CoordinatesLat", JSON.stringify(coordinates.lat));
    sessionStorage.setItem("CoordinatesLng", JSON.stringify(coordinates.lng));
  }, [coordinates.lat, coordinates.lng]);

  const searchOptions = {
    componentRestrictions: { country: ["bo"] },
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={addressh}
      onChange={setAddress}
      onSelect={handleSelect}
      style={{ height: "100%" }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="form-control borderInputSearch searchconfig">
          <input
            type="search"
            /* MODIFIED_BY@KH start */
            style={{ outline: "none" }}
            /* MODIFIED_BY@KH end */
            {...getInputProps({
              placeholder: "Escribe la ubicación de tu inmueble soñado",
            })}
          />
          <div
            style={{
              backgroundColor: "white",
              position: "relative",
              right: "13px",
              padding: "0 0 0 5px",
			  width:'100%'
            }}
            className="div-suggestion"
          >
            {loading && <div>Buscando...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: "orange",
                    cursor: "pointer",
                    width: "100%",
					padding: "0 0 5px 0"
                  }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    width: "100%",
					padding: "0 0 5px 0"
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <p>{suggestion.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
