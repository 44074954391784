import React from "react"
import Header from "./Header"
import Footer from "./Footer"

class Layoutf extends React.Component {
  render(){
    return (
      <>
        <Header></Header>
        <main>{this.props.children}</main>
        <Footer></Footer>
        
      </>
    )
  }
}
export default Layoutf;