import React, { useEffect } from 'react';
import Layout from "./Components/Layout";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabUsuario from './Components/TabUsuario';



function DetalleUsuario(){

useEffect(() => {
	window.scrollTo(0, 0);
},[])
    return (
        <>
         <Layout>
            <br></br>
            <Container style={{padding:'0 0 0 0',minHeight:'400px',position:'relative'}}>
                <Row>
                    <Col sm={1}>
                    </Col>
                    <Col sm={10}>
                    <TabUsuario></TabUsuario>
                    </Col>
                    <Col sm={1}>
                    </Col>
                </Row>
            </Container>
         </Layout>
        </>
        
    )
}

export default DetalleUsuario;
