import React from "react"
import Header2 from "./Header2"
import Footer from "./Footer"
class Layout extends React.Component {
	render(){
		return (
			<>
				<Header2></Header2>
				<main>{this.props.children}</main>				
				<Footer></Footer>
			</>
			)
	}

}
export default Layout;
