import React, { useState,useEffect } from 'react';
import Layout3 from "./Components/Layout3";
import Perfilagente from './Components/Perfilagente';




function MiperfilAgente()
{

    return (
        <>
          <Layout3>
          <br></br>
            <center>
              <Perfilagente></Perfilagente>
                </center>
          </Layout3>
        </>
      );

}
export default MiperfilAgente;
